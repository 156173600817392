import { createWebHookSignalTableRows } from 'app/design/speedBot/ComponentItem/WebHookSignals/utils';
import { TWebHookSignal } from 'types/ApiServicesTypes';
import CommonTable from '../Common/CommonTable';
interface IWebhookCutomTableProps {
  data: TWebHookSignal[];
  handleClick: (TWebHookSignal) => void;
}
const SignalDetailTable = ({ data, handleClick }: IWebhookCutomTableProps) => {
  const { rows, columns } = createWebHookSignalTableRows(data, handleClick);
  return (
    <CommonTable
      rows={rows}
      columns={columns}
      handleChartRowClick={handleClick}
    />
  );
};

export default SignalDetailTable;
