/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import BotsList from 'app/components/Bots/BotsList';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import { useHistory } from 'react-router-dom';
import {
  handleTouchMoveHandle,
  handleTouchStartHandle,
} from 'app/design/layouts/swipeUtil';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Box } from '@mui/material';

export function BotsPage({ selectedTab }: { selectedTab: number }) {
  const breadcrumb = useBreadcrumbManager();
  const [, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(1);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { isLoading } = useGetAllBots(page);
  const isMdUp = useGetMediaQueryUp('md');
  useEffect(() => {
    breadcrumb.resetBreadcrumpPaths();
  }, [breadcrumb]);
  let eventDir: string | null = null;
  const history = useHistory();
  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handleTouchStartHandle(e);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const eventDirRes = handleTouchMoveHandle(e);

    if (eventDirRes) eventDir = eventDirRes;
  };
  const handleTouchEnd = () => {
    if (!isMdUp && eventDir === 'left') {
      history.push('portfolio?ptype=paper');
      eventDir = '';
    }
  };
  return (
    <>
      <Helmet>
        <title>Bots</title>
        <meta name="description" content="Bots Page" />
      </Helmet>
      {/* <CircularLoader open={isLoading} /> */}

      <Box
        onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchStart(touchStartEvent)
        }
        onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchMove(touchMoveEvent)
        }
        onTouchEnd={() => handleTouchEnd()}
      >
        {/* <PageHeader
              variant="bots"
              title={<FormattedMessage id="bots.page_title.bots" />}
              filterChips={
                <Box
                  sx={{
                    overflow: 'auto',
                    display: 'flex',
                    '&::-webkit-scrollbar': { display: 'none' },
                  }}
                >
                  <MuiChip
                    label={<FormattedMessage id="bot_status.all" />}
                    size="medium"
                    color={activeTab === 'all' ? 'greyselected' : 'greylight'}
                    onClick={() => setActiveTab('all')}
                    sx={{ mr: 1 }}
                  />
                  {botsData &&
                    botTabs.map(item => {
                      return (
                        botsData[item.name] &&
                        botsData[item.name].length > 0 && (
                          <MuiChip
                            label={item.label}
                            key={item.name}
                            onClick={() => setActiveTab(item.name)}
                            sx={{ mr: 1 }}
                            size="medium"
                            color={
                              activeTab === item.name
                                ? 'greyselected'
                                : 'greylight'
                            }
                          />
                        )
                      );
                    })}
                </Box>
              }
            /> */}
        <BotsList activeTab={'all'} selectedTab={selectedTab} />
      </Box>
    </>
  );
}
