/* eslint-disable no-console */
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AddIcon from '@mui/icons-material/Add';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FeatureFlag, useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import BotsListData from 'app/design/speedBot/ComponentItem/Bots/BotsListData';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { PAGES } from '../Common/Breadcrumb/types';

// Service
import useGetAllBots from 'services/Bots/useGetAllBots';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import BotsOnboarding from './BotsOnboarding';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { botTabs } from 'app/pages/Bots/utils';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import StatusMessage from '../Common/StatusMessage';

import nodatafound from '../../../assets/images/No_data_image.png';
import { KeyboardCommandKey, SearchOutlined } from '@mui/icons-material';
import { getOSFromWeb } from 'utils/GenericFunctions';
import CreateCodeStrategtModal from './StrategyBuilder/CreateCodeStrategtModal';

const BotsList = ({
  activeTab,
  selectedTab,
}: {
  activeTab: string;
  selectedTab: number;
  getPage?: (val: number) => void;
}) => {
  const breadcrumb = useBreadcrumbManager();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  // const botPath = ['/bots'].includes(location.pathname);
  // const parsedKeyWord = () => {

  // return botSearchKeyWord;
  // };
  const keywordData = localStorage.getItem('searchKeyWords');
  let botSearchKeyWord: Record<string, string> = {};
  if (keywordData) {
    try {
      botSearchKeyWord = JSON.parse(keywordData) as Record<string, string>;
    } catch (e) {
      // console.error('Error parsing searchKeyWords from localStorage', e);
      botSearchKeyWord = {};
    }
  }

  const [searchText, setSearchText] = useState(botSearchKeyWord.bots || '');

  useEffect(() => {
    const updatedKeywords = { ...botSearchKeyWord, bots: searchText };
    localStorage.setItem('searchKeyWords', JSON.stringify(updatedKeywords));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  const page = 1;
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [selectedTab]);
  const [activetab, setActiveTab] = useState<string>('all');
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const handleCloseModal = (value: boolean) => {
    setOpenCodeModal(value);
  };
  const {
    isLoading,
    data: botsData,
    currentPage,
    setCurrentPage,
  } = useGetAllBots(page, searchText, activetab);
  const isMdUp = useGetMediaQueryUp('md');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  // const isBotPath = location.pathname.startsWith('/bot');
  // const ptype = isBotPath ? 'mybots' : 'active';
  const handleMenuItemclick = (
    value:
      | 'equity'
      | 'option'
      | 'code'
      | 'MCX'
      | 'crypto_india'
      | 'crypto_global',
  ) => {
    breadcrumb.push(
      '/bot-builder?type=' + value,
      PAGES.BOTS,
      `/bots?ptype=mybots`,
      t('bots.page_title.bots'),
    );
  };

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };
  const isFeatureEquity = useIsFeatureFlag([FEATURES.IS_EQUITY_BOTBUILDER]);
  const isFeatureOption = useIsFeatureFlag([FEATURES.IS_OPTION_BOTBUILDER]);
  const isCodeYourBot = useIsFeatureFlag([FEATURES.IS_CODE_YOUR_BOT]);
  const isCommodityBuilder = useIsFeatureFlag([FEATURES.IS_COMMODITY_BUILDER]);
  const isCryptoIndiaBuilder = useIsFeatureFlag([
    FEATURES.IS_CRYPTO_INDIA_BUILDER,
  ]);
  const isCryptoGlobalBuilder = useIsFeatureFlag([
    FEATURES.IS_CRYPTO_GLOBAL_BUILDER,
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && (e.key === 'k' || e.key === 'K')) {
        e.preventDefault(); // Prevent the default browser behavior
        if (inputRef.current) inputRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const os = getOSFromWeb();
  return (
    <>
      {(isFeatureEquity || isFeatureOption) && (
        <FeatureFlag features={[FEATURES.IS_BACKTESTING]} fallback={<></>}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            sx={{
              py: 1,
              mt: 1,
              textAlign: 'right',
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className="m-main-spacing"
            flexWrap={'wrap'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                order: { xs: 2, md: 0 },
                // width: '100%',
                rowGap: 1,
                maxWidth: { xs: '100%', md: '20rem', lg: '30rem' },
              }}
            >
              <Box>
                <OutlinedInput
                  inputRef={inputRef}
                  // label="Search"
                  placeholder="Type to search"
                  // variant="outlined"
                  size="small"
                  onChange={handleInputChange}
                  value={searchText}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'text.secondary',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'text.secodary',
                    },
                  }}
                  inputProps={{ border: 'red !important' }}
                  startAdornment={
                    <InputAdornment position="start">
                      {' '}
                      <SearchOutlined fontSize="small" />
                    </InputAdornment>
                  }
                  endAdornment={
                    isMdUp && (
                      <InputAdornment position="end">
                        <Box display={'flex'} gap={0.5}>
                          <Box
                            sx={theme => ({
                              borderRadius: 1,
                              backgroundColor: theme.palette.grey['300'],
                              height: '1.3pc',
                              width: os === 'MacOS' ? '1.3pc' : '2.5pc',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            })}
                          >
                            {os == 'MacOS' ? (
                              <KeyboardCommandKey
                                sx={theme => ({
                                  color: theme.palette.common.black,
                                  fontSize: 14,
                                })}
                              />
                            ) : (
                              <Typography
                                component={'code'}
                                sx={theme => ({
                                  fontSize: 12,
                                  color: theme.palette.common.black,
                                })}
                              >
                                Ctrl
                              </Typography>
                            )}
                          </Box>
                          <Box
                            sx={theme => ({
                              borderRadius: 1,
                              backgroundColor: theme.palette.grey[300],
                              height: '1.3pc',
                              width: '1.3pc',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            })}
                          >
                            <Typography
                              component={'code'}
                              sx={theme => ({
                                fontSize: 12,
                                color: theme.palette.common.black,
                              })}
                            >
                              K
                            </Typography>
                          </Box>
                        </Box>
                      </InputAdornment>
                    )
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  '&::-webkit-scrollbar': { display: 'none' },
                }}
              >
                <MuiChip
                  label={<FormattedMessage id="bot_status.all" />}
                  size="medium"
                  color={activetab === 'all' ? 'greyselected' : 'greylight'}
                  onClick={() => setActiveTab('all')}
                  sx={{ mr: 1 }}
                />
                {botsData &&
                  botTabs.map(item => {
                    return (
                      <MuiChip
                        label={item.label}
                        key={item.name}
                        onClick={() => setActiveTab(item.name)}
                        sx={{ mr: 1 }}
                        size="medium"
                        color={
                          activetab === item.name ? 'greyselected' : 'greylight'
                        }
                      />
                    );
                  })}
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="stretch"
              gap={2}
              height={'max-content'}
              flexWrap={'wrap'}
            >
              {isFeatureEquity && isFeatureOption ? (
                <>
                  {!!isCodeYourBot && (
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpenCodeModal(true)}
                      fullWidth={!isMdUp}
                      // size={isMdUp ? 'medium' : 'small'}
                      {...(isMdUp && { startIcon: <AddIcon /> })}
                      // startIcon={<AddIcon />}
                    >
                      <FormattedMessage id="buttons.code_bot" />
                    </MuiButton>
                  )}
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleMenuItemclick('equity')}
                    fullWidth={!isMdUp}
                    // size={isMdUp ? 'medium' : 'small'}
                    {...(isMdUp && { startIcon: <AddIcon /> })}
                    // startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="buttons.create_equity_bot" />
                  </MuiButton>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleMenuItemclick('option')}
                    fullWidth={!isMdUp}
                    // size={isMdUp ? 'medium' : 'small'}
                    {...(isMdUp && { startIcon: <AddIcon /> })}
                    // startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="buttons.create_option_bot" />
                  </MuiButton>
                  {isCommodityBuilder && (
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => handleMenuItemclick('MCX')}
                      fullWidth={!isMdUp}
                      // size={isMdUp ? 'medium' : 'small'}
                      {...(isMdUp && { startIcon: <AddIcon /> })}
                      // startIcon={<AddIcon />}
                    >
                      <FormattedMessage id="buttons.create_commodity_bot" />
                    </MuiButton>
                  )}
                  {isCryptoIndiaBuilder && (
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => handleMenuItemclick('crypto_india')}
                      fullWidth={!isMdUp}
                      // size={isMdUp ? 'medium' : 'small'}
                      {...(isMdUp && { startIcon: <AddIcon /> })}
                      // startIcon={<AddIcon />}
                    >
                      <FormattedMessage id="buttons.create_crypto_india_bot" />
                    </MuiButton>
                  )}
                  {isCryptoGlobalBuilder && (
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => handleMenuItemclick('crypto_global')}
                      fullWidth={!isMdUp}
                      // size={isMdUp ? 'medium' : 'small'}
                      {...(isMdUp && { startIcon: <AddIcon /> })}
                      // startIcon={<AddIcon />}
                    >
                      <FormattedMessage id="buttons.create_crypto_global_bot" />
                    </MuiButton>
                  )}
                </>
              ) : (
                <>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleMenuItemclick(isFeatureEquity ? 'equity' : 'option')
                    }
                    fullWidth={!isMdUp}
                    startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="buttons.create_bot" />
                  </MuiButton>
                </>
              )}
            </Box>
          </Box>
        </FeatureFlag>
      )}

      {botsData && Number(botsData?.data?.result?.length) > 0 ? (
        <BotsListData
          // type="active"
          bots={botsData?.data?.result}
          // title={<FormattedMessage id="bot_status.live" />}
          isOwnBot={activeTab === 'draft'}
        />
      ) : isLoading ? (
        <CircularLoader open={isLoading} />
      ) : (
        searchText === '' && <BotsOnboarding />
      )}
      {/* {(activeTab == 'draft' || activeTab == 'inactive') &&
        botsData?.data.result?.length == 0 && <BotsOnboarding />} */}
      {/* {activeTab != 'draft' &&
        activeTab != 'inactive' &&
        botsData &&
        botsData?.data &&
        botsData?.data?.result?.length === 0 && (
          // botsFilterData.inactive?.length === 0 &&
          // botsFilterData.paper?.length === 0 &&
          // botsFilterData.draft?.length === 0 &&
          // <StatusMessage subtitle={} />
          <Typography
            flex={1}
            variant="body1"
            sx={{ fontWeight: 400, fontStyle: 'italic', textAlign: 'center' }}
            color="text.secondary"
          >
            <FormattedMessage id="no_bots_available" />
          </Typography>
        )} */}

      {botsData && botsData?.data.result!.length > 0 && (
        <Pagination
          count={botsData.data.total_pages}
          page={currentPage}
          onChange={handleChangePagination}
          color="primary"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '1.5rem',
            pb: 3,
          }}
        />
      )}
      {botsData && !botsData.data.result?.length && searchText !== '' && (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            // justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            mt: 10,
          }}
        >
          <img src={nodatafound} style={{ width: '150px', height: '150px' }} />
          <StatusMessage
            subtitle={t('reports.couldnt_find')}
            fontStyle="italic"
          />
        </Box>
      )}
      <CreateCodeStrategtModal
        open={openCodeModal}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default BotsList;
// import React from 'react'

// const BotsList = ({
//     activeTab,
//     getPage,
//   }: {
//     activeTab: string;
//     getPage: (val: number) => void;
//   }) => {
//   return (
//     <div>BotsList</div>
//   )
// }

// export default BotsList
