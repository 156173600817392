import { createWebHookBotTableRows } from 'app/design/speedBot/ComponentItem/WebHookSignals/utils';
import { TWebHookBot } from 'types/ApiServicesTypes';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import CommonTable from '../Common/CommonTable';
interface IWebhookCutomTableProps {
  data: TWebHookBot[];
  handleViewBotClick: (bot: TWebHookBot) => void;
  handleActionClick: (status: string, sub_id: number) => void;
}
const WebHookCustomTables = ({
  data,
  handleViewBotClick,
  handleActionClick,
}: IWebhookCutomTableProps) => {
  const breadcrumb = useBreadcrumbManager();
  const { rows, columns } = createWebHookBotTableRows(
    data,
    breadcrumb,
    handleViewBotClick,
    handleActionClick,
  );
  return <CommonTable columns={columns} rows={rows} />;
};

export default WebHookCustomTables;
