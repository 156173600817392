import { useState, useCallback } from 'react';

const useClipboard: () => {
  copied: string | null;
  copyToClipboard: (id: string, text: string) => void;
} = () => {
  const [copied, setCopied] = useState<string | null>(null);

  const copyToClipboard = useCallback((id: string, text: string) => {
    void navigator.clipboard.writeText(text);
    setCopied(id);
    setTimeout(() => {
      setCopied(null);
    }, 700);
  }, []);

  return { copied, copyToClipboard };
};

export default useClipboard;
