import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { PAGES } from '../Common/Breadcrumb/types';
type CommonCardProps = {
  title: string;
  description: string;
  click: string;
};
const CommonCard = ({ title, click, description }: CommonCardProps) => {
  const breadcrumb = useBreadcrumbManager();

  const handleMenuItemclick = (value: string, name: string) => {
    breadcrumb.push(
      value !== 'template'
        ? '/bot-builder?type=' + value
        : `/templates/category?category=${name}`,
      value === 'template' ? PAGES.TEMPLATES : PAGES.BOTS,
      `/home`,
      'Home',
    );
  };
  const theme = useTheme();
  return (
    <Card
      sx={{
        mr: 2,
        mb: 2,
        width: 250,
        height: 250,
        borderRadius: '1em',
        overflow: 'hidden',
        position: 'relative',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(0.95)',
        },
        cursor: 'pointer',
        boxShadow: theme.palette.custom.boxShadow,
      }}
      onClick={() => handleMenuItemclick(click, title)}
    >
      <CardContent
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          background: `linear-gradient(135deg,${theme.palette.grey[300]} 0%, ${theme.palette.grey[200]} 100%)`,
          color: 'ButtonText',
        }}
      >
        <Typography
          sx={{ color: theme.palette.common.black }}
          variant="body1"
          component="div"
        >
          {title}
        </Typography>
      </CardContent>
      <Box
        sx={{
          p: 1,
          backgroundColor: theme.component.mainLayoutContainer.main,
          height: '100%',
        }}
      >
        <Typography
          sx={{ color: theme.palette.common.black }}
          variant="caption"
        >
          {description}
        </Typography>
      </Box>
    </Card>
  );
};

export default CommonCard;
