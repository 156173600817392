import { Box, Typography } from '@mui/material';
import { TWebHookSignal } from 'types/ApiServicesTypes';
import { getDate, getTime } from 'utils/GenericFunctions';

import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

import { ItemSeparator } from 'app/design/speedBot/EntityItem';

const InfoListSignal = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  source,
}: {
  data: TWebHookSignal;
  source?: string;
}) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.take_profit" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          // color={data.tp === '0' ? 'success.main' : 'error.main'}
        >
          {data.tp}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.take_profit_per" />
        </Typography>
        <Box>
          {/* <MuiChip
            icon={<>{orderStatus[data.status].icon}</>}
            label={<>{orderStatus[data.status].value}</>}
            size="xsmallicon"
            color={orderStatus[data.status].chipColor as ChipColor}
          /> */}
          <Typography
            variant="body3"
            fontWeight={500}
            // color={data.tp === '0' ? 'success.main' : 'error.main'}
          >
            {data.tpp}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.stop_loss" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.sl}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.stop_loss_per" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.slp}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.risk" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.risk}
        </Typography>
      </Box>
      <ItemSeparator variant="1px" />
      <Box display={'flex'} justifyContent={'space-between'} sx={{ py: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.signal" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.signal}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.error" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.error}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.time_received" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {getDate(data.time_received, false)} {getTime(data.time_received)}
        </Typography>
      </Box>
      <ItemSeparator variant="1px" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2 }}
      >
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.time_finish" />
        </Typography>
        {/* <MuiChip
          label={tags[data.tag] ? (tags[data.tag] as string) : data.tag}
          size="xsmall"
          color="greylight"
        /> */}
        <Typography variant="body3" fontWeight={500}>
          {getDate(data.time_finish, false)} {getTime(data.time_finish)}
        </Typography>
      </Box>{' '}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2 }}
      >
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="webhook_signals.processing_duration" />
        </Typography>
        {/* <MuiChip
          label={tags[data.tag] ? (tags[data.tag] as string) : data.tag}
          size="xsmall"
          color="greylight"
        /> */}
        <Typography variant="body3" fontWeight={500}>
          {data.processing_duration.toFixed(2)}s
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoListSignal;
