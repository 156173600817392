import { SbStrategy, UserBot } from 'app/components/Bots/StrategyBuilder/types';
import { ProfitLossByDateTransform } from 'app/design/uiComponents/Calendar/types';
import { AllBotOpenPositions } from 'services/Portfolio/useGetPortfolioActivePaper';
import { SubscriptionPlan } from './SubscriptionPlans';
import {
  TradeIdeasMetaData,
  TradeOptions,
  TradesType,
} from 'app/components/TradeIdeas/types';

export type Errors = {
  title: string;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
};

export type AuthenticateParams = {
  userid: string;
  password: string;
};

export type AuthenticateResult = {
  session_id: string;
  username: string;
  uid: number;
  error?: string;
};

export type CreateParams = {
  model: string;
  method: string;
  args: {
    login: string;
    email: string;
    name: string;
    password: string;
  };
  kwargs: {
    token: string;
  };
};

export type CreateResult = {
  result: string[];
};

export type ResetPasswordParams = {
  args: {
    login: string;
  };
  kwargs: {
    token: string;
  };
};

export type ResetPasswordResult = {
  result: string[];
};

export type ResetNewPasswordParams = {
  args: {
    login: string;
    email: string;
    name: string;
    password: string;
  };
  kwargs: {
    token: string;
  };
};

export type ResetNewPasswordResult = {
  result: string[];
};

export type ReadMarketPlaceParams = {
  model: string;
  page: number;
  search_string: string;
};

export type ExchangeId = {
  exchange_type: string;
  name: string;
};

export type StrategyTag = {
  name: string;
};

export type RuntimeStatisticsId = {
  netprofit: number;
  returns: number;
};

export type PortfolioStatisticsId = {
  drawdown: number;
};

export type AlgorithmStatisticsId = {
  runtimeStatisticsId: RuntimeStatisticsId[];
};

export type BaseStatisticsId = {
  portfolioStatisticsId: PortfolioStatisticsId[];
};

export type Strategy = {
  id: number;
  name: string;
  strategie_name: string;
  start_datetime: string;
  end_datetime: string;
  exchange_ids: ExchangeId[];
  tags: StrategyTag[];
  algorithmStatisticsId: AlgorithmStatisticsId[];
  baseStatisticsId: BaseStatisticsId[];
  trade_fund: string;
  totalfees: number;
  total_number_of_trades: number;
  st_winrate: number;
  st_lossrate: number;
  total_pages: number;
  equity: number;
};

export type ReadMarketPlaceResult = Strategy[];

export type EmailActivationParam = {
  args: {
    login: string;
  };
  kwargs: {
    token: string;
  };
};

export type EmailActivationResult = {
  result: string[];
};

export type ResendInvitationEmailParam = {
  args: {
    login: string;
    token: string;
  };
};

export type ResendInvitationEmailResult = {
  result: string[];
};

export type AuthenticateDefualtParams = {
  sessionid: string;
};

export type AuthenticateDefualtResult = {
  user_id: number;
  result: {
    name: string;
    login: string;
  };
};

// export type AuthenticateWithGoogleParams = {};
export type AuthenticateWithGoogleParams = { id?: string };

export type AuthenticateWithGoogleResult = {
  session_id: string;
  username: string;
  uid: number;
  email?: string;
  error?: string;
};

export type SignupWithGoogleParams = { id?: string };

export type SignupWithGoogleResult = {
  email: string;
  session_id: string;
  username: string;
  uid: string;
};

export type VerifyPhoneParams = {
  country_code: string;
  mobile: string;
  email: string;
};

export type VerifyPhoneResult = {
  otp: number;
  success: boolean;
  error?: string;
};

export type VerifyOtpParams = {
  country_code: string;
  otp: string;
  mobile: string;
};

export type VerifyOtpResult = {
  error?: string;
  success?: boolean;
};

export type MobileSignupParams = {
  name: string;
  email: string;
  mobile: string;
  country_code: string;
  password: string;
  referral: string;
};

export type MobileSignupResult = {
  status_code: string;
  email: string;
  error?: string;
};

export type ForgotPassParams = {
  country_code: string;
  mobile: string;
  email: string;
  action: string;
  auth_type: string;
  otp?: string;
  password?: string;
};

export type ForgotPassResult = {
  success: boolean;
  error?: string;
  auth_type: 'mobile' | 'email';
};

export type ResetConfirmOtpParams = {
  country_code: string;
  mobile: string;
  otp: string;
};

export type ResetConfirmOtpResult = {
  success: boolean;
  error?: string;
};

export type ResetPassParams = {
  country_code: string;
  mobile: string;
  otp: string;
  password: string;
};

export type ResetPassResult = {
  success: boolean;
  error?: string;
};

export type DeployeStrategyBrokerInfoParams = {
  model: string;
  user_id: number;
  page: number;
  search_string: string;
};

export type ExchangeName = {
  name: string;
};

export type UserBrokerInfo = {
  id: number;
  userbroker_id: number | string;
  name: string;
  display_name: string;
  exchange_ids: ExchangeName[];
  broker_id: BrokerName[];
  auth_1: string;
  auth_2: string;
  auth_3: string;
  auth_4: string;
  total_pages: number;
};

export type DeployeStrategyBrokerInfoResult = UserBrokerInfo[];

export type CreateSubscriptionParams = {
  data: {
    user_id: number;
    strategy_id: number;
    subscription_start_date: string;
    subscription_types: string;
    multiplier: number;
    user_broker_info_id: number;
    subscription_period: number;
    status_id: number;
  };
};

export type CreateSubscriptionResult = {
  result: {
    broker_id: {
      name: string;
    };
  };
};

export type GetAllStatusParams = {
  model: string;
};

export type GetAllStatusResult = Status[];

export type CheckingExistingSubscriptionParams = {
  user_id: number;
  strategy_id: number;
};

export type CheckingExistingSubscriptionResult = {
  result: [{ display_name: string; id: number }];
};

export type LoadSubscriptionByIDParams = {
  id: number;
};

export type LoadSubscriptionByIDResult = {
  result: {
    display_name: string;
    strategy_id: number;
    subscription_start_date: string;
    subscription_types: string;
    multiplier: number;
    user_broker_info_id: number;
    subscription_period: number;
  };
};

export type EditSubscriptionParams = {
  filter: [[string, string, number]];
  data: {
    strategy_id: number;
    subscription_types: string;
    multiplier: number;
    user_broker_info_id: number;
    subscription_period: number;
    status_id: number;
  };
};

export type EditSubscriptionResult = {
  result: {
    broker_id: {
      name: string;
    };
  };
};

export type GetAllSubscriptionParams = {
  model: string;
  page: number;
  search_string: string;
};

export type StrategyName = {
  name: string;
};

export type BrokerName = {
  id: number;
  name: string;
};
export type Status = {
  id: number;
  status: string;
};

export type ExchangeOnlyId = {
  id: number;
};

export type SubscriptionStrategy = {
  id: number;
};

export type Subscription = {
  id: number;
  name: string;
  strategy_id: SubscriptionStrategy;
  strategy_name: StrategyName[];
  exchange_ids: ExchangeOnlyId[];
  trade_fund: number;
  strategy_ref: string;
  subscription_start_date: string;
  subscription_end_date: string;
  subscription_types: string;
  multiplier: number;
  user_broker_info_id: BrokerName[];
  subscription_period: number;
  status_id: Status[];
  total_pages: number;
};

export type GetAllSubscriptionResult = Subscription[];

export type DeleteSubscriptionParams = {
  filter: [[string, string, number]];
  data: {
    active: boolean;
    // status_id: number;
  };
};

export type DeleteSubscriptionResult = {
  result: boolean;
};

export type GetAllExchangesParams = {
  model: string;
};

export type Exchange = {
  id: number | string;
  name: string[];
  exchange_type: string;
};

export type GetAllExchangesResult = Exchange[];

export type GetAllBrokerParams = {
  model: string;
};

export type BrokerResult = {
  id: number | string;
  name: string[];
};

export type GetAllBrokerResult = BrokerResult[];

export type CreateUserBrokerInfoParams = {
  data: {
    user_id: number;
    name: string;
    broker_id: number;
    exchange_ids: number[];
    auth_1: string;
    auth_2: string;
    auth_3: string;
    auth_4: string;
  };
};

export type CreateUserBrokerInfoResult = {
  result: {
    id: number;
  };
};

export type DeleteUserBrokerInfoParams = {
  filter: [[string, string, number]];
  data: {
    active: boolean;
  };
};

export type DeleteUserBrokerInfoResult = {
  result: boolean;
};

export type GetAllTagsParams = {
  model: string;
};

export type Tags = {
  id: number;
  name: string;
};

export type GetAllTagsResult = Tags[];
export type GetStrategyDetailParams = {
  model: string;
  id: number;
};

export type StrategyDetails = {
  id: number;
  name: string;
  exchange_ids: ExchangeId[];
  netprofit: number;
  st_compoundingannualreturn: number;
  fees: number;
  st_drawdown: number;
  tags: StrategyTag[];
  strategie_name: string;
  trade_fund: number;
  st_sharperatio: number;
  winrate: number;
  lossrate: number;
  total_number_of_trades: number;
  end_datetime: string;
  start_datetime: string;
  equity: number;
  returns: number;
  averageprofit: number;
  averageloss: number;
  st_average_win_rate: number;
  st_average_loss_rate: number;
  holdings: number;
  unrealized: number;
  volume: number;
  profitlossratio: number;
  winlossratio: number;
  totalprofitlosses: number;
  maximumdrawdownduration: number;
  maximumclosedtradedrawdown: number;
  profitlossstandarddeviation: number;
  profitlossdownsidedeviation: number;
  tssharperatios: number;
  probabilistic_sharpe_ratio: number;
  sortinoratios: number;
  profittomaxdrawdownratio: number;
  largestMFE: number;
  largestMAE: number;
  averageMFE: number;
  averageMAE: number;
  st_annualstandarddeviation: number;
  st_annualvariance: number;
  st_information_ratio: number;
  maxconsecutivewinningtrades: number;
  maxconsecutivelosingtrades: number;
  totalprofit_loss: number;
  averageprofitloss: number;
  averagetradeduration: number;
  averagewinningtradeduration: number;
  averagelosingtradeduration: number;
  largestprofit: number;
  largestloss: number;
  numberofwinningtrades: number;
  numberoflosingtrades: number;
  st_winrate: number;
  st_lossrate: number;
};

export type GetStrategyDetailResult = StrategyDetails[];

export type StrategyOrderDetails = {
  id: number;
  order_date: string;
  instrument_id: string;
  quantity: number;
  price: number;
  value: number;
  status: string;
  tag: string;
  direction: string;
};

export type GetStrategyOrderResult = StrategyOrderDetails[];

export type StrategyPositionDetails = {
  id: number;
  entry_time: string;
  exit_time: string;
  instrument_id: string;
  trading_symbol: string;
  profit_loss: number;
  totalfees: number;
  status: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  entry_Price: string;
  exist_price: string;
  quantity: number;
  direction: string;
  duration: string;
  percent_change: number;
  position_value: number;
  mae_percent: number;
  mfe_percent: number;
  PandLPercent: number;
  mae: number;
  mfe: number;
};

export type GetStrategyPositionResult = StrategyPositionDetails[];

export type GetStrategyRollingwindoM1Params = {
  model: string;
  strategy_id: number;
};

export type GetStrategyRollingwindoM1Details = {
  id: number;
  rolling_window_id: Array<Roolingwindowid>;
};
interface Roolingwindowid {
  start_date_time: string;
  end_date_time: string;
  totalprofit_loss: number;
  rolling_window_name: string;
}

export type GetStrategyRollingwindoM1Result =
  GetStrategyRollingwindoM1Details[];

export type GetPortfolioGraphDataParms = {
  args: Array<number>;
};

export type GetPortfolioGraphDataDetails = {
  date: string;
  capital: number;
  percentage: number;
};

export type GetPortfolioGraphDataResult = GetPortfolioGraphDataDetails[];

export type GetAnualChartDataParams = {
  args: [
    { model: string },
    { fields: Array<string> },
    { groupby: Array<string> },
    { strategy_id: number },
  ];
};

export type GetAnualChartDataDetails = {
  profit_loss: number;
  'entry_time:year': string;
  percentage: number;
};

export type GetAnualChartDataResult = GetAnualChartDataDetails[];

export type GetIntervalResult = [{ name: string; interval: string }];

export type GetReturnPerTradeGraphDataParams = {
  strategy_id: number;
};

export type GetReturnPerTradeGraphDataDetail = {
  key: string;
  value: number;
};

export type GetReturnPerTradeGraphDataResult =
  GetReturnPerTradeGraphDataDetail[];

export type MyStrategy = {
  id: number;
  name: string;
  latest_version: {
    id: number;
    strategie_name: string;
    trade_fund: number;
    netprofit: number;
    returns: number;
    drawdown: number;
    total_number_of_trades: number;
    st_winrate: number;
    st_lossrate: number;
    st_sharperatio: number;
    last_backtest_run: string;
  };
  total_pages: number;
};

export type GetAllMyStrategyResult = MyStrategy[];

export type GetAllMyStrategyParams = {
  model: string;
  search_string: string;
  page: number;
};

export type GetStrategyDataByUserStrategyResult = {
  param_json: string;
  strategy_id: number;
  name: string;
  created_date: string;
};

export type GetStrategyDataByUserStrategyParms = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  args: {};
};

export type AddRevisionResult = {
  result: string[];
};

export type AddRevisionParms = {
  args: Array<object>;
};

export type GetAllMyinstrument = {
  name: string;
  exchange_ids: string;
  trading_symbol: string;
  exchange_instrument: number;
};

export type GetAllMyinstrumentResult = GetAllMyinstrument[];

export type UserStrategyResult = {
  result: number;
};

export type UserStrategyParms = {
  args: Array<string>;
};

export type InstantBacktestResult = {
  result: string[];
};

export type InstantBacktestParms = {
  args: Array<object>;
};

export type ResultReadyResult = {
  result: string[];
};

export type ResultReadyParms = {
  args: Array<object>;
};

export type DeleteUserStrategyParams = {
  filter: [[string, string, number]];
  data: {
    active: boolean;
    // status_id: number;
  };
};

export type DeleteUserStrategyResult = {
  result: boolean;
};

export type GetAllBotsParams = {
  model: string;
};

export interface GetAllBots {
  id: number;
  sub_id: number;
  botid: number;
  name: string;
  days: string;
  is_own_bot: boolean;
  category: string;
  exchanges: string[];
  author: string;
  create_date: string;
  write_date: string;
  drawdown: number;
  min_capital: number;
  user_botid: number;
  symbols: string[];
  currency_symbol: string;
}
export interface GetBotCardDetails {
  count: number;
  prev: boolean;
  current: number;
  next: boolean;
  total_pages: number;
  result?: GetAllBots[];
}
export interface GetBotCardDetailsResult {
  success: boolean;
  error: string;
  data: GetBotCardDetails;
}
export type GetAllBotsResult = {
  active: GetAllBots[];
  inactive: GetAllBots[];
  paper: GetAllBots[];
  draft: GetAllBots[];
};
export type Instruments = {
  company_name: string;
  symbol: string;
};
export type BotHistory = {
  id: number;
  bot_id: number;
  sub_id: number;
  start_data: string;
  end_date: string;
  positions_count: number;
  category: string;
  total_profit_loss: number;
  total_profit_loss_per: number;
  net_profit: number;
  net_profit_percentage: number;
  instrument: Instruments[];
  initial_capital: number;
  portfolio_value: number;
  currency_name: string;
  currency_symbol: string;
};
export type GetBotHistoryResult = BotHistory[];

export type GetBotHistoryParams = {
  model: string;
};

export type GraphPoint = {
  date: string;
  capital: number;
  percentage: number;
};

export type GetBotHistoryDetailResult = {
  initial_capital: number;
  total_trades: number;
  total_profit: number;
  total_profit_percentage?: number;
  avg_profit: number;
  avg_profit_percentage: number;
  avg_loss: number;
  avg_loss_percentage: number;
  max_profit: number;
  max_profit_percentage: number;
  max_loss: number;
  max_loss_percentage: number;
  profit_trades: number;
  loss_trades: number;
  orders_count: number;
  positions_count: number;
  start_data: string;
  end_date: string;
  portfolio_graph?: GraphPoint[] | null;
} & StatisticsData;

export type GetBotDetailsParams = {
  model: string;
  id: number;
};

export type GetStatisticsParams = {
  model: string;
};

export type GetBotDetailsResult = {
  name: string;
  category: string;
  description: string;
  positions_count: number;
  order_count: number;
  history_count: number;
  bot_id: number;
  car: number;
  risk_type: string | boolean;
  position_type: string | boolean;
  drawdown: number;
  start_date: string;
  scripts: GetPortfolioBotDetailScripts[];
  time: string;
  user_botid: number;
  result_ready?: boolean;
  is_own_bot?: boolean;
  is_line_strategy?: boolean;
  exchanges: string[];
  is_custom_form?: boolean;
  built_with: string | null;
  type: string;
} & StatisticsData;

export type GetBotPositionsParams = {
  model: string;
  id: number;
  page: number;
  pageSize: number;
};

export interface Position {
  id: number;
  entry_time: string;
  exit_time: string;
  profit_loss: number;
  totalfees: number;
  status: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  entry_Price: number;
  exit_price: number;
  quantity: number;
  direction: string;
  duration: string;
  percent_change: number;
  position_value: number;
  mae_percent: number;
  mfe_percent: number;
  mae: number;
  mfe: number;
  instrument_id: string;
  trading_symbol: string;
  total_pages: number;
  currency_symbol: string;
  currency_name: string;
  count: number;
  percentage_change: number;
  PandLPercent: number;
  tag?: string;
  trade_id?: string;
  signal_id?: string;
  bot_name?: string;
  metadata?: {
    order_product: string;
    position_type: string; //
    product_type: string; //
    tag: string; //
    trade_id: string; //
    unique_identifier: string;
  };
  stoploss?: BotSingalStopLoss;
  target?: BotSignalTarget;
}

export enum PositionMetaDataIgnoreKeys {
  'tag',
  'trade_id',
  'product_type',
  'order_product',
  'position_type',
  'entry_order_id',
  'target_order_id',
  'stoploss_order_id',
  'unique_identifier',
  'en_ul_price',
  'ex_ul_price',
  'ul_price',
}

export type GetBotPositionsResult = Position[];

export type GetBotTodaysPositionsResult = {
  success: boolean;
  today_positions: Position[];
  errors: MessageAlertErrors[];
};

export type GetBotTodaysPositionsQueryResult = {
  data: GetBotTodaysPositionsResult;
  symbols: Array<string>;
  openPositions: Array<Position>;
  closePositions: Array<Position>;
};

export type GetBotOrdersParams = GetBotPositionsParams;

export interface Order {
  id: number;
  instrument_id: string;
  trading_symbol: string;
  order_date: string;
  last_fill_time: string;
  quantity: number;
  price: number;
  limit_price: number;
  stop_price: number;
  value: number;
  status: string;
  type: string;
  tag: string;
  direction: string;
  broker_order_id: string;
  total_pages: number;
  count: number;
  message: string;
  currency_name: string;
  currency_symbol: string;
  metadata?: {
    OrderMetadata: {
      order_product: string;
      position_type: string; //
      product_type: string; //
      tag: string; //
      trade_id: string; //
      unique_identifier: string;
    };
  };
}
export type GetBotOrdersResult = Order[];

export type GetBotTodaysOrdersResult = {
  success: boolean;
  today_orders: Order[];
  errors: MessageAlertErrors[];
};

export type GetBotOrdersPositionsForExcelParams = {
  botId: number;
  page: number;
  baseApiUrl: string;
  maxPage: number;
};

export type GetBotHistoryPositionsParams = {
  model: string;
  bid: number;
  id: number;
  page: number;
  pageSize: number;
};

export type GetPortfolioParams = {
  model: string;
};

export interface PortfolioBot {
  id: number;
  botid: number;
  name: string;
  days: string;
  instrument: string[];
  position_count: number;
  open_position_count: number;
  todays_change: number;
  initial_capital: number;
  bot_info?: GetPortfolioBotDetailResult;
  portfolio_value?: number;
  broker_name: string;
  trading_username: string;
  currency_name: string;
  currency_symbol: string;
  is_logged_in: boolean;
}

export type GetPortfolioBotResult = {
  todays_change: number;
  total_profit_loss: number;
  total_profit_loss_per: number;
  initial_capital: number;
  portfolio_value: number;
  bots: {
    active?: PortfolioBot[];
    paper?: PortfolioBot[];
    manual?: PortfolioBot[];
  };
};

export type PortfolioBotsData = {
  data: GetPortfolioBotResult;
  bots: PortfolioBot[];
  symbols: Array<string>;
  positionData: Array<Position> | Array<AllBotOpenPositions>;
};

export type GetPortfolioBotDetailScripts = {
  symbol: string;
  company_name: string;
};
export type MessageAlertErrors = {
  id: number;
  info: string;
  message: string;
  type: string;
  error_timestamp: string;
};

export type GetPortfolioBotDetailResult = {
  bot_name: string;
  start_date: string;
  time: string;
  end_date: string;
  initial_capital: number;
  todays_change: number;
  total_fees: number;
  total_profit_loss: number;
  total_profit_loss_per: number;
  net_profit: number;
  net_profit_per: number;
  portfolio_value: number;
  order_count: number;
  position_count: number;
  today_positions: Position[];
  slave: boolean;
  portfolio_graph: GraphPoint[] | null;
  category: string;
  scripts: GetPortfolioBotDetailScripts[];
  botid: number;
  sub_id: number;
  errors: MessageAlertErrors[];
  currency_name: string;
  currency_symbol: string;
};

export type PostClearErrorParams = {
  read: boolean;
};

export type PostClearErrorResult = {
  status_code: number;
  success?: boolean;
  error?: string;
};

export type GetPortfolioBotDetailQueryResult = {
  data: GetPortfolioBotDetailResult;
  symbols: Array<string>;
  openPositions: Array<Position>;
  closePositions: Array<Position>;
};

export type GetMarketplaceBotsParams = {
  model: string;
  method: string;
};
export type GetMarketplaceBotsItem = {
  id: number;
  name: string;
  profit_loss: number;
  initial_capital: number;
  cagr: number;
  max_drowdown: number;
  instruments: string[];
  net_profit: number;
  net_profit_percentage: number;
  graph_data: [GraphPoint[]];
  position_type: string;
  start_date: string;
  end_date: string;
  risk_type: string;
  currency_name: string;
  currency_symbol: string;
  followers?: number;
  last_month_return?: number;
  leverage?: number;
  description?: string;
  state?: string;
};

export type GetMarketplaceBotsCategoryData = {
  category_name: string;
  description: string;
  category_id: number;
  bots: GetMarketplaceBotsItem[];
};

export type GetMarketplaceBotsData = {
  category_data: GetMarketplaceBotsCategoryData[];
  graph_data: GraphPoint[][] | null;
};

export type GetMarketplaceBotsResult = GetMarketplaceBotsData[];

export type GetMarketplaceBotsByCategoryParams = {
  model: string;
  method: string;
  id: number;
};

export type GetMarketplaceBotsByCategoryResult =
  GetMarketplaceBotsCategoryData[];

export type GetMarketplaceBotsBySearchParams = {
  model: string;
  method: string;
  keyword: string;
};

export type GetMarketplaceBotsBySearchResult = GetMarketplaceBotsItem[] | null;

export type GetMarketplaceBotDetailParams = {
  model: string;
  method: string;
  id: number;
};
export type GetDayWisePnl = {
  date: string;
  profit_loss: number;
  // profit_loss_percent: number;
  profit_loss_percentage: number;
  trades: number;
  bot_name: string;
  total_fees: string;
  // profitLossPercentage: number;
};
export type GetMonthPnl = {
  month: string;
  total_pnl: number;
  pnl_percentage: number;
  total_trades: number;
  total_fees: number;
  day_wise_pnl: GetDayWisePnl[];
};
export type GetReportOverallData = {
  realized_pnl: number;
  charges_and_taxes: number;
  net_profit: number;
  data: GetMonthPnl[];
};
export type ReportColorProps = {
  color: string;
  opacity: number;
};
export type ReportTransfromPnlData = {
  date: string;
  profit_loss: number;
  profit_loss_percentage: number;
  trades: number;
  bot_name: string;
  total_fees: number;
  color: ReportColorProps;
};
export type GetTransformReportData = {
  startDate: string;
  endDate: string;
  minDate: string;
  maxDate: string;
  // profitLossData: GetDayWisePnl[];
  transformProfitLossData: ReportTransfromPnlData[];
};
export type LastTransformReportData = GetTransformReportData[];
export type GetReportResult = GenericApiResponse<GetReportOverallData>;
export type GetStatisticsResult = {
  trade_statistics: BotTradeStatsData;
  advance_statistics: BotAdvanceStatsData;
  statistics: StatisticsData;
  result_ready: boolean;
  type: string;
  errors?: MessageAlertErrors[];
  currency_name: string;
  currency_symbol: string;
};

export type StatisticsData = {
  name: string;
  author: string;
  description: string;
  initial_capital: number;
  start_date: string;
  end_date: string;
  candle_interval: string;
  total_profit: number;
  total_fees: number;
  total_profit_percentage: number;
  net_profit: number;
  net_profit_percentage: number;
  drawdown: number;
  max_drawdown_duration: string;
  total_trads: number;
  winrate: number;
  lossrate: number;
  sharp_ratio: number;
  average_profit: number;
  average_profit_percentage: number;
  average_loss: number;
  average_loss_percentage: number;
  max_profit: number;
  max_profit_percentage: number;
  max_loss: number;
  max_loss_percentage: number;
  position_count: number;
  order_count: number;
  sub_id: number;
  bot_id: number;
  category: string;
  holding_type: string;
  car: number;
  position_type: string;
  risk_type: string;
  scripts: GetPortfolioBotDetailScripts[];
  total_trades: number;
  profit_trades: number;
  loss_trades: number;
  avg_profit: number;
  avg_loss: number;
  orders_count: number;
  positions_count: number;
  profit_trades_percentage: number;
  loss_trades_percentage: number;
  profit_percentage: number;
  loss_percentage: number;
  avg_profit_percentage: number;
  avg_loss_percentage: number;
  portfolio_graph: GraphPoint[];
  is_own_bot: boolean;
  user_botid: number;
  subscribers_count: number;
  owner_id: number;
  owner_name: string;
  annual_return_percentage: number;
  currency_name: string;
  currency_symbol: string;
  last_month_return?: number;
  exchanges?: string[];
  type?: string;
};

export type GetBacktestBotDetailParams = {
  model: string;
  method: string;
  id: number;
};

export type GetBacktestBotDetailResult = {
  bot_id: number;
  name: string;
  description: string;
  initial_capital: number;
  total_profit: number;
  total_fees: number;
  total_profit_percentage: number;
  net_profit: number;
  net_profit_percentage: number;
  drawdown: number;
  car: number;
  total_trads: number;
  winrate: number;
  lossrate: number;
  sharp_ratio: number;
  average_profit: number;
  average_profit_percentage: number;
  average_loss: number;
  average_loss_percentage: number;
  max_profit: number;
  max_profit_percentage: number;
  max_loss: number;
  max_loss_percentage: number;
  category: string;
  position_count: number;
  position_type: string | boolean;
  risk_type: string | boolean;
  order_count: number;
  candle_interval: string;
  start_date: string;
  end_date: string;
  scripts: GetPortfolioBotDetailScripts[];
  is_own_bot: boolean;
  user_botid: number;
};

export type BotAdvanceStatsData = {
  holdings: number;
  unrealized: number;
  equity: number;
  volume: number;
  profitlossratio: number;
  winlossratio: number;
  winrate: number;
  lossrate: number;
  st_compoundingannualreturn: number;
  st_drawdown: number;
  totalprofitlosses: number;
  maximumdrawdownduration: string;
  maximumclosedtradedrawdown: number;
  profitlossstandarddeviation: number;
  profitlossdownsidedeviation: number;
  tssharperatios: number;
  probabilistic_sharpe_ratio: number;
  sortinoratios: number;
  profittomaxdrawdownratio: number;
  largestMFE: number;
  largestMAE: number;
  averageMFE: number;
  averageMAE: number;
  st_annualstandarddeviation: number;
  st_annualvariance: number;
  st_information_ratio: number;
  car_mdd: number;
};

export type BotTradeStatsData = {
  maxconsecutivewinningtrades: number;
  maxconsecutivelosingtrades: number;
  totalprofit_loss: number;
  averageprofitloss: number;
  averagetradeduration: string;
  averagewinningtradeduration: string;
  averagelosingtradeduration: string;
  largestprofit: number;
  largestloss: number;
  numberofwinningtrades: number;
  numberoflosingtrades: number;
  st_winrate: number;
  st_lossrate: number;
  average_loss: number;
  average_profit: number;
  total_profit_trade: number;
  total_loss_trade: number;
};
export type ProfileCapability = {
  balance: number;
  credit: number;
  debit: number;
  key: string;
  name: string;
  description?: string;
};
export type ProfieUserPlan = {
  end_date: string;
  start_date: string;
  plan: SubscriptionPlan;
  recurring_price: number;
  status: string;
  subscription_id: number;
  payment_source: string;
  capabilities: ProfileCapability[];
  is_closed: boolean;
  is_pending_invoice: boolean;
};
export type GetProfileResult = {
  name: string;
  mobile: string;
  country_code: string;
  email: string;
  gstin: string;
  referral_code: string;
  is_oauth_user: boolean;
  notification: boolean;
  general_notification: boolean;
  paper_notification: boolean;
  live_notification: boolean;
  plan: string;
  user_plan?: ProfieUserPlan;
  free_trial_eligible: boolean;
  wallet_balance: number;
  google_user: boolean;
  referred_by: boolean;
  user_id: string;
  previous_active_plan?: string;
};

export type NotificationChannel = {
  id: number;
  label: string;
  icon_name: string;
  icon_color: string;
  active: boolean;
  icon_svg: string;
};
export type UserNotificationChannel = Omit<NotificationChannel, 'label'> & {
  enabled: boolean;
  value: boolean;
};
export type NotificationSetting = {
  id: number;
  label: string;
  description: string;
  channels: UserNotificationChannel[];
};
export type GetNotificationSettingsResult = {
  channels: NotificationChannel[];
  settings: NotificationSetting[];
};
export type EditNotificationParam = {
  setting_id: number;
  channel_id: number;
  value: boolean;
};
export type EditNotificationParams = {
  settings: EditNotificationParam[];
};
export type GetProfileFeaturesResult = {
  [key: string]: number;
};

export type EditProfileParams = {
  name?: string | undefined;
  email?: string | undefined;
  mobile?: string | undefined;
  otp?: string | undefined;
  gstin?: string | undefined;
  country_code?: string | undefined;
  general_notification?: boolean | undefined;
  paper_notification?: boolean | undefined;
  live_notification?: boolean | undefined;
};

export type EditProfileResult = {
  id: number | null;
  result: boolean;
  error?: string;
};

export type GetOtpParams = {
  mobile?: string | undefined;
  country_code?: string | undefined;
};

export type GetOtpResult = {
  id: number | null;
  result: boolean;
  error?: string;
};

export type ChangePasswordParams = {
  password: string;
  new_password: string;
};

export type ChangePasswordResult = {
  id: number | null;
  result: boolean;
};

export type PostBotActionParams = {
  type: string;
  multiplier: number;
  duration?: number;
  user_broker_id?: number;
  action?: string;
};

export type PostBotActionResult = {
  id: number | null;
  sub_id: number;
  botid: number;
  result: boolean;
  error?: string;
};

export interface BotSignal {
  id: number;
  signal_id: string;
  order_duration: string;
  order_type: string;
  order_price: string;
  position_type: string;
  product_type: string;
  quantity: number;
  signal_date: string;
  tag: string;
  trading_symbol: string;
  transaction_type: string;
  total_pages: number;
  count: number;
  trade_id: string;
  instrument_id: string;
  currency_name: string;
  currency_symbol: string;
  target?: BotSignalTarget;
  stoploss?: BotSingalStopLoss;
  leverage?: number;
  position_sizing: string;
  order_trigger_price: string;
}
export type GetBotSignalsResult = BotSignal[];

export type BotSingalStopLoss = {
  enabled: boolean;
  price: number;
  trigger: number;
  exit_order_type?: string;
};

export type BotSignalTarget = {
  enabled: boolean;
  exit_order_type?: string;
  price: number;
};

export type BotSignalParams = {
  signal_id: string;
  instrument_id: string;
  product_type: string;
  order_type: string;
  trade_id: string;
  transaction_type: string;
  order_duration: string;
  quantity: number;
  tag: string;
  position_type: string;
  validity: string;
  order_price: string;
  symbol?: string;
  target?: BotSignalTarget;
  stoploss?: BotSingalStopLoss;
  leverage?: number;
  position_sizing?: string;
  order_trigger_price?: string;
};

export type PostBotSignalParams = {
  signals: BotSignalParams[];
};

export type PostBotSignalResult = {
  id: number | null;
  sub_id: number;
  botid: number;
  success: boolean;
  error?: string;
};

export type PostBotExitAllAndStopResult = boolean;

export type PostBotExitStrategyPositionsParams = {
  position_ids: number[];
  action: string;
};

export type PostBotExitStrategyPositionsResult = {
  status_code: number;
  success?: boolean;
  error?: string;
  data: { toaster_message?: string };
};

export type DeleteBotResult = {
  status_code: number;
  success?: number;
  error?: string;
};

export type PostUsersBotActionParams = {
  name: string;
  action: string;
};

export type PostUsersBotActionResult = {
  status_code: number;
  success?: number;
  error?: string;
  errors?: string[];
};

export type GetMarketplacGraphParams = {
  id: number;
  type?: string;
};

export type GetMarketplacEquityGraphResult = GraphPoint[] | null;

export type BotReturnsGraphDataItem = {
  label: string;
  value: number;
  value2: number;
};

export type BotReturnsGraphData = BotReturnsGraphDataItem[];

export type BotTradeGraphDataItem = {
  label: string;
  value: number;
};

export type BotTradeGraphData = BotReturnsGraphDataItem[];

export type GetNotificationsBody = {
  id: number;
  instrument_id: string;
  error_type: string;
  order_date: string;
  trading_symbol: string;
  last_fill_time: string;
  type: string;
  quantity: number;
  price: number;
  value: number;
  status: string;
  message?: string;
  tag: string;
  direction: string;
  sub_id: number;
  sub_type: string;
  description: string;
  redirect_url: string;
  image_url: string;
  event_date: string;
  user_broker_info_id: number;
};
export type GetNotifications = {
  id: number;
  subscription_id: number;
  status: boolean;
  type: string;
  title: string;
  message: string;
  body: GetNotificationsBody;
  read: boolean;
  redirect_url?: string;
  category?: string;
};
export type GetNotificationsResult = GetNotifications[];

export type GetNotificationGroupResult = {
  key: string;
  data: GetNotificationsResult;
};

export type GetNotificationResultProps = {
  data: GetNotificationsResult;
  unreadCount: number;
} | null;

export type GetNotificationGroupResultProps = {
  data: GetNotificationGroupResult[];
  unreadCount: number;
} | null;

export type ReadNotificationsParams = {
  read: boolean;
};

export type ReadNotificationsResult = {
  id: number | null;
  result: boolean;
};

export type ReadAllNotificationsParams = {
  read: boolean;
};
export type ReadAllNotificationsResult = {
  id: number | null;
  result: boolean;
};

export interface BrokerById {
  brokerId: number;
  broker: string;
  auth_1?: string;
  auth_2?: string;
  auth_3?: string;
  auth_4?: string;
  auth_5?: string;
  auth_6?: string;
  uuid: string;
  isVerified?: boolean;
  totp_verification?: boolean;
}
export type GetBrokerByIdResult = BrokerById;

export interface Broker {
  id: number;
  broker: string;
  name: string;
  username: string;
  is_auth: boolean;
  isVerified: boolean;
  last_login: string;
}

export type GetBrokersResult = Broker[];

export type BrokerFormValidationRule = {
  id: number;
  type: string;
  errorMessage: string;
  regex?: string;
};

export type BrokerFormField = {
  id: number;
  label?: string;
  key: string;
  type: string;
  defaultValue: string | object;
  validationRules: BrokerFormValidationRule[];
  values?: Array<{ key: string; value: string }>;
  url?: string;
  fields?: Array<BrokerFormField>;
  helpText?: string;
  min_symbol?: number;
  symbolData?: GetStrategyParameterKeyValue;
  readOnly?: boolean;
};

export type BrokerFormBrokerData = {
  id: number;
  key: string;
  name: string;
  description: string;
  help_link?: string;
  fields: BrokerFormField[];
  is_auth: boolean;
  show_redirect_url: boolean;
  broker_notes: string;
  image_url: string | null;
  url?: string;
};

export type BrokerFormData = {
  version: string;
  brokers: BrokerFormBrokerData[];
};

export type GetBrokerFormResult = BrokerFormData;

export type CreateBrokerParams = {
  name: string | undefined;
  broker: number;
  exchange: [];
  auth1: string;
  auth2: string;
  auth3: string;
  auth4: string;
  auth5: string;
  auth6: string;
  auth7: string;
  uuid?: string;
};

export type CreateBrokerResult = {
  error: string;
  success: string;
  loginUrl: string;
};

export type DeleteBrokerResult = {
  result: boolean;
  error: string;
  success: string;
};

export type AuthBrokerParams = {
  uuid: string;
  userId: string;
  apiKey: string;
  request_token: string;
  auth_token: string;
  feed_token: string;
  refresh_token: string;
  auth_code: string;
  state: string;
  code: string;
  RequestToken: string;
  apisession: string;
  requestToken: string;
};

export type AuthBrokerResult = {
  result: boolean;
  error: string;
  success: string;
};

export type GetBrokerAuthUrlResult = {
  loginUrl: string;
  error: string;
  success: string;
};

export type BrokerLogoutParams = {
  id: number;
};

export type BrokerLogoutResult = {
  error: string;
  success: string;
};

export type BuildRestRequestParams =
  | AuthenticateParams
  | AuthenticateWithGoogleParams
  | SignupWithGoogleParams
  | VerifyPhoneParams
  | VerifyOtpParams
  | MobileSignupParams
  | ForgotPassParams
  | ResetConfirmOtpParams
  | ResetPassParams
  | EmailActivationParam;

export type SaveUserBotParams = UserBot;

export type SaveUserBotResult = {
  status_code: number;
  queued?: boolean;
  success?: number;
  bot_id?: number;
  error?: string;
};

export type GetUserBotResult = UserBot;

export type BacktestQueue = {
  end_date: string;
  id: number;
  start_date: string;
  status: string;
  strategy_name: string;
  total_pages: number;
  count: number;
  strategy_id: number;
  bot_id: number;
  percentage: number;
};

export type GetBacktestQueueResult = { data: BacktestQueue[] };
export type GetReferraLDataAction = {
  date: string;
  user: string;
  action: string;
  credit: number;
};
export type GetReferralDetailsData = {
  referral_credit_reward_percentage: number;
  actions: GetReferraLDataAction[];
};
export type GetReferralDetails = {
  success: boolean;
  error: string;
  data: GetReferralDetailsData;
  message: string;
};
// export type GetReferralDetailsResult = GetReferralDetails[];
export type PutCancelBacktestQueueParams = {
  cancel: boolean;
};

export type PutCancelBacktestQueueResult = {
  status_code: number;
  success?: number;
  error?: string;
};

export type PostUserBotIsExistParams = { name: string };

export type PostUserBotIsExistResult = {
  status_code: number;
  success?: number;
  error?: string;
};

export type ProfitLossByDate = {
  date: string;
  profit_loss: number;
  profit_loss_percent: number;
  trades: number;
};

export type ProfitLossByDateData = {
  startDate: string;
  endDate: string;
  minDate: string;
  maxDate: string;
  profitLossData: ProfitLossByDate[];
  transformProfitLossData: ProfitLossByDateTransform[];
};

export type GetProfitLossByDateResult = ProfitLossByDateData;

// =============
export type AuthorSocialMediaLink = {
  social_platform_name: string;
  social_profile_url: string;
  social_media_icon: string;
};

export type AuthorAddress = {
  street: string;
  street2: string;
  city: string;
  zip: string;
  state: string;
  country: string;
};

export type Author = {
  success: boolean;
  subscribers_count: number;
  social_media_links: AuthorSocialMediaLink[];
  owned_strategies: GetMarketplaceBotsItem[];
  address: AuthorAddress;
  about: string;
  name: string;
  picture: string;
};

export type GetAuthorResult = Author;

export type AuthorPictureResult = {
  success: boolean;
  picture: string;
};

export type ScheduledEvent = {
  id: number;
  title: string;
  description: string;
  imageURL: string;
  eventURL: string;
  scheduledDate: string;
  label?: string;
  color?: string;
};

export type GetEventsResult = ScheduledEvent[];

export type GetSiteInfoSigninScreen = {
  top_subtitle_1: string;
  top_subtitle_2: string;
  bottom_subtitle_1: {
    label: string;
    button_label: string;
    button_link: string;
  };
  bottom_subtitle_2: string;
};

export type PortfolioSymbol = {
  label: string;
  value: string;
};

export type GetSiteInfoResult = {
  name: string;
  logo: string;
  small_logo: string;
  favicon: string;
  razorpay_key: string;
  term_link: string;
  disclaimer: string;
  signin_screen: GetSiteInfoSigninScreen;
  help_link: string;
  support_link: string;
  option_builder_toolkit_link: string;
  social_youtube: string;
  social_twitter: string;
  social_telegram: string;
  social_instagram: string;
  social_tiktok: string;
  portfolio_symbols: PortfolioSymbol[];
  telegram_bot_link: string;
  default_country: string;
  default_country_code: string;
  bot_template_help_video_link: string;
  equity_builder_symbol_exchange: string;
  currency_name: string;
  currency_symbol: string;
  equity_helpguide_url: string;
  option_youtube: string;
  speedbot_course: string;
  speedbot_youtube: string;
  notes_option_builder: string;
  notes_backtesting_option: string;
  notes_menu_notification: string;
  notes_add_credit: string;
  terms_and_conditions: string;
  refund_policy: string;
  privacy_policy: string;
  speedbot_dark_logo: string;
  webhook_signal_note: string;
};

export type GetInvoicesParams = {
  page: number;
  pageSize: number;
};

export type Invoice = {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  invoice_date: string;
  amount: number;
  order_id: string;
};

export type GetInvoicesResult = Invoice[];

export type ActivateFreeTrialResult = {
  success: boolean;
  subscription_id?: number;
  error?: string;
};

export type PaginationApiParams = {
  page: number;
  pageSize: number;
};

export type GenericApiResponse<T> =
  | {
      success: true;
      error: null;
      data: T;
    }
  | {
      success: false;
      error: string;
      data: null;
    };

export type PaginationApiResponse<T> = {
  count: number;
  total_pages: number;
  prev: boolean;
  next: boolean;
  current: number;
  result: T;
};
export type FilterMetaData = {
  exchanges?: string[];
  // categories: string[];
  // tags: string[];
  strategy?: string[];
  brokers?: string[];
};
export type ReportSearchParams = Partial<FilterMetaData> & {
  deployment?: string;
  startDate?: string;
  endDate?: string;
  search?: string;
  strategyLabel?: string | undefined;
  // riskTypes?: string[];
  // search?: string;
  // subscriptionType?: string;
};
export type GetMarketplaceResult = GenericApiResponse<
  PaginationApiResponse<GetMarketplaceBotsItem[]>
>;

export type BotTemplate = {
  id: number;
  name: string;
  tags: string[];
  symbols: string[];
  image: string | null;
  categories: string[];
  position_type: string;
  description: string;
  initial_capital: number;
  currency_symbol: string;
  risk_type: string;
  count: number;
  youtube_video_url: string;
  strategy_type: string;
};

export type BotTemplateCategory = {
  category_name: string;
  category_id: number;
  description: string;
  templates: BotTemplate[];
};

export type BotTemplateDetail = BotTemplate & {
  params: SbStrategy;
};

export type GetBotTemplatesResult = GenericApiResponse<BotTemplateCategory[]>;

export type GetBotTemplatesCategoryResult = GenericApiResponse<
  PaginationApiResponse<BotTemplate[]>
>;

export type GetBotTemplateDetailResult = GenericApiResponse<BotTemplateDetail>;

export type GetBotBuilderFormResult = BrokerFormField[] | null;
export type ReferralValidateResult = {
  success: boolean;
  message: string;
};
export type SetReferralResult = {
  success: boolean;
  error: string;
  message?: string;
};

export type GetEstimatedCreditResult = GenericApiResponse<{ credits: number }>;
export type GetBotsLogResult = {
  success: boolean;
  error: string;
  data: string;
};
export type GetBotsLogFilesDataResult = {
  isdefault: boolean;
  files: Array<string>;
  logs: string;
};
export type GetActionParams = {
  action?: string | undefined;
};

export type GetPaidAmountResult = {
  success: boolean;
  error: string;
  data: GetPaidAmountResultData;
};

export type GetPaidAmountResultData = {
  discount_percentage: number;
  estimated_price: number;
};
export type ReportDropDownValue = {
  label: string;
  value: number;
};
export type GetReportDropDownData = {
  strategies: ReportDropDownValue[];
  exchanges: ReportDropDownValue[];
  broker_accounts: ReportDropDownValue[];
};
export type ReportDropDownDataArray = GetReportDropDownData[];

export type PostCreditTransferResult = {
  success?: number;
  error?: string;
};

export type PostCreditTransferData = {
  action: string;
  amount: number;
  to_email: string;
};
export type GetPortfolioPositionsData = {
  entry_time: string;
  exit_time: string;
  profit_loss: number;
  totalfees: number;
  status: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  entry_Price: number;
  exit_price: number;
  quantity: number;
  direction: string;
  duration: string;
  position_value: number;
  mae_percent: number;
  mfe_percent: number;
  mae: number;
  mfe: number;
  instrument_id: boolean;
  trading_symbol: boolean;
  percentage_change: number;
};
export type GetPortfolioShareBotsData = {
  bot_name: string;
  total_pnl: number;
  currency_name: string;
  currency_symbol: string;
  positions: GetPortfolioPositionsData[];
};
export type GetPortfolioSharePortfolio = {
  date: string;
  total_pnl: number;
  bots: GetPortfolioShareBotsData[];
};
export type GetportfolioShareDateData = {
  date: string;
  net_profit_loss: number;
};
export type GetportfolioShareData = {
  shared_dates: GetportfolioShareDateData[];
  // shared_dates: string[];
  portfolio: GetPortfolioSharePortfolio;
};
export type GetPortfolioShareResult = {
  success: boolean;
  data: GetportfolioShareData;
  error: string;
};

export type PostPortfolioShareParams = {
  action: string;
  shared_date?: string;
};
export type PostPortfolioShareResults = {
  success: boolean;
  date: string;
  user_id: string;
};
export type GetStrategyParameterURL = {
  // data: Array<{ key: string; value: string }>;
  // data: string[];
  data: GetStrategyParameterKeyValue;
};

export type GetStrategtParameterKeyValueResult = {
  success: boolean;
  data: GetStrategyParameterKeyValue;
};
export type GetStrategyParameterExchange = Record<
  string,
  GetStrategyParameterKeyValue
>;
export type GetStrategyParameterType = Record<
  string,
  GetStrategyParameterKeyValue
>;
export type GetStrategyParameterKeyValue = { key: string; value: string }[];

export type GetAccountDeleteResult = {
  success?: number;
  error?: string;
};
export type GetTicketsData = {
  id: number;
  name: string;
  status: string;
  date: string;
};
export type GetTicketsDataResult = {
  success: boolean;
  errors: string[];
  data: GetTicketsData[];
};
export type PostTicketDataResult = {
  success: boolean;
  errors: string;
};
export type ImportStrategyResult = {
  success: boolean;
  errors: string;
  data: { bot_id: number };
};
export type PostTicketAttachmentData = {
  name: string;
  mimetype: string;
  data: string;
};
export type PostTicketData = {
  subject: string;
  description: string;
  attachments: PostTicketAttachmentData[];
};
export type GetTicketAttachmentData = {
  name: string;
  mimetype: string;
  url: string;
};
export type GetTicketMessage = {
  id: number;
  author: string;
  body: string;
  date: string;
  attachments: GetTicketAttachmentData[];
};
export type GetTicketMessageData = {
  name: string;
  description: string;
  messages: GetTicketMessage[];
  status: string;
};
export type GetTicketMessageResult = {
  success: boolean;
  errors: string[];
  data: GetTicketMessageData;
};
export type PostTicketMessageDataAttchments = {
  name: string;
  mimetype: string;
  data: string;
};
export type PostTicketMessageData = {
  message: string;
  attachments: PostTicketAttachmentData[];
  action: string;
};
export type PostTickestMessageDataResponseData = {
  ticket_id: number;
  message_id: number;
};

export type PostTicketMessageDataResponse = {
  success: boolean;
  errors: string[];
  data: PostTickestMessageDataResponseData;
};
export type GetCarouselDatumHome = {
  id: number;
  title: string;
  description: string;
  imageURL: string;
  eventURL: string;
  scheduledDate: string;
  eventType: string;
};
export type BacktestingProgress = {
  id: number;
  strategy_id: number;
  start_date: string;
  end_date: string;
  status: string;
  strategy_name: string;
  percentage: number;
};
export type TemplateCategories = {
  id: number;
  name: string;
  description: string;
};
export type GetHomePageData = {
  label: string;
  description: string;
  model: string;
  data: BotTemplate[] | TemplateCategories[] | BacktestingProgress[];
};

export type HomePageItem =
  | {
      label: string;
      description: string;
      model: 'template_strategies';
      data: BotTemplate[];
    }
  | {
      label: string;
      description: string;
      model: 'algo_category';
      data: TemplateCategories[];
    }
  | {
      label: string;
      description: string;
      model: 'backtest_pipeline';
      data: BacktestingProgress[];
    };

export type HomePageList = HomePageItem[];

export type GetHomePageDataResult = GenericApiResponse<HomePageList>;
//  {
//   success: boolean;
//   error: string;
//   data: GetHomePageData<
//     BotTemplate & BacktestingProgress & TemplateCategories
//   >[];
// };

export type GetLogoutResult = {
  success: boolean;
};
export type GetPortfolioBotResultV2 = {
  todays_change: number;
  total_profit_loss: number;
  total_profit_loss_per: number;
  initial_capital: number;
  portfolio_value: number;
  bots: PortfolioBot[];
};
export type PostPortfolioManualBotParams = {
  bot_name?: string;
  broker: string;
  capital: string;
  duration: string;
  multiplier?: string;
  type?: string;
};
export type PostPortfolioManualBotResults = {
  success: boolean;
  error: string;
  sub_id: number;
};
export type GetOptionHelpResult = Record<string, string>;

export type GetWebHookAuthKeyResult = {
  key: string;
  create_alert_img: string;
  copy_jsondata_img: string;
  copy_webhook_img: string;
  copy_strategy_jsondata_img: string;
};
export type GetBotTypeResult = {
  type: string;
};

export type SymbolMetadata = {
  lot_sizes: Record<string, number>;
  futures: Record<string, Array<string>>;
  options: Record<string, Array<string>>;
};

export type GetOptionChainMetadataResult = {
  success: boolean;
  data: SymbolMetadata;
};

export type SymbolOptionChainData = {
  iv: number;
  close: number;
  delta: number;
  theta: number;
  gamma: number;
  vega: number;
  rho: number;
  contractType?: string;
};
export type PositionsGreekDataForTable = {
  iv: number;
  close: number;
  delta: number;
  theta: number;
  gamma: number;
  vega: number;
  rho: number;
  contractType?: JSX.Element;
};

export type SymbolOptionChainDataType = {
  CE: SymbolOptionChainData;
  PE: SymbolOptionChainData;
};

export type GetOptionChainDataResult = {
  success: boolean;
  data: GetOptionChainResultDataType;
};

export type GetOptionChainResultDataType = {
  index: string;
  expiry: string;
  timestamp: string;
  spot_price: number;
  candles: Record<string, SymbolOptionChainDataType>;
  futures: Record<string, FutureDataType>;
};

export type FutureDataType = {
  close: number;
  high: number;
  low: number;
  open: number;
  timestamp: string;
  volume: number;
};

export type MTMDataType = {
  DateTime: string;
  Data: Record<string, number | undefined>;
};

export type GetMTMDataResult = {
  success: boolean;
  data: Array<MTMDataType>;
};

export type GetTradeIdeasResult = {
  success: boolean;
  trades: Array<TradesType>;
};

export type GetTradeOptionsResult = {
  success: boolean;
  data: TradeOptions;
};

export type GetTradesIdeasMetadataResult = {
  success: boolean;
  metadata: TradeIdeasMetaData;
};
export type GetFetchIframUrlResult = {
  success: boolean;
  url?: string;
};
export type GetUserActiveStatusResult = {
  success: boolean;
};
export type TGetOptionSymbols = {
  Stocks: { key: string; value?: string; label?: string }[];
};
export type TGetOptionSymbolsResult = {
  success: boolean;
  data: Record<string, TGetOptionSymbols>;
};
export type TWebHookBot = {
  name: string;
  duration: string;
  sub_id: number;
  broker_name: string;
  client_id: string;
  status: string | boolean | null;
};
export type TWebHookBotResult = {
  success: boolean;
  bots: TWebHookBot[];
};
export type TWebHookSignal = {
  signal: string;
  symbol: string;
  command: string;
  tp: number;
  tpp: number;
  sl: number;
  slp: number;
  risk: number;
  comment: boolean;
  error: string;
  time_received: string;
  time_finish: string;
  processing_duration: number;
};

export type TWebHookSignalResult = {
  success: boolean;
  bot_data: TWebHookBot;
  signals: TWebHookSignal[];
};
export type TWebHookActionParams = {
  status: string;
  sub_id: number;
};
export type TWebHookActionResult = {
  success: boolean;
  error: string;
  status_code: number;
};

export type TOTPVarificationParams = {
  totp: string;
};

export type TOTPVarificationResult = {
  verified: boolean;
  message: null | string;
};
