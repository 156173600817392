import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Autocomplete,
  TextField,
  OutlinedInput,
  Grid,
  createFilterOptions,
  createTheme,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import { ChartTypesData, IntervalsData } from './util';
import { StrategyBuilderSymbolsFormData } from './types';
import StrategyBuilderSymbolItem from './StrategyBuilderSymbolItem';
import { useTranslation } from 'react-i18next';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

// Services
import useGetInstruments from 'services/Bots/useGetInstruments';
import { Instrument } from 'types/Instrument';
import usePostUserBotsIsExist from 'services/Bots/usePostUserBotsIsExist';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import MUIRichTextEditor from 'mui-rte';
import { ThemeProvider } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

const StrategyBuilderSymbolTab = ({
  symbolData,
  setSymbolData,
  selectedExchange,
  setSelectedExchange,
}: {
  symbolData: StrategyBuilderSymbolsFormData | null;
  setSymbolData: (values: StrategyBuilderSymbolsFormData) => void;
  selectedExchange: string;
  setSelectedExchange: (selectedExchange: string) => void;
}) => {
  const { t } = useTranslation();
  const isUSAsupporInEq = useIsFeatureFlag([FEATURES.IS_USA_SUPPORT_IN_EQ]);
  const isMdUp = useGetMediaQueryUp('md');
  const { data: siteInfo } = useGetSiteInfo();
  const marketType = (siteInfo &&
    siteInfo.equity_builder_symbol_exchange &&
    siteInfo.equity_builder_symbol_exchange.split(',')) || ['NSE'];
  const exchangeFilter =
    siteInfo && siteInfo.equity_builder_symbol_exchange
      ? `&exchange=${selectedExchange}`
      : `&exchange=NSE`;
  const { data: instruments, isLoading } = useGetInstruments(
    `with_groups=1${exchangeFilter}`,
  );
  const history = useHistory();

  const mutationIsExist = usePostUserBotsIsExist();

  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option: { group: string; value: string; name: string }) =>
      option.value, // make it one for it
    limit: 500,
  });
  const theme = useTheme();

  const myTheme = createTheme({});

  Object.assign(myTheme, {
    overrides: {
      MUIRichTextEditor: {
        container: {
          border: '1px solid',
          borderColor: theme.palette.grey[500],
          borderRadius: '4px',
        },
        editor: {
          paddingLeft: '14px',
        },
        placeHolder: {
          paddingLeft: '14px',
          color: theme.palette.grey['300'],
        },
      },
    },
  });

  const indices =
    (instruments &&
      Array.from(
        instruments.reduce((acc, crr) => {
          if (crr && crr.indexes) {
            crr.indexes.forEach(index => acc.add(index));
          }
          return acc;
        }, new Set<string>()),
      )) ||
    [];

  const getIndexSymbols = (index: string) => {
    return instruments!.filter(instrument =>
      instrument.indexes?.includes(index),
    );
  };

  const state = ContentState.createFromBlockArray(
    convertFromHTML(symbolData?.description || '').contentBlocks,
    convertFromHTML(symbolData?.description || '').entityMap,
  );
  const dynamicSizeOfgrid = isUSAsupporInEq ? 3 : 6;
  const instrumentList = instruments
    ? [
        ...indices.map(indices => {
          return { group: 'Indices', value: indices, name: 'Indices' };
        }),
        ...instruments.map(instruments => {
          return {
            group: 'Symbol',
            value: instruments.trading_symbol,
            name: instruments.name,
          };
        }),
      ]
    : [];

  return (
    <>
      <Formik
        initialValues={
          symbolData
            ? symbolData
            : ({
                botName: '',
                chartType: ChartTypesData ? ChartTypesData[0].value : '',
                interval: '00:10:00',
                symbols: [] as Instrument[],
                symbol: null,
                description: '',
              } as StrategyBuilderSymbolsFormData)
        }
        validationSchema={Yup.object().shape({
          botName: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.bot_name'),
            }),
          ),
          chartType: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.chart_type'),
            }),
          ),
          interval: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.interval'),
            }),
          ),
          symbols: Yup.array()
            // .min(0, 'Atleast one symbols is required')
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.symbol'),
              }),
            ),
        })}
        onSubmit={values => {
          const isNameChanged =
            !symbolData ||
            (symbolData && symbolData.botName !== values.botName);

          if (isNameChanged) {
            mutationIsExist.mutate(
              { name: values.botName },
              {
                onSuccess: resSuccess => {
                  if (resSuccess.success) setSymbolData(values);
                },
              },
            );
          } else {
            setSymbolData(values);
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          handleBlur,
          handleChange,
          touched,
          isValid,
        }) => {
          const nameExistError =
            mutationIsExist.data && mutationIsExist.data.error
              ? mutationIsExist.data.error
              : '';

          return (
            <Box
              className="m-main-spacing"
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <CircularLoader open={isLoading || mutationIsExist.isLoading} />
              <Box sx={{ mb: 2, flex: 1 }}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      error={
                        Boolean(touched.botName && errors.botName) ||
                        Boolean(touched.botName && nameExistError)
                      }
                      variant="outlined"
                      sx={{ mb: 3 }}
                    >
                      <InputLabel htmlFor="label-botName">
                        <FormattedMessage id="user_bot_startegy.form.bot_name" />
                      </InputLabel>
                      <OutlinedInput
                        id="label-botName"
                        name="botName"
                        value={values.botName}
                        onBlur={handleBlur}
                        onChange={e => {
                          if (nameExistError) mutationIsExist.reset();
                          handleChange(e);
                        }}
                        label={t('user_bot_startegy.form.bot_name')}
                        endAdornment={
                          <InputAdornment position="end">
                            <CustomTooltip tooltipKey={'eq_bot_name'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </InputAdornment>
                        }
                      />
                      {touched.botName && errors.botName && (
                        <FormHelperText error>{errors.botName}</FormHelperText>
                      )}
                      {touched.botName && nameExistError && (
                        <FormHelperText error>{nameExistError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={dynamicSizeOfgrid}
                    lg={dynamicSizeOfgrid}
                  >
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={Boolean(touched.chartType && errors.chartType)}
                    >
                      <InputLabel id="label-chartType">
                        <FormattedMessage id="user_bot_startegy.form.chart_type" />
                      </InputLabel>
                      <Select
                        labelId="label-chartType"
                        name="chartType"
                        value={values.chartType}
                        label={t('user_bot_startegy.form.chart_type')}
                        onChange={e => {
                          setFieldValue('chartType', e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end" sx={{ mr: 1 }}>
                            <CustomTooltip tooltipKey={'eq_chart_type'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </InputAdornment>
                        }
                      >
                        {ChartTypesData &&
                          ChartTypesData.map(chartType => (
                            <MenuItem
                              key={chartType.value}
                              value={chartType.value}
                            >
                              {chartType.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.chartType && errors.chartType && (
                        <FormHelperText error>
                          {errors.chartType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={dynamicSizeOfgrid}
                    lg={dynamicSizeOfgrid}
                  >
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={Boolean(touched.interval && errors.interval)}
                    >
                      <InputLabel id="label-interval">
                        <FormattedMessage id="user_bot_startegy.form.interval" />
                      </InputLabel>
                      <Select
                        labelId="label-interval"
                        name="interval"
                        value={values.interval}
                        label={t('user_bot_startegy.form.interval')}
                        onChange={e => {
                          setFieldValue('interval', e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end" sx={{ mr: 1 }}>
                            <CustomTooltip tooltipKey={'eq_interval'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </InputAdornment>
                        }
                      >
                        {IntervalsData.map(interval => (
                          <MenuItem value={interval.value} key={interval.value}>
                            {interval.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.interval && errors.interval && (
                        <FormHelperText error>{errors.interval}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {isUSAsupporInEq && (
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="label-marketType">
                          <FormattedMessage id="user_bot_startegy.form.market_type" />
                        </InputLabel>
                        <Select
                          labelId="label-marketType"
                          name="marketType"
                          value={selectedExchange}
                          label={t('user_bot_startegy.form.market_type')}
                          onChange={e => {
                            void setSelectedExchange(e.target.value);
                            setFieldValue('symbols', []);
                          }}
                          endAdornment={
                            <InputAdornment position="end" sx={{ mr: 1 }}>
                              <CustomTooltip tooltipKey={'eq_market_type'}>
                                {({ isTooltipAvailable }) =>
                                  isTooltipAvailable ? (
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                }
                              </CustomTooltip>
                            </InputAdornment>
                          }
                        >
                          {marketType &&
                            marketType.map(market => (
                              <MenuItem key={market} value={market}>
                                {market}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={Boolean(touched.symbol && errors.symbol)}
                    >
                      <Autocomplete
                        fullWidth
                        blurOnSelect
                        value={null}
                        getOptionLabel={option => option.value}
                        groupBy={option => option.group}
                        filterOptions={filterOptions}
                        onChange={(event: React.SyntheticEvent, newSymbol) => {
                          if (newSymbol) {
                            if (newSymbol.group === 'Indices') {
                              const indexSymbols = getIndexSymbols(
                                newSymbol.value,
                              ).filter(
                                x =>
                                  values.symbols.findIndex(
                                    val =>
                                      val.trading_symbol === x.trading_symbol,
                                  ) < 0,
                              );
                              if (indexSymbols.length === 0) return;
                              setFieldValue('symbols', [
                                ...values.symbols,
                                ...indexSymbols,
                              ]);
                            } else if (newSymbol.group === 'Symbol') {
                              const selectedSymbol =
                                instruments &&
                                instruments?.filter(
                                  instruments =>
                                    instruments.trading_symbol ===
                                    newSymbol.value,
                                );
                              const arrSymbols = [...values.symbols];
                              // If already exist in array then avoid to push
                              if (
                                arrSymbols.filter(
                                  x =>
                                    selectedSymbol &&
                                    x.trading_symbol ===
                                      selectedSymbol[0].trading_symbol,
                                ).length
                              )
                                return;
                              if (selectedSymbol) {
                                arrSymbols.push(selectedSymbol[0]);
                              }
                              setFieldValue('symbols', arrSymbols);
                              setFieldValue('symbol', null);
                            }
                          }
                        }}
                        options={instrumentList || []}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('user_bot_startegy.form.symbol')}
                            error={Boolean(touched.symbol && errors.symbol)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end" sx={{ mr: -3 }}>
                                  <CustomTooltip tooltipKey={'eq_symbol'}>
                                    {({ isTooltipAvailable }) =>
                                      isTooltipAvailable ? (
                                        <IconButton>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </CustomTooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        renderOption={(props, option) => {
                          return option.name !== 'Indices' ? (
                            <li {...props} key={option.value}>
                              {`${option.value} ( ${option.name} )`}
                            </li>
                          ) : (
                            <li {...props} key={option.value}>
                              {`${option.value}`}
                            </li>
                          );
                        }}
                      />
                      {touched.symbol && errors.symbol && (
                        <FormHelperText error>{errors.symbol}</FormHelperText>
                      )}
                      {touched.symbols && errors.symbols && (
                        <FormHelperText error>{errors.symbols}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FeatureFlag
                      features={[FEATURES.IS_BOT_DISCRIPTION]}
                      fallback={<></>}
                    >
                      <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                          controls={[
                            'title',
                            'bold',
                            'italic',
                            'underline',
                            'highlight',
                            'undo',
                            'redo',
                          ]}
                          // value={mutation.isSuccess && data}
                          onChange={values => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
                            setFieldValue(
                              'description',
                              stateToHTML(values.getCurrentContent()),
                            );
                          }}
                          defaultValue={JSON.stringify(convertToRaw(state))}
                          inlineToolbar
                          label="Description"
                        />
                      </ThemeProvider>
                    </FeatureFlag>
                  </Grid>
                </Grid>
                <Box>
                  <Box
                    sx={{
                      py: 2,
                      display: { xs: 'flex', md: 'flex' },
                    }}
                  >
                    <Typography
                      variant="h6"
                      flex="1"
                      fontWeight={500}
                      alignSelf={'self-end'}
                    >
                      <FormattedMessage id="user_bot_startegy.symbols" />
                    </Typography>
                    <FeatureFlag
                      features={[FEATURES.IS_EQUITY_BOTBUILDER_STOCK_TEMPLATES]}
                      fallback={<></>}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                          mt: !isMdUp ? 1 : 0,
                        }}
                      >
                        <MuiButton onClick={() => setFieldValue('symbols', [])}>
                          {t('buttons.clear')}
                        </MuiButton>
                      </Box>
                    </FeatureFlag>
                  </Box>
                  <ItemSeparator variant="2px" />
                  <StrategyBuilderSymbolItem
                    symbols={values.symbols}
                    deleteSymbol={(id: number) => {
                      const arrSymbols = [...values.symbols];
                      arrSymbols.splice(id, 1);
                      setFieldValue('symbols', arrSymbols);
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 0,
                  textAlign: 'center',
                  display: { xs: 'block', md: 'flex' },
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                }}
              >
                <MuiButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth={!isMdUp}
                  sx={{
                    mb: { xs: 2, md: 0 },
                  }}
                  disabled={values.symbols.length === 0 || !isValid}
                >
                  <FormattedMessage id="buttons.continue" />
                </MuiButton>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth={!isMdUp}
                  onClick={() => history.goBack()}
                  sx={{
                    mr: { xs: 0, md: 2 },
                  }}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
export default memo(StrategyBuilderSymbolTab);
