import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import OptionStrategyBuilderContent from 'app/components/Bots/StrategyBuilder/OptionStrategyBuilderContent';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// Services
import { getQueryParam } from 'utils/GenericFunctions';
import EquityStrategyBuilderContent from 'app/components/Bots/StrategyBuilder/EquityStrategyBuilderContent';
import useGetBotTemplateDetails from 'services/BotTemplates/useGetTemplateDetails';
import { UserBot } from 'app/components/Bots/StrategyBuilder/types';
import useGetProfile from 'services/Menu/useGetProfile';
import { isUserLoggedIn } from 'utils/auth';

export function TemplateBotBuilder() {
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <Helmet>
        <title>Bot Builder</title>
        <meta name="description" content="Discover bots search" />
      </Helmet>

      <TemplateBotBuilderWrapper botId={id} />
    </>
  );
}

const TemplateBotBuilderWrapper = ({ botId }: { botId: string }) => {
  const isLoggedIn = isUserLoggedIn();
  const { data: templateDetail, isLoading } = useGetBotTemplateDetails(botId);
  const bid = parseInt(botId);
  let type = getQueryParam('type').toLowerCase();

  if (!type) type = 'equity';
  if (templateDetail && templateDetail.params?.type)
    type = templateDetail.params?.type;
  const { data: profile } = useGetProfile();
  const userBotDetails = {
    name:
      profile?.name == ''
        ? `${profile.name}` + `${templateDetail?.name}`
        : `${profile?.name}'s${' '}` + `${templateDetail?.name}`,
    // name: `${'your first'}'s ${templateDetail?.name}`,
    sb_strategy: templateDetail?.params,
  } as UserBot;

  return (
    <>
      <CircularLoader open={isLoading} />
      {isLoggedIn
        ? templateDetail && (
            <>
              {type === 'equity' ? (
                <EquityStrategyBuilderContent
                  isTemplate
                  botId={bid}
                  subId={0}
                  userBotDetails={userBotDetails}
                  isLoading={isLoading}
                />
              ) : (
                <OptionStrategyBuilderContent
                  isTemplate
                  botId={bid}
                  subId={0}
                  userBotDetails={userBotDetails}
                  isLoading={isLoading}
                  botBuilderType={'option'}
                />
              )}
            </>
          )
        : templateDetail && (
            <>
              {type === 'equity' ? (
                <EquityStrategyBuilderContent
                  isTemplate
                  botId={bid}
                  subId={0}
                  userBotDetails={userBotDetails}
                  isLoading={isLoading}
                />
              ) : (
                <OptionStrategyBuilderContent
                  isTemplate
                  botId={bid}
                  subId={0}
                  userBotDetails={userBotDetails}
                  isLoading={isLoading}
                  botBuilderType={'option'}
                />
              )}
            </>
          )}
    </>
  );
};
