import { useQuery } from 'react-query';
import { GetOptionSymbols } from 'services/apiServices';
import { Errors, TGetOptionSymbolsResult } from 'types/ApiServicesTypes';

export default function useGetOptionSymbols(
  exchange?: string,
  india?: boolean,
  usa?: boolean,
  fromBackend?: boolean,
) {
  const objQuery = useQuery<TGetOptionSymbolsResult, Errors>(
    ['GetStrategyParameterUrl', exchange],
    () => GetOptionSymbols(exchange),
    {
      enabled: (india || usa) && fromBackend,
    },
  );

  return objQuery;
}
