import {
  CloseOutlined,
  ContentCopy,
  InfoOutlined,
  TaskAlt,
} from '@mui/icons-material';
import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import useGetWebHookAuthKey from 'services/Bots/useGetWebHookAuthKey';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import useClipboard from 'services/WebHookSignals/useCopytoClipboard';
import usePutWebhookSignalAction from 'services/WebHookSignals/usePutWebhookSignalAction';
import { TWebHookBot } from 'types/ApiServicesTypes';
import { getFullDomain } from 'utils/GenericFunctions';
interface IWebHookBotModal {
  open: boolean;
  handleClose: () => void;
  data: TWebHookBot;
}
const WebHookBotModal = ({ open, handleClose, data }: IWebHookBotModal) => {
  const { copied, copyToClipboard } = useClipboard();
  //   const { data: brokersData, isLoading: getBrokerLoaing } = useGetBrokers();
  const theme = useTheme();
  const { data: siteInfo } = useGetSiteInfo();
  const curDomain = getFullDomain();
  const { data: webhookurl } = useGetWebHookAuthKey();
  const mutation = usePutWebhookSignalAction();
  const { name, broker_name, status, duration, sub_id } = data;
  const webhook = `${curDomain}/api/webhook/signal?key=${webhookurl?.key}`;
  const handleActionClick = (status: string) => {
    mutation.mutate(
      { status, sub_id },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
    handleClose();
  };
  return (
    <MuiModal
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      header={
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h6">{name}</Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
      }
      body={
        <Box px={2}>
          {/* <TextField
            fullWidth
            placeholder="Bot name"
            value={name}
            disabled
            sx={{ mb: 2 }}
          /> */}

          <Box
            sx={{
              mb: 2,
              backgroundColor: theme.palette.info.light,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              p: 1,
              gap: 1,
              borderRadius: 2,
            }}
          >
            <InfoOutlined />
            <Typography variant="body2" color={theme.palette.text.primary}>
              {siteInfo?.webhook_signal_note
                ? siteInfo.webhook_signal_note
                : 'webhook signal'}
            </Typography>
          </Box>

          <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="body1" gutterBottom>
              Bot info
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                {/* <Typography>Speedbot ID</Typography>
                <TextField
                  value="12345"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  sx={{ mt: 1 }}
                /> */}
                <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="label-connection-url">
                    Connection URL
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    id="label-connection-url"
                    name="connection_url"
                    label="Connection URL"
                    value={webhook}
                    readOnly
                    fullWidth
                    //   disabled={isReadonly ? isReadonly : !values.enable}
                    endAdornment={
                      <IconButton
                        aria-label="Copy"
                        onClick={() => copyToClipboard(webhook, webhook)}
                      >
                        {copied === webhook ? (
                          <TaskAlt sx={{ color: 'success.main' }} />
                        ) : (
                          <ContentCopy />
                        )}
                      </IconButton>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="label-connection-url">
                    Speedbot Id
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    id="label-connection-url"
                    name="Speedbot Id"
                    label="Speedbot Id"
                    value={sub_id}
                    readOnly
                    fullWidth
                    //   disabled={isReadonly ? isReadonly : !values.enable}
                    endAdornment={
                      <IconButton
                        aria-label="Copy"
                        onClick={() =>
                          copyToClipboard(String(sub_id), String(sub_id))
                        }
                      >
                        {copied === String(sub_id) ? (
                          <TaskAlt sx={{ color: 'success.main' }} />
                        ) : (
                          <ContentCopy />
                        )}
                      </IconButton>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Card>

          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="body1" gutterBottom>
              Live Info
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="label-broker">Broker</InputLabel>
                  <OutlinedInput
                    type="text"
                    id="label-broker"
                    name="Broker"
                    label="Broker"
                    value={broker_name}
                    readOnly
                    fullWidth
                    //   disabled={isReadonly ? isReadonly : !values.enable}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ my: 1 }}>
                  <InputLabel htmlFor="label-connection-url">
                    End Date of Your signal Bot
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    id="label-connection-url"
                    name="End Date of Your signal Bot"
                    label="End Date of Your signal Bot"
                    value={duration}
                    readOnly
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      }
      footer={
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          p={2}
          gap={2}
        >
          <MuiButton
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={handleClose}
          >
            Cancel
          </MuiButton>
          <MuiButton
            size="large"
            variant="outlined"
            color={status === 'Active' ? 'error' : 'success'}
            fullWidth={true}
            onClick={() =>
              handleActionClick(status === 'Active' ? 'Paused' : 'Active')
            }
          >
            {status === 'Active' ? 'Stop Live' : 'Start Live'}
          </MuiButton>
        </Box>
      }
    />
  );
};

export default WebHookBotModal;
