// import { Helmet } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import OptionStrategyBuilderContent from 'app/components/Bots/StrategyBuilder/OptionStrategyBuilderContent';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// Services
import useGetUserBot from 'services/Bots/useGetUserBot';
import { getQueryParam } from 'utils/GenericFunctions';
import EquityStrategyBuilderContent from 'app/components/Bots/StrategyBuilder/EquityStrategyBuilderContent';
import CodeStrategyBuilderContent from 'app/components/Bots/StrategyBuilder/CodeStrategyBuilderContent';

export function BotStrategyBuilderPage() {
  const { id, sid } = useParams<{ id?: string; sid?: string }>();
  const botId = id ? parseInt(id) : 0;
  const subId = sid ? parseInt(sid) : 0;
  // const type = getQueryParam('type');

  return (
    <>
      <Helmet>
        <title>Discover bots search</title>
        <meta name="description" content="Discover bots search" />
      </Helmet>

      <StrategyBuilderWrapper botId={botId} subId={subId} />
    </>
  );
}
const StrategyBuilderWrapper = ({
  botId,
  subId,
}: {
  botId: number;
  subId: number;
  source?: string;
}) => {
  let type = getQueryParam('type').toLowerCase();
  const selectedVersion = getQueryParam('versionid').toLowerCase();
  const { data: userBotDetails, isLoading } = useGetUserBot(
    botId,
    selectedVersion,
  );
  const exchangeMapping: Record<string, string> = {
    nse: 'option',
    bse: 'option',
    usa: 'option',
    crypto_india: 'crypto_india',
    crypto_global: 'crypto_global',
    mcx: 'mcx',
  };
  const botBuilderType: string | undefined =
    userBotDetails && Object.keys(userBotDetails).length > 0
      ? exchangeMapping[userBotDetails.exchange?.toLowerCase() as string]
      : type;
  // const isCommodity: boolean =
  //   userBotDetails && userBotDetails.exchange === 'MCX'
  //     ? true
  //     : type === 'commodity'
  //     ? true
  //     : false;
  if (userBotDetails && userBotDetails.sb_strategy?.type)
    type = userBotDetails.sb_strategy?.type;
  return (
    <>
      <CircularLoader open={isLoading} />
      {type === 'equity' ? (
        <EquityStrategyBuilderContent
          botId={botId}
          subId={subId}
          userBotDetails={userBotDetails}
          isLoading={isLoading}
        />
      ) : type === 'code' ? (
        <CodeStrategyBuilderContent
          botId={botId}
          subId={subId}
          userBotDetails={userBotDetails}
          isLoading={isLoading}
        />
      ) : type !== 'code' && type !== 'equity' ? (
        <OptionStrategyBuilderContent
          botId={botId}
          subId={subId}
          userBotDetails={userBotDetails}
          isLoading={isLoading}
          botBuilderType={botBuilderType}
        />
      ) : (
        <></>
      )}
    </>
  );
};
