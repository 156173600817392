import { Box, Typography } from '@mui/material';
import { getDate, getTime } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';

import { TWebHookSignal } from 'types/ApiServicesTypes';

interface IwebHookItem {
  bot: TWebHookSignal;
  handleViewSignalClick: (bot: TWebHookSignal) => void;
}
const WebhookSignalDetailItem = ({
  bot,
  handleViewSignalClick,
}: IwebHookItem) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2, cursor: 'pointer' }}
      onClick={() => {
        handleViewSignalClick(bot);
      }}
    >
      {/* {order.status && order.status !== '3' && orderStatus[order.status] && (
        <Box sx={{ mb: 1 }}>
          <MuiChip
            icon={<>{orderStatus[order.status].icon}</>}
            label={<>{orderStatus[order.status].value}</>}
            size="xsmallicon"
            color={orderStatus[order.status].chipColor as ChipColor}
          />
        </Box>
      )} */}
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="text.disabled">
            {bot.processing_duration.toFixed(2)} s
          </Typography>
        </Box>

        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right', lineHeight: 0 }}>
          <MuiChip
            label={`risk: ${bot.risk}`}
            size="xsmall"
            color={'warninglight'}
            sx={{ maxWidth: '100px' }}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{bot.signal}</span>
            {/* <MuiChip
              label={order.direction === '0' ? 'B' : 'S'}
              color={order.direction === '0' ? 'infolight' : 'purplelight'}
              size="singleLetter"
              variant="filled"
              sx={{ ml: 1 }}
            /> */}
          </Typography>
        </Box>
        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right' }}>
          <Typography variant="body2" color="info.main">
            {bot.comment}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          {getDate(bot.time_received, false)},{getTime(bot.time_received)}
        </Box>
        <Box
          sx={{
            flex: 'initial',
            pl: 2,
            textAlign: 'right',
            columnGap: 1,
            display: 'flex',
          }}
        >
          <Typography variant="body3" color="text.disabled">
            SL: {bot.sl}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          {getDate(bot.time_finish, false)},{getTime(bot.time_finish)}
        </Box>
        <Box
          sx={{
            flex: 'initial',
            pl: 2,
            textAlign: 'right',
            columnGap: 1,
            display: 'flex',
          }}
        >
          <Typography variant="body3" color="text.disabled">
            TP: {bot.tp}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WebhookSignalDetailItem;
