import { useState, useEffect, memo, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import MainLayoutContainer from 'app/design/layouts/MainLayoutContainer';
import { LayoutProps } from 'types/ComponentTypes';
import SliceErrorAlert from 'app/design/uiComponents/SliceErrorAlert';
import { ToastProvider } from '../../Context/ToastContext';
import Toast from '../../Context/Toast';
import AppbarLogo from './AppbarLogo';
import AppbarNavigationMenu from './AppbarNavigationMenu';
import AppbarNotification from './AppbarNotification';
import { useQueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBack from 'app/components/Common/ErrorFallBack';
import EventModal from 'app/components/Common/EventModal';
import { isUserLoggedIn } from 'utils/auth';
import SignInModal from 'app/components/Auth/SignInModal';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import SideNavigation from './SideNavigation';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import useGetProfile from 'services/Menu/useGetProfile';
import { PRODUCT_TOUR } from 'constants/common';

function MainLayout({ children }: LayoutProps) {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [activeNav, setActiveNav] = useState<string>('');
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const { reset } = useQueryErrorResetBoundary();
  const isLoggedIn = isUserLoggedIn();
  const isMdDown = useGetMediaQueryDown('md');
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  const isShowIntroAtSignin = useIsFeatureFlag([
    FEATURES.IS_SHOW_INTRO_AT_SIGNIN,
  ]);
  const [isOpenLoginModal, setOpenLoginModal] = useState(
    isShowIntroAtSignin ? (isMdDown ? true : false) : false,
  );
  useEffect(() => {
    if (isLoggedIn) {
      setOpenLoginModal(false);
      return;
    }
  }, [isLoggedIn]);
  useEffect(() => {
    let page = location.pathname.split('/')[1];
    if (page === 'change-password') page = 'profile';
    if (!isSideBarView && page.startsWith('broker')) page = 'profile';
    if (page.startsWith('bot')) page = 'portfolio';
    if (page.startsWith('template')) page = 'templates';
    if (page.startsWith('trader')) page = 'traders';
    if (page.startsWith('report')) page = 'report';
    if (page.startsWith('home')) page = 'home';
    if (page.startsWith('signal')) page = 'signal';
    setActiveNav(page);
  }, [location.pathname, isSideBarView]);
  const opnLgnFun = (val: boolean) => {
    setOpenLoginModal(val);
  };
  // Check internet connection is enabled or not
  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      setIsOnline(true);
      return;
    }
    setIsOnline(false);
  };

  // const isShowProductTour = useIsFeatureFlag([FEATURES.IS_SHOW_PRODUCT_TOUR]);

  const isPortFolio = window.location.pathname.startsWith('/portfolio');
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('action')) {
      if (queryParams.get('action') === 'login') {
        setOpenLoginModal(true);
      }
      queryParams.delete('action');
    }
    if (isPortFolio) {
      return;
    }
    history.replace({
      search: queryParams.toString(),
    });
  }, [history, location.search, isPortFolio]);

  // Bind events for check internet connection is enabled or not
  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);
  const { data: profile } = useGetProfile();

  const isAuthorProfile = location.pathname.startsWith('/discover/author');
  const isMenuPage = location.pathname === '/menu';

  if (!localStorage.getItem(PRODUCT_TOUR)) {
    localStorage.setItem(PRODUCT_TOUR, '{}');
  }
  return (
    <ToastProvider>
      <Toast />
      <Box
        sx={{
          height: '100%',
        }}
      >
        <MainLayoutContainer
          maxWidth={isSideBarView ? 'auto' : 'xl'}
          // width={'auto'}
          display={isSideBarView ? 'flex' : ''}
          isSideNav={isSideBarView}
          sx={{ pb: { xs: 8, md: isAuthorProfile || isMenuPage ? 0 : 2 } }}
          id={isSideBarView ? 'sideBar-container' : 'parent-container'}
        >
          <>
            {!isMdDown && isSideBarView && (
              <SideNavigation activeNav={activeNav} opnLgnFun={opnLgnFun} />
            )}
            <Box
              display="flex"
              height="100%"
              width={isSideBarView ? 'calc(100% - 240px)' : 'auto'}
              flexDirection="column"
              flexGrow={isSideBarView ? 1 : 0}
              overflow={isMdDown ? 'auto' : ''}
              sx={{ pb: { xs: isMenuPage ? 0 : 4, md: 0 } }}
              id="main-container"
            >
              <ErrorBoundary
                key={location.pathname}
                FallbackComponent={ErrorFallBack}
                onReset={reset}
              >
                {children}
              </ErrorBoundary>
              {!isOnline && (
                <SliceErrorAlert message="Network unreachable. Check your connection." />
              )}
              {(isMdDown || !isSideBarView) && (
                <Box
                  sx={{
                    position: 'fixed',
                    zIndex: 1,
                    bottom: 0,
                    left: '-6px',
                    right: 0,
                    boxShadow: 'none',
                    borderTop: '2px solid',
                    borderColor: 'grey.300',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    [theme.breakpoints.up('md')]: {
                      top: 0,
                      bottom: 'auto',
                      borderTop: 'none',
                      borderBottom: '2px solid',
                      borderColor: 'grey.300',
                      display: 'flex',
                    },
                  }}
                >
                  <Box
                    maxWidth="xl"
                    display="flex"
                    alignItems="center"
                    sx={{ px: [0, 0, 3, 3], width: '100%' }}
                    id="navBar"
                  >
                    <AppbarLogo />
                    <Box flex={1}>
                      <AppbarNavigationMenu
                        open={opnLgnFun}
                        activeNav={activeNav}
                        profile={profile}
                      />
                    </Box>
                    <Suspense fallback={<div>Loading..</div>}>
                      <AppbarNotification
                        activeNav={activeNav}
                        profile={profile}
                      />
                    </Suspense>
                  </Box>
                </Box>
              )}
            </Box>
            {isLoggedIn && (
              <Suspense fallback={<></>}>
                <EventModal />
              </Suspense>
            )}

            {!isLoggedIn && (
              <SignInModal
                open={isOpenLoginModal}
                handleClose={() => setOpenLoginModal(false)}
              />
            )}
          </>
        </MainLayoutContainer>
      </Box>
    </ToastProvider>
  );
}

export default memo(MainLayout);
