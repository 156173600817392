import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { PutWebhookSignalAction } from 'services/apiServices';
import {
  Errors,
  TWebHookActionParams,
  TWebHookActionResult,
} from 'types/ApiServicesTypes';

export default function usePutWebhookSignalAction() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    TWebHookActionResult,
    Errors,
    Pick<TWebHookActionParams, 'status' | 'sub_id'>
  >('PutWebHookAction', values =>
    PutWebhookSignalAction(values, values.sub_id)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
