import { Route, Switch, useLocation } from 'react-router-dom';
// import { lazyLoad } from 'utils/loadable';
import MainLayout from 'app/components/Layouts/MainLayout';
// project imports
import AuthGuard from 'routes/route-guard/AuthGuard';
// import { QueryCacheInvalidateOnSocketNotification } from 'app/components/Common/QueryCacheInvalidation/QueryCacheInvalidateOnSocketNotification';
import { BotAdvanceStatsPage } from 'app/pages/Bots/BotAdvanceStatsPage';
import { BotBackTestingOrdersPage } from 'app/pages/Bots/BotBackTestingOrdersPage';
import { BotBackTestingPage } from 'app/pages/Bots/BotBackTestingPage';
import { BotSignalsPage } from 'app/pages/Bots/BotSignalsPage';
import { BotSignalsTodaysPositioPage } from 'app/pages/Bots/BotSignalsTodaysPositioPage';
import { BotSignalsTodaysOrderPage } from 'app/pages/Bots/BotSignalsTodaysOrderPage';
import { BotSignalsPositionsPage } from 'app/pages/Bots/BotSignalsPositionsPage';
import { BotSignalsOrdersPage } from 'app/pages/Bots/BotSignalsOrdersPage';
import { BotBackTestingPositionsPage } from 'app/pages/Bots/BotBackTestingPositionsPage';
import { BotBackTestingProfitLossByDateReportPage } from 'app/pages/Bots/BotBackTestingProfitLossByDateReportPage';
import { BotBackTestingSymbolsPage } from 'app/pages/Bots/BotBackTestingSymbolsPage';
import { BotDetailsPage } from 'app/pages/Bots/BotDetailsPage';
import { BotDetailSymbolsPage } from 'app/pages/Bots/BotDetailSymbolsPage';
import { BotHistoryDetailPage } from 'app/pages/Bots/BotHistoryDetailPage';
import { BotHistoryOrdersPage } from 'app/pages/Bots/BotHistoryOrdersPage';
import { BotHistoryPage } from 'app/pages/Bots/BotHistoryPage';
import { BotHistoryPositionsPage } from 'app/pages/Bots/BotHistoryPositionsPage';
import { BotHistoryProfitLossByDateReportPage } from 'app/pages/Bots/BotHistoryProfitLossByDateReportPage';
import { BotHistorySymbolsPage } from 'app/pages/Bots/BotHistorySymbolsPage';
import { BotOrdersPage } from 'app/pages/Bots/BotOrdersPage';
import { BotPositionsPage } from 'app/pages/Bots/BotPositionsPage';
// import { BotsPage } from 'app/pages/Bots/BotsPage';
import { BotTradeStatsPage } from 'app/pages/Bots/BotTradeStatsPage';
import { SellOnMarketAddPage } from 'app/pages/Bots/SellOnMarketAddPage';
import { SellOnMarketBillingDetailsPage } from 'app/pages/Bots/SellOnMarketBillingDetailsPage';
import { SellOnMarketPage } from 'app/pages/Bots/SellOnMarketPage';
import { SellOnMarketUpdatePage } from 'app/pages/Bots/SellOnMarketUpdatePage';
import { UserBotBacktestingPage } from 'app/pages/Bots/UserBotBacktestingPage';
import { UserBotStrategyDetailPage } from 'app/pages/Bots/UserBotStrategyDetailPage';
import { UserBotTradingviewPage } from 'app/pages/Bots/UserBotTradingviewPage';
import { AddBrokerPage } from 'app/pages/Menu/AddBrokerPage';
import { BillingDetailsPage } from 'app/pages/Menu/BillingDetailsPage';
import { BrokerAuthPage } from 'app/pages/Menu/BrokerAuthPage';
import { BrokersPage } from 'app/pages/Menu/BrokersPage';
import { ChangePasswordPage } from 'app/pages/Menu/ChangePasswordPage';
import { EditBrokerPage } from 'app/pages/Menu/EditBrokerPage';
import { MenuNotificationsPage } from 'app/pages/Menu/MenuNotificationsPage';
import { MenuPage } from 'app/pages/Menu/MenuPage';
import { PlanChangePage } from 'app/pages/Menu/PlanChangePage';
import { PlanDetailsPage } from 'app/pages/Menu/PlanDetailsPage';
import { PlanUpgradePage } from 'app/pages/Menu/PlanUpgradePage';
import { ProfileEditPage } from 'app/pages/Menu/ProfileEditPage';
import { ProfilePage } from 'app/pages/Menu/ProfilePage';
import { ReportsPage } from 'app/pages/Menu/ReportsPage';
import { AuthorProfilePage } from 'app/pages/Merketplace/AuthorProfilePage';
import { MarketplaceBotDetailPage } from 'app/pages/Merketplace/MarketplaceBotDetailPage';
import { MarketplaceBotSearchPage } from 'app/pages/Merketplace/MarketplaceBotSearchPage';
import { MarketplaceCategoryPage } from 'app/pages/Merketplace/MarketplaceCategoryPage';
import { MarketplaceOrdersPage } from 'app/pages/Merketplace/MarketplaceOrdersPage';
import { MarketplacePage } from 'app/pages/Merketplace/MarketplacePage';
import { MarketplacePositionsPage } from 'app/pages/Merketplace/MarketplacePositionsPage';
import { MarketplaceProfitLossByDateReportPage } from 'app/pages/Merketplace/MarketplaceProfitLossByDateReportPage';
import { MarketplaceSymbolsPage } from 'app/pages/Merketplace/MarketplaceSymbolsPage';
import { MarketplaceBotsComparePage } from 'app/pages/Merketplace/MarketplaceBotsComparePage';
import { NotificationControllerPage } from 'app/pages/Notification/NotificationControllerPage';
import { OptionsBuilderPage } from 'app/pages/OptionsStrategyBuilder/OptionsBuilderPage';
import { PortfolioDetailPage } from 'app/pages/Portfolio/PortfolioDetailPage';
import { PortfolioOrdersPage } from 'app/pages/Portfolio/PortfolioOrdersPage';
import { PortfolioPage } from 'app/pages/Portfolio/PortfolioPage';
import { PortfolioPositionsPage } from 'app/pages/Portfolio/PortfolioPositionsPage';
import { PortfolioProfitLossByDateReportPage } from 'app/pages/Portfolio/PortfolioProfitLossByDateReportPage';
import { PortfolioStatisticsOrdersPage } from 'app/pages/Portfolio/PortfolioStatisticsOrdersPage';
import { PortfolioStatisticsPositionsPage } from 'app/pages/Portfolio/PortfolioStatisticsPositionsPage';
import { PortfolioStatisticsSymbolsPage } from 'app/pages/Portfolio/PortfolioStatisticsSymbolsPage';
import { PortfolioSymbolsPage } from 'app/pages/Portfolio/PortfolioSymbolsPage';
import { StatisticsPage } from 'app/pages/Portfolio/StatisticsPage';
import { StrategyBuilderPage } from 'app/pages/StrategyBuilder/StrategyBuilderPage';
import { MenuWalletPage } from 'app/pages/Menu/MenuWalletPage';
import { TradersPage } from 'app/pages/Traders/TradersPage';
import { TradersSearchPage } from 'app/pages/Traders/TradersSearchPage';
import { TradersCategoryPage } from 'app/pages/Traders/TradersCategoryPage';
import { TradersDetailsPage } from 'app/pages/Traders/TradersDetailsPage';
import ReferralPage from 'app/pages/Menu/ReferralPage';
import HistoryReportsPage from 'app/pages/Reports/ReportsPage';
import ReportsSearchPage from 'app/pages/Reports/ReportsSearchPage';
import CodeStrategyLogsPage from 'app/components/Bots/StrategyBuilder/CodeStrategyLogsPage';
import MenuHelpPage from 'app/pages/Menu/MenuHelpPage';
import TicketDetailPage from 'app/pages/Menu/TicketDetailPage';
import { PortfolioSignalsPage } from 'app/pages/Portfolio/PortfolioSignalsPage';
import { ToggleContextProvider } from 'app/components/Context/ToggleViewContext';
import Simulator from 'app/pages/Simulator';
import TradeIdeas from 'app/pages/TradeIdeas/TradeIdeasPage';
import TradeOptions from 'app/pages/TradeIdeas/TradeoptionsPage';
import WebHookSignals from 'app/pages/Signals/WebHookSignals';
import WebHookSignalDetails from 'app/pages/Signals/WebHookSignalDetails';
// // sample page routing

// const PortfolioPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioPage'),
//   module => module.PortfolioPage,
// );
// const PortfolioDetailPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioDetailPage'
//     ),
//   module => module.PortfolioDetailPage,
// );
// const PortfolioPositionsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioPositionsPage'
//     ),
//   module => module.PortfolioPositionsPage,
// );
// const PortfolioOrdersPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioOrdersPage'
//     ),
//   module => module.PortfolioOrdersPage,
// );
// const PortfolioSymbolsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioSymbolsPage'
//     ),
//   module => module.PortfolioSymbolsPage,
// );
// const StatisticsPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Portfolio/StatisticsPage'),
//   module => module.StatisticsPage,
// );
// const PortfolioStatisticsPositionsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioStatisticsPositionsPage'
//     ),
//   module => module.PortfolioStatisticsPositionsPage,
// );
// const PortfolioStatisticsOrdersPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioStatisticsOrdersPage'
//     ),
//   module => module.PortfolioStatisticsOrdersPage,
// );
// const PortfolioStatisticsSymbolsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioStatisticsSymbolsPage'
//     ),
//   module => module.PortfolioStatisticsSymbolsPage,
// );

// const MarketplacePage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplacePage'),
//   module => module.MarketplacePage,
// );
// const MarketplaceBotDetailPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceBotDetailPage'
//     ),
//   module => module.MarketplaceBotDetailPage,
// );
// const MarketplaceCategoryPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceCategoryPage'
//     ),
//   module => module.MarketplaceCategoryPage,
// );
// const MarketplaceBotSearchPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceBotSearchPage'
//     ),
//   module => module.MarketplaceBotSearchPage,
// );
// const MarketplacePositionsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplacePositionsPage'
//     ),
//   module => module.MarketplacePositionsPage,
// );
// const MarketplaceOrdersPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceOrdersPage'
//     ),
//   module => module.MarketplaceOrdersPage,
// );
// const MarketplaceSymbolsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceSymbolsPage'
//     ),
//   module => module.MarketplaceSymbolsPage,
// );
// const MarketplaceProfitLossByDateReportPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/MarketplaceProfitLossByDateReportPage'
//     ),
//   module => module.MarketplaceProfitLossByDateReportPage,
// );

// const AuthorProfilePage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Merketplace/AuthorProfilePage'
//     ),
//   module => module.AuthorProfilePage,
// );

// const BotsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotsPage'),
//   module => module.BotsPage,
// );
// const BotDetailsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotDetailsPage'),
//   module => module.BotDetailsPage,
// );
// const BotDetailSymbolsPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotDetailSymbolsPage'),
//   module => module.BotDetailSymbolsPage,
// );
// const BotPositionsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotPositionsPage'),
//   module => module.BotPositionsPage,
// );
// const BotOrdersPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotOrdersPage'),
//   module => module.BotOrdersPage,
// );
// const BotHistoryPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotHistoryPage'),
//   module => module.BotHistoryPage,
// );
// const BotHistoryDetailPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotHistoryDetailPage'),
//   module => module.BotHistoryDetailPage,
// );
// const BotHistoryPositionsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotHistoryPositionsPage'
//     ),
//   module => module.BotHistoryPositionsPage,
// );
// const BotHistoryOrdersPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotHistoryOrdersPage'),
//   module => module.BotHistoryOrdersPage,
// );
// const BotHistorySymbolsPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotHistorySymbolsPage'),
//   module => module.BotHistorySymbolsPage,
// );

// const BotHistoryProfitLossByDateReportPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotHistoryProfitLossByDateReportPage'
//     ),
//   module => module.BotHistoryProfitLossByDateReportPage,
// );
// const UserBotBacktestingPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/UserBotBacktestingPage'),
//   module => module.UserBotBacktestingPage,
// );
// const UserBotStrategyDetailPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/UserBotStrategyDetailPage'
//     ),
//   module => module.UserBotStrategyDetailPage,
// );
// const SellOnMarketPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/SellOnMarketPage'),
//   module => module.SellOnMarketPage,
// );
// const SellOnMarketAddPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/SellOnMarketAddPage'),
//   module => module.SellOnMarketAddPage,
// );
// const SellOnMarketUpdatePage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/SellOnMarketUpdatePage'),
//   module => module.SellOnMarketUpdatePage,
// );
// const SellOnMarketBillingDetailsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/SellOnMarketBillingDetailsPage'
//     ),
//   module => module.SellOnMarketBillingDetailsPage,
// );

// const MenuPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/MenuPage'),
//   module => module.MenuPage,
// );
// const MenuNotificationsPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Menu/MenuNotificationsPage'),
//   module => module.MenuNotificationsPage,
// );
// const ProfilePage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/ProfilePage'),
//   module => module.ProfilePage,
// );
// const ProfileEditPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/ProfileEditPage'),
//   module => module.ProfileEditPage,
// );
// const ChangePasswordPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/ChangePasswordPage'),
//   module => module.ChangePasswordPage,
// );
// const BrokersPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/BrokersPage'),
//   module => module.BrokersPage,
// );
// const AddBrokerPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/AddBrokerPage'),
//   module => module.AddBrokerPage,
// );
// const EditBrokerPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/EditBrokerPage'),
//   module => module.EditBrokerPage,
// );
// const BrokerAuthPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/BrokerAuthPage'),
//   module => module.BrokerAuthPage,
// );
// const PlanUpgradePage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/PlanUpgradePage'),
//   module => module.PlanUpgradePage,
// );
// const PlanChangePage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/PlanChangePage'),
//   module => module.PlanChangePage,
// );
// const PlanDetailsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/PlanDetailsPage'),
//   module => module.PlanDetailsPage,
// );
// const BillingDetailsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/BillingDetailsPage'),
//   module => module.BillingDetailsPage,
// );
// const ReportsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Menu/ReportsPage'),
//   module => module.ReportsPage,
// );
// const PortfolioProfitLossByDateReportPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Portfolio/PortfolioProfitLossByDateReportPage'
//     ),
//   module => module.PortfolioProfitLossByDateReportPage,
// );

// const BotBackTestingPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotBackTestingPage'),
//   module => module.BotBackTestingPage,
// );
// const BotTradeStatsPage = lazyLoad(
//   () => import(/* webpackPrefetch: true */ 'app/pages/Bots/BotTradeStatsPage'),
//   module => module.BotTradeStatsPage,
// );
// const BotAdvanceStatsPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotAdvanceStatsPage'),
//   module => module.BotAdvanceStatsPage,
// );
// const BotBackTestingPositionsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotBackTestingPositionsPage'
//     ),
//   module => module.BotBackTestingPositionsPage,
// );
// const BotBackTestingOrdersPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotBackTestingOrdersPage'
//     ),
//   module => module.BotBackTestingOrdersPage,
// );
// const BotBackTestingSymbolsPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotBackTestingSymbolsPage'
//     ),
//   module => module.BotBackTestingSymbolsPage,
// );
// const BotBackTestingProfitLossByDateReportPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Bots/BotBackTestingProfitLossByDateReportPage'
//     ),
//   module => module.BotBackTestingProfitLossByDateReportPage,
// );

// const BotStrategyBuilderPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/BotStrategyBuilderPage'),
//   module => module.BotStrategyBuilderPage,
// );

// const StrategyBuilderPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/StrategyBuilder/StrategyBuilderPage'
//     ),
//   module => module.StrategyBuilderPage,
// );

// const UserBotTradingviewPage = lazyLoad(
//   () =>
//     import(/* webpackPrefetch: true */ 'app/pages/Bots/UserBotTradingviewPage'),
//   module => module.UserBotTradingviewPage,
// );

// const OptionsBuilderPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/OptionsStrategyBuilder/OptionsBuilderPage'
//     ),
//   module => module.OptionsBuilderPage,
// );

// const NotificationControllerPage = lazyLoad(
//   () =>
//     import(
//       /* webpackPrefetch: true */ 'app/pages/Notification/NotificationControllerPage'
//     ),
//   module => module.NotificationControllerPage,
// );

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();
  return (
    <Route
      path={[
        '/portfolio',
        '/portfolio/:id',
        '/discover',
        '/discover/bots',
        '/discover/bots/search/:keyword?',
        '/discover/bots/category/:id/:title/:subtitle',
        '/discover/bot/:id',
        '/discover/user-details/:id',
        '/discover/:id/positions/:title',
        '/discover/:id/orders/:title',
        '/discover/:id/symbols/:title',
        '/discover/:id/profitloss/:title',
        '/discover/author/:id',
        '/discover/bots/compare/:id1/:id2',
        // For Temp
        '/marketplace',
        '/marketplace/bots',
        '/marketplace/bots/search/:keyword?',
        '/marketplace/bots/category/:id/:title/:subtitle',
        '/marketplace/bot/:id',
        '/marketplace/user-details/:id',
        '/marketplace/:id/positions/:title',
        '/marketplace/:id/orders/:title',
        '/marketplace/:id/symbols/:title',
        '/marketplace/:id/profitloss/:title',
        '/marketplace/author/:id',
        // For Temp
        '/bots/:ptype?',
        '/bots/:bid/details/:sid/:title?',
        '/bots/:bid/:sid/symbols/:title',
        '/bots/:id/positions/:title',
        '/bots/:id/orders/:title',
        '/portfolio/:ptype/:id/signals/:title/:bid',
        '/portfolio/:ptype/:id/symbols',
        '/portfolio/:ptype/:id/symbols/:title',
        '/portfolio/:ptype/:id/statistics/:title',
        '/portfolio/:ptype/:id/statistics/positions/:title',
        '/portfolio/:ptype/:id/statistics/orders/:title',
        '/portfolio/:ptype/:id/statistics/symbols/:title',
        '/portfolio/:ptype/:id/statistics/profitloss/:title',
        '/bots/:bid/history/:sid/:title',
        '/bots/:bid/history/:sid/:title/:hsid',
        '/bots/:bid/history/:sid/positions/:title/:subtitle',
        '/bots/:bid/history/:sid/orders/:title/:subtitle',
        '/bots/:bid/history/:sid/symbols/:hsid/:title',
        '/bots/:bid/history/:sid/profitloss/:hsid/:title/:subtitle',
        '/bots/:bid/signals/:sid/:title',
        '/bots/:bid/signals/:sid/todays-positions/:title',
        '/bots/:bid/signals/:sid/todays-orders/:title',
        '/bots/:bid/backtest/:sid/:title',
        '/bots/:id/backtest/:sid/tradestats/:title',
        '/bots/:id/backtest/:sid/advancestats/:title',
        '/bots/:bid/backtest/:sid/positions/:title',
        '/bots/:bid/backtest/:sid/orders/:title',
        '/bots/:bid/backtest/:sid/symbols/:title',
        '/bots/:bid/backtest/:sid/profitloss/:title',
        '/bot/:id/backtesting',
        '/bot/:id/strategy/:sid?',
        '/bots/:type/logs/:botId',
        '/bots/:id/sell-on-discover/:title',
        '/bots/:id/sell-on-discover/:title/new',
        '/bots/:id/sell-on-discover/:title/edit',
        '/bots/:id/sell-on-discover/:title/billing-details',
        '/plan-upgrade',
        '/plan-upgrade-confirm',
        '/plan-change',
        '/plan-change-confirm',
        '/menu',
        '/menu/notifications',
        '/menu/help',
        '/menu/help/ticketdetail/:id',
        '/profile',
        '/profile/edit/name/:name?',
        '/profile/edit/email/:email?',
        '/profile/edit/gstin/:gstin?',

        '/profile/edit/mobile/:ccode/:mobile?',
        '/referral/:referral?',
        '/change-password',
        '/broker',
        '/brokers/new',
        '/broker/:id',
        '/broker/auth/:uuid',
        '/menu/plan-details/:action?',
        '/menu/billing-details',
        '/reports',
        '/reports/profit-loss-by-date',
        '/c3RyYXRlZ3ktYnVpbGRlcg',
        '/bot/:bid/:id/tradingview/:sid?/:title?',
        '/c3RyYXRlZ3ktYnVpbGRlcd',
        '/notification-controller',
        '/traders',
        '/traders/search',
        '/traders/category/:id/:title/:subtitle',
        '/traders/details/:id/:title',
        '/trader/:id/positions/:title',
        '/trader/:id/orders/:title',
        '/trader/:id/symbols/:title',
        '/trader/:id/profitloss/:title',
        '/report',
        '/report/search',
        // For Option Chain
        '/option-chain',
        '/trade-options',
        '/trade-options/trade-table',
        //WebHook Signals
        '/signal',
        '/signal/details/:bid',
      ]}
    >
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <>
              {/* <QueryCacheInvalidateOnSocketNotification /> */}
              <ToggleContextProvider>
                <Route
                  exact
                  path="/portfolio/:ptypeOld?"
                  component={PortfolioPage}
                />
                {/* For Temp */}
                <Route exact path="/bots/:ptype?" component={PortfolioPage} />
                <Route
                  exact
                  path="/bots/:bid/details/:sid/:title?"
                  component={BotDetailsPage}
                />
              </ToggleContextProvider>
              <Route
                exact
                path="/portfolio/:ptype/:id"
                component={PortfolioDetailPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/positions/:title/:bid"
                component={PortfolioPositionsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/orders/:title/:bid"
                component={PortfolioOrdersPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/signals/:title/:bid"
                component={PortfolioSignalsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/symbols/:title"
                component={PortfolioSymbolsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/statistics/:title"
                component={StatisticsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/statistics/positions/:title"
                component={PortfolioStatisticsPositionsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/statistics/orders/:title"
                component={PortfolioStatisticsOrdersPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/statistics/symbols/:title"
                component={PortfolioStatisticsSymbolsPage}
              />
              <Route
                exact
                path="/portfolio/:ptype/:id/statistics/profitloss/:title"
                component={PortfolioProfitLossByDateReportPage}
              />
              <Route exact path="/discover" component={MarketplacePage} />
              <Route exact path="/discover/bots" component={MarketplacePage} />
              <Route
                exact
                path="/discover/bots/search/:keyword?"
                component={MarketplaceBotSearchPage}
              />
              <Route
                exact
                path="/discover/bots/category/:id/:title/:subtitle"
                component={MarketplaceCategoryPage}
              />
              <Route
                exact
                path="/discover/bot/:id/:title"
                component={MarketplaceBotDetailPage}
              />
              <Route
                exact
                path="/discover/author/:id"
                component={AuthorProfilePage}
              />
              <Route
                exact
                path="/discover/:id/positions/:title"
                component={MarketplacePositionsPage}
              />
              <Route
                exact
                path="/discover/:id/orders/:title"
                component={MarketplaceOrdersPage}
              />
              <Route
                exact
                path="/discover/:id/symbols/:title"
                component={MarketplaceSymbolsPage}
              />
              <Route
                exact
                path="/discover/:id/profitloss/:title"
                component={MarketplaceProfitLossByDateReportPage}
              />
              <Route
                exact
                path="/discover/bots/compare/:id1/:id2"
                component={MarketplaceBotsComparePage}
              />

              {/* For Temp */}
              <Route exact path="/marketplace" component={MarketplacePage} />
              <Route
                exact
                path="/marketplace/bots"
                component={MarketplacePage}
              />
              <Route
                exact
                path="/marketplace/bots/search/:keyword?"
                component={MarketplaceBotSearchPage}
              />
              <Route
                exact
                path="/marketplace/bots/category/:id/:title/:subtitle"
                component={MarketplaceCategoryPage}
              />
              <Route
                exact
                path="/marketplace/bot/:id/:title"
                component={MarketplaceBotDetailPage}
              />
              <Route
                exact
                path="/marketplace/author/:id"
                component={AuthorProfilePage}
              />
              <Route
                exact
                path="/marketplace/:id/positions/:title"
                component={MarketplacePositionsPage}
              />
              <Route
                exact
                path="/marketplace/:id/orders/:title"
                component={MarketplaceOrdersPage}
              />
              <Route
                exact
                path="/marketplace/:id/symbols/:title"
                component={MarketplaceSymbolsPage}
              />
              <Route
                exact
                path="/marketplace/:id/profitloss/:title"
                component={MarketplaceProfitLossByDateReportPage}
              />

              <Route
                exact
                path="/bots/:bid/:sid/symbols/:title"
                component={BotDetailSymbolsPage}
              />
              <Route
                exact
                path="/bots/:id/positions/:title"
                component={BotPositionsPage}
              />
              <Route
                exact
                path="/bots/:type/logs/:botId"
                component={CodeStrategyLogsPage}
              />
              <Route
                exact
                path="/bots/:id/orders/:title"
                component={BotOrdersPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/:title"
                component={BotHistoryPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/:hsid/:title"
                component={BotHistoryDetailPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/positions/:hsid/:title/:subtitle"
                component={BotHistoryPositionsPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/orders/:hsid/:title/:subtitle"
                component={BotHistoryOrdersPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/symbols/:hsid/:title/:subtitle"
                component={BotHistorySymbolsPage}
              />
              <Route
                exact
                path="/bots/:bid/history/:sid/profitloss/:hsid/:title/:subtitle"
                component={BotHistoryProfitLossByDateReportPage}
              />

              <Route exact path="/plan-upgrade" component={PlanUpgradePage} />
              <Route exact path="/plan-change" component={PlanChangePage} />
              <Route exact path="/menu" component={MenuPage} />
              <Route
                exact
                path="/menu/notifications"
                component={MenuNotificationsPage}
              />
              <Route exact path="/menu/wallet" component={MenuWalletPage} />
              <Route exact path="/menu/help" component={MenuHelpPage} />
              <Route
                exact
                path={'/menu/help/ticketdetail/:id'}
                component={TicketDetailPage}
              />

              <Route exact path="/profile" component={ProfilePage} />
              <Route
                exact
                path="/profile/edit/name/:name?"
                component={ProfileEditPage}
              />
              <Route
                exact
                path="/profile/edit/email/:email?"
                component={ProfileEditPage}
              />
              <Route
                exact
                path="/profile/edit/gstin/:gstin?"
                component={ProfileEditPage}
              />
              <Route
                exact
                path="/profile/edit/mobile/:ccode/:mobile?"
                component={ProfileEditPage}
              />
              <Route
                exact
                path="/referral/:referral?"
                component={ReferralPage}
              />

              <Route
                exact
                path="/change-password"
                component={ChangePasswordPage}
              />
              <Route exact path="/broker" component={BrokersPage} />
              <Route exact path="/brokers/new" component={AddBrokerPage} />
              <Route exact path="/broker/:id" component={EditBrokerPage} />
              <Route
                exact
                path="/broker/auth/:uuid"
                component={BrokerAuthPage}
              />
              <Route
                exact
                path="/menu/plan-details/:action?"
                component={PlanDetailsPage}
              />
              <Route
                exact
                path="/menu/billing-details"
                component={BillingDetailsPage}
              />
              <Route exact path="/reports" component={ReportsPage} />

              <Route
                exact
                path="/bots/:bid/signals/:sid/:title"
                component={BotSignalsPage}
              />
              <Route
                exact
                path="/bots/:bid/signals/:sid/todays-positions/:title"
                component={BotSignalsTodaysPositioPage}
              />
              <Route
                exact
                path="/bots/:bid/signals/:sid/positions/:title"
                component={BotSignalsPositionsPage}
              />
              <Route
                exact
                path="/bots/:bid/signals/:sid/orders/:title"
                component={BotSignalsOrdersPage}
              />
              <Route
                exact
                path="/bots/:bid/signals/:sid/todays-orders/:title"
                component={BotSignalsTodaysOrderPage}
              />
              <Route
                exact
                path="/bots/:bid/backtest/:sid/:title"
                component={BotBackTestingPage}
              />
              <Route
                exact
                path="/bots/:id/backtest/:sid/tradestats/:title"
                component={BotTradeStatsPage}
              />
              <Route
                exact
                path="/bots/:id/backtest/:sid/advancestats/:title"
                component={BotAdvanceStatsPage}
              />
              <Route
                exact
                path="/bots/:bid/backtest/:sid/positions/:title"
                component={BotBackTestingPositionsPage}
              />
              <Route
                exact
                path="/bots/:bid/backtest/:sid/orders/:title"
                component={BotBackTestingOrdersPage}
              />
              <Route
                exact
                path="/bots/:bid/backtest/:sid/symbols/:title"
                component={BotBackTestingSymbolsPage}
              />
              <Route
                exact
                path="/bots/:bid/backtest/:sid/profitloss/:title"
                component={BotBackTestingProfitLossByDateReportPage}
              />
              <Route
                exact
                path="/bot/:id/backtesting/:sid?"
                component={UserBotBacktestingPage}
              />
              <Route
                exact
                path="/bot/:id/strategy/:sid?"
                component={UserBotStrategyDetailPage}
              />
              <Route
                exact
                path="/bots/:id/sell-on-discover/:title"
                component={SellOnMarketPage}
              />
              <Route
                exact
                path="/bots/:id/sell-on-discover/:title/new"
                component={SellOnMarketAddPage}
              />
              <Route
                exact
                path="/bots/:id/sell-on-discover/:title/edit"
                component={SellOnMarketUpdatePage}
              />
              <Route
                exact
                path="/bots/:id/sell-on-discover/:title/billing-details"
                component={SellOnMarketBillingDetailsPage}
              />
              <Route
                exact
                path="/c3RyYXRlZ3ktYnVpbGRlcg"
                component={StrategyBuilderPage}
              />

              <Route
                exact
                path="/bot/:bid/:id/tradingview/:sid?/:title?"
                component={UserBotTradingviewPage}
              />
              <Route
                exact
                path="/c3RyYXRlZ3ktYnVpbGRlcd"
                component={OptionsBuilderPage}
              />
              <Route
                exact
                path="/notification-controller"
                component={NotificationControllerPage}
              />

              <Route exact path={'/traders'} component={TradersPage} />
              <Route
                exact
                path={'/traders/search'}
                component={TradersSearchPage}
              />
              <Route
                exact
                path={'/traders/category/:id/:title/:subtitle'}
                component={TradersCategoryPage}
              />
              <Route
                exact
                path="/traders/details/:id/:title"
                component={TradersDetailsPage}
              />
              <Route
                exact
                path="/trader/:id/positions/:title"
                component={MarketplacePositionsPage}
              />
              <Route
                exact
                path="/trader/:id/orders/:title"
                component={MarketplaceOrdersPage}
              />
              <Route
                exact
                path="/trader/:id/symbols/:title"
                component={MarketplaceSymbolsPage}
              />
              <Route
                exact
                path="/trader/:id/profitloss/:title"
                component={MarketplaceProfitLossByDateReportPage}
              />
              <Route exact path="/report" component={HistoryReportsPage} />
              <Route path="/report/search" component={ReportsSearchPage} />
              <Route exact path="/option-chain" component={Simulator} />
              <Route exact path="/trade-options" component={TradeOptions} />
              <Route
                exact
                path="/trade-options/trade-table"
                component={TradeIdeas}
              />
              <Route exact path={'/signal'} component={WebHookSignals} />
              <Route
                exact
                path={'/signal/details/:bid'}
                component={WebHookSignalDetails}
              />
            </>
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
