import { BreadcrumbData, Generator, GeneratorPages, PAGES } from '../types';
import {
  botBacktestForm,
  botBacktestHistorySymbols,
  botBacktestOrdersPositionsSymbols,
  botDetail,
  botHistoryDetails,
  botHistorySymbolsOrderPositions,
  botStrategyBuilder,
} from './botsBackPaths';
import {
  marketplaceDetail,
  marketplaceDetailOrderPositions,
} from './marketplaceBackPaths';
import {
  billingDetails,
  brokerAndProfile,
  brokerDetail,
  profileEdit,
  ticketDetails,
  tradeIdeas,
} from './menuBackPaths';
import {
  portfolioDetails,
  portfolioDetailOrderPositions,
  portfolioDetailStatisticOrderPositions,
} from './portfolioBackPaths';
import { categoryAndSearch } from './templatesBackPaths';
import {
  traderHistoryOrdersPositions,
  tradersDetails,
} from './tradersBackPaths';
import { webhookSignals } from './webhookSignalsBackpath';

export const PageToDefaultBackPathGenerator = {
  // Bot pages
  [PAGES.BOT_DETAIL]: botDetail,
  [PAGES.BOT_DETAIL_SYMBOLS]: botBacktestHistorySymbols,
  [PAGES.BOT_HISTORY]: botBacktestHistorySymbols,
  [PAGES.BOT_HISTORY_DETAIL]: botHistoryDetails,
  [PAGES.BOT_HISTORY_SYMBOLS]: botHistorySymbolsOrderPositions,
  [PAGES.BOT_HISTORY_ORDERS]: botHistorySymbolsOrderPositions,
  [PAGES.BOT_HISTORY_POSITIONS]: botHistorySymbolsOrderPositions,
  [PAGES.BOT_HISTORY_PROFITLOSS]: botHistorySymbolsOrderPositions,
  [PAGES.BOT_BACK_TESTING]: botBacktestHistorySymbols,
  [PAGES.BOT_SIGNALS]: botBacktestHistorySymbols,
  [PAGES.BOT_SIGNALS_TODAYS_POSITIONS]: botBacktestHistorySymbols,
  [PAGES.BOT_SIGNALS_TODAYS_ORDERS]: botBacktestHistorySymbols,
  [PAGES.BOT_SIGNALS_POSITIONS]: botBacktestHistorySymbols, //botSignalsPositions,
  [PAGES.BOT_SIGNALS_ORDERS]: botBacktestHistorySymbols, //botSignalsOrders,
  [PAGES.BOT_BACK_TESTING_POSITIONS]: botBacktestOrdersPositionsSymbols,
  [PAGES.BOT_BACK_TESTING_ORDERS]: botBacktestOrdersPositionsSymbols,
  [PAGES.BOT_BACK_TESTING_SYMBOLS]: botBacktestOrdersPositionsSymbols,
  [PAGES.BOT_BACK_TESTING_PROFITLOSS]: botBacktestOrdersPositionsSymbols,
  [PAGES.BOT_STRATEGY_BUILDER]: botStrategyBuilder,
  [PAGES.BOT_STRATEGY_DETAIL]: botBacktestHistorySymbols,
  [PAGES.BOT_BACK_TESTING_FORM]: botBacktestForm,
  [PAGES.BOT_TREE_VIEW]: botBacktestOrdersPositionsSymbols,

  // Portfolio pages
  [PAGES.PORTFOLIO_DETAIL]: portfolioDetails,
  [PAGES.PORTFOLIO_DETAIL_POSITIONS]: portfolioDetailOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_ORDERS]: portfolioDetailOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_SYMBOLS]: portfolioDetailOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_STATISTIC_POSITIONS]:
    portfolioDetailStatisticOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_STATISTIC_ORDERS]:
    portfolioDetailStatisticOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_STATISTIC_SYMBOLS]:
    portfolioDetailStatisticOrderPositions,
  [PAGES.PORTFOLIO_DETAIL_STATISTIC_PROFITLOSS]:
    portfolioDetailStatisticOrderPositions,

  // Marketplace pages
  [PAGES.MARKETPLACE_DETAIL]: marketplaceDetail,
  [PAGES.MARKETPLACE_CATEGORY]: marketplaceDetail,
  [PAGES.MARKETPLACE_SEARCH]: marketplaceDetail,
  [PAGES.MARKETPLACE_ORDERS]: marketplaceDetailOrderPositions,
  [PAGES.MARKETPLACE_POSITIONS]: marketplaceDetailOrderPositions,
  [PAGES.MARKETPLACE_SYMBOLS]: marketplaceDetailOrderPositions,
  [PAGES.MARKETPLACE_PROFITLOSS]: marketplaceDetailOrderPositions,

  // Menu pages
  [PAGES.BROKER]: brokerAndProfile,
  [PAGES.PROFILE_NOTIFICATION]: brokerAndProfile,
  [PAGES.ADD_BROKER]: brokerDetail,
  [PAGES.EDIT_BROKER]: brokerDetail,
  [PAGES.PROFILE]: brokerAndProfile,
  [PAGES.PROFILE_EDIT]: profileEdit,
  [PAGES.CHANGE_PASSWORD]: profileEdit,
  [PAGES.PLAN_DETAIL]: brokerAndProfile,
  [PAGES.BILLING_DETAILS]: billingDetails,
  [PAGES.WALLET]: brokerAndProfile,

  // Templates pages
  [PAGES.TEMPLATES_DETAIL]: categoryAndSearch,
  [PAGES.TEMPLATES_SEARCH]: categoryAndSearch,
  [PAGES.TEMPLATES_CATEGORY]: categoryAndSearch,

  // Traders pages
  [PAGES.TRADERS_CATEGORY]: tradersDetails,
  [PAGES.TRADERS_DETAIL]: tradersDetails,
  [PAGES.TRADERS_ORDERS]: traderHistoryOrdersPositions,
  [PAGES.TRADERS_POSITIONS]: traderHistoryOrdersPositions,
  [PAGES.TRADERS_SYMBOLS]: traderHistoryOrdersPositions,
  [PAGES.TRADERS_PROFITLOSS]: traderHistoryOrdersPositions,
  [PAGES.REFER_EARN]: brokerAndProfile,
  [PAGES.HELP]: brokerAndProfile,
  [PAGES.TICKET_DETAILS]: ticketDetails,
  [PAGES.TRADE_OPTIONS]: tradeIdeas,
  [PAGES.SIGNALS]: webhookSignals,
} as Record<GeneratorPages, Generator>;

export const getDefaultBackPathForPage = (
  page: GeneratorPages,
  data: BreadcrumbData,
) => {
  return PageToDefaultBackPathGenerator[page](data);
};
