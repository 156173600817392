import {
  OptionSetParam,
  OptionLegParam,
  OptionStrategyParam,
  FieldParam,
  FieldParamValue,
  OptionDayWiseParam,
  DAY_WISE_TARGETSTOPLOSS,
  DAY_WISE_STOPLOSS,
} from '../types';
import { SbStrategy } from 'app/components/Bots/StrategyBuilder/types';
import { cloneDeep } from 'lodash';
import {
  createContractSelectionOptions,
  calculateOptionSetParentKey,
  calculateOptionLegParentKey,
  calculateOptionStopLossFieldsParentKey,
  calculateOptionTargetProfitFieldsParentKey,
  calculateOptionTrailingStopLossFieldsParentKey,
  OptionLegFields,
  SYMBOL_RANGES,
  createDaysToExpireSelectionOptions,
  createRRTypeSelectionOptions,
  createContractExpityTypeSelection,
  checkFeatureFlagForContractType,
  createTradingSymbolSelectionOptions,
  OptionFutureLegFields,
  checkDeltaStrikeEnabled,
  createButtonGroupOptions,
  createMomentumTypeSelectionOptions,
} from '../FieldsData';
import {
  OptionSetIronCondorValueType,
  OptionSetIronFlyValueType,
  OptionSetSpreadValueType,
  OptionSetStraddleValueType,
  OptionSetStrangleValueType,
} from '../OptionSetTemplates/types';

export function createEmptyOptionSet(
  type: string,
  OptionSetFieldsGroupWise: FieldParam[],
  totalSets: number,
): OptionSetParam {
  let emptyOptionSet: OptionSetParam = {
    name: `S${totalSets + 1}`,
    set_security_type: type,
    parent_symbol: { symbol: '', market: '' },
    parent_entry_time: { entry_time: '' },
    parent_exit_time: { exit_time: '' },
    parent_max_allowed_entry_time: { max_allowed_entry_time: '' },

    // parent_mtm_sl_unit: { mtm_sl_unit: 'points' },
    // parent_mtm_target_unit: { mtm_target_unit: 'points' },
    // parent_mtm_sl_value: { mtm_sl_value: 0 },
    // parent_mtm_target_value: { mtm_target_value: 0 },
    parent_move_to_cost: { move_to_cost: false },

    parent_enter_if_missed: { entry_if_missed: false },
    legs: [],
    parent_holding_type: { holding_type: '' },
    parent_days_to_run: { days_to_run: [] },
    parent_is_stbt_btst: { is_stbt_btst: false },
    parent_is_momentum_trade: { is_momentum_trade: false },
    isFutureLegEnabled: 'false',
    parent_square_off_all_legs: { square_off_all_legs: 'false' },
    indicator_extras: {
      entry_conditions: [],
      exit_conditions: [],
    },
    external_signal: {
      enable: false,
    },
  };

  OptionSetFieldsGroupWise.forEach(field => {
    if (field.type === 'selectwithinput') {
      emptyOptionSet = {
        ...emptyOptionSet,
        [field.parentKey]: { toggleStatus: true },
      };
    }
    field.controls.forEach(control => {
      emptyOptionSet = {
        ...emptyOptionSet,
        [field.parentKey]: {
          ...(emptyOptionSet[field.parentKey] as Record<
            string,
            FieldParamValue
          >),
          [control.key]: control.defaultValue,
        },
      };
    });
  });
  return emptyOptionSet;
}

export function createEmptyOptionLeg(
  OptionLegFields: FieldParam[],
  totalLegs: number,
  legEnabled?: string,
): OptionLegParam {
  let emptyOptionLeg: OptionLegParam = {
    name: `Leg${totalLegs + 1}`,
    parent_type: { type: 'option' },
    parent_right: { right: 'call' },
    parent_contract_selection_type: { contract_selection_type: 'price' },
    parent_contract_selection_value: { contract_selection_value: '' },
    parent_direction: { direction: 'long' },
    parent_lots: { lots: 0 },
    class_name: 'SbExecutor.Executor.SBOptionLeg',
    hedge_legs: [],
    isHedge: true,
    legEnabled: 'optionleg',
    contract_selection_strike_stap_size: '0',
    entry_execution_settings: {
      order_type: 'market',
      buffer_type: 'points',
      limit_buffer: 0,
      trigger_buffer: 0,
      convert_to_market_after: 0,
    },
    order_product_type: 'mis',
    // order_product_type: 'mis',

    exit_execution_settings: {
      order_type: 'market',
      buffer_type: 'points',
      limit_buffer: 0,
      trigger_buffer: 0,
      convert_to_market_after: 0,
    },
    indicator_extras: {
      entry_on_underlying: false,
      exit_on_underlying: false,
      entry_conditions: [],
      exit_conditions: [],
    },
  };
  legEnabled == 'futureleg' || legEnabled == 'equityleg'
    ? OptionFutureLegFields
    : OptionLegFields.forEach(field => {
        if (field.type === 'selectwithinput') {
          emptyOptionLeg = {
            ...emptyOptionLeg,
            [field.parentKey]: { toggleStatus: true },
          };
        }
        field.controls.forEach(control => {
          emptyOptionLeg = {
            ...emptyOptionLeg,
            [field.parentKey]: {
              ...(emptyOptionLeg[field.parentKey] as Record<
                string,
                FieldParamValue
              >),
              [control.key]: control.defaultValue,
            },
          };
        });
      });
  return emptyOptionLeg;
}

export function createEmptyBuilder(
  OptionStrategyFields: FieldParam[],
): OptionStrategyParam {
  let emptyBuilder = {
    parent_start_date: { start_date: '' },
    parent_end_date: { end_date: '' },
    parent_initial_capital: { initial_capital: '' },
    strategy_sets: [],
    day_wise_sl: [],
    day_wise_tg: [],
    day_wise_tsl: [],
  };
  OptionStrategyFields.forEach(field => {
    if (field.type === 'selectwithinput') {
      emptyBuilder = {
        ...emptyBuilder,
        [field.parentKey]: { toggleStatus: true },
      };
    }
    field.controls.forEach(control => {
      emptyBuilder = {
        ...emptyBuilder,
        [field.parentKey]: {
          ...(emptyBuilder[field.parentKey] as Record<string, FieldParamValue>),
          [control.key]: control.defaultValue,
        },
      };
    });
  });
  return emptyBuilder;
}

export function createEmptyOptionBuilder(
  OptionStrategyFields: FieldParam[],
): SbStrategy {
  const entryExit = {
    condition_key: '',
    conditions: [],
  };
  const sb_opt_strategy = createEmptyBuilder(OptionStrategyFields);
  const emptyData: SbStrategy = {
    chart: '',
    direction: '',
    // built_with: undefined,
    long_entry: entryExit,
    short_entry: entryExit,
    long_exit: entryExit,
    short_exit: entryExit,
    risk_type: '',
    order_sizing: {
      type: '',
      value: 0,
    },
    risk_percentage: 0,
    order_type: '',
    order_valid_till: '',
    square_off_time: '',
    target_per: 0,
    stop_loss_per: 0,
    trailing_sl_enabled: false,
    trailling_sl: 0,
    trading_start_time: '',
    trading_stop_time: '',
    //Below params are needed for the option builder
    holding_type: '',
    universe: [],
    resolution: '00:01:00',
    start_date: (
      sb_opt_strategy.parent_start_date as Record<string, FieldParamValue>
    ).start_date as string,
    end_date: (
      sb_opt_strategy.parent_end_date as Record<string, FieldParamValue>
    ).end_date as string,
    initial_capital: (
      sb_opt_strategy.parent_initial_capital as Record<string, FieldParamValue>
    ).initial_capital as string,
    override_leverage: 5000,
    sb_opt_strategy,
    type: 'option',
    per_order_fees: 0,
    slippage: 0.1,
    // day_wise_sl: [],
    // day_wise_tg: [],
    // day_wise_tsl: [],
  };
  return emptyData;
}

export function generateOptionValues(
  field: FieldParam,
  optionLeg: OptionLegParam,
  optionSet: OptionSetParam,
  optionDayWise: OptionDayWiseParam,
  enabledFeatures: {
    reEntryEnabled?: boolean;
    DTEenabled?: boolean;
    crudeOilSymbol?: boolean;
    stockSymbols?: boolean;
    bankex?: boolean;
    QQQ?: boolean;
    SPY?: boolean;
    SPX?: boolean;
    XSP?: boolean;
    deltaEnabled?: boolean;
    usaStockSymbols?: boolean;
    strikeDeltaEnabled?: boolean;
    continuousSet?: boolean;
    symbolFromBackend?: boolean;
    isEquityLeg?: boolean;
    botBuilderType?: string;
  },
) {
  // in this function we are also modify the optionleg and optionset but not sending in return
  const retData = cloneDeep(field);

  field.controls.forEach((item, itemIndex) => {
    if (item.key === 'momentum_type' || item.key === 'momentum_value') {
      // if (
      //   Object.prototype.hasOwnProperty.call(
      //     optionLeg.parent_momentum,
      //     'momentum_type',
      //   ) &&
      //   // optionLeg.parent_momentum_type &&
      //   !optionLeg.parent_momentum['momentum_type']
      // )
      if (
        optionLeg.parent_momentum &&
        optionLeg.parent_momentum['toggleStatus']
      ) {
        optionLeg.parent_momentum['momentum_type'] = 'none';
      } else {
        if (optionLeg.parent_momentum['momentum_type'] === 'none') {
          optionLeg.parent_momentum['momentum_type'] = 'points';
          optionLeg.parent_momentum['momentum_value'] = 0;
        }
      }
      retData.controls[itemIndex].values = createMomentumTypeSelectionOptions(
        enabledFeatures.botBuilderType,
      );
    }

    if (
      (item.key === 'trailing_sl_type' ||
        item.key === 'trailing_sl_trigger_value' ||
        item.key === 'trailing_sl_value') &&
      Object.keys(optionLeg).length
    ) {
      if (!optionLeg.parent_stop_loss['toggleStatus']) {
        retData['hide'] = false;
        const hidecondition = new Set([
          'delta_points_up',
          'delta_points_down',
          'delta_percentage_up',
          'delta_percentage_down',
        ]);
        if (
          hidecondition.has(optionLeg.parent_stop_loss['sl_unit'] as string)
        ) {
          retData['hide'] = true;
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(
          optionLeg.parent_trail_stop_loss,
          'trail_sl',
        ) &&
        !optionLeg.parent_trail_stop_loss['trail_sl']
      ) {
        optionLeg.parent_trail_stop_loss['trailing_sl_type'] = 'none';
      } else {
        if (optionLeg.parent_trail_stop_loss['trailing_sl_type'] === 'none') {
          optionLeg.parent_trail_stop_loss['trailing_sl_type'] = 'points';
        }
      }
    } else if (
      item.key === 'reexecution_type' ||
      item.key === 'reexecution_delay_seconds'
    ) {
      if (optionSet.parent_reexecution['reexecution_type'] === 'delay') {
        retData.controls
          .filter(x => x.key === 'reexecution_delay_seconds')
          .map(x => (x.enabled = true));
      } else {
        retData.controls.filter(x => x.key !== 'reexecution_delay_seconds');
      }
    } else if (item.key == 'reentry_type') {
      if (
        optionLeg.parent_rr_type['reentry_type'] === 'delay' ||
        optionLeg.parent_rr_type['reentry_type'] === 'delay_reverse'
      ) {
        retData.controls
          .filter(x => x.key === 'delay_seconds')
          .map(x => (x.enabled = true));
      } else {
        retData.controls.filter(x => x.key !== 'delay_seconds');
      }
    } else if (
      [
        'breakout_bar_starttime',
        'breakout_bar_endtime',
        'breakout_bar_field',
      ].includes(item.key) &&
      Object.keys(optionLeg).length
    ) {
      if (
        Object.prototype.hasOwnProperty.call(
          optionLeg.parent_range_breakout,
          'range_breakout',
        ) &&
        !optionLeg.parent_range_breakout['range_breakout']
      ) {
        optionLeg.parent_range_breakout['breakout_bar_field'] = 'none';
      } else {
        if (optionLeg.parent_range_breakout['breakout_bar_field'] === 'none') {
          optionLeg.parent_range_breakout['breakout_bar_field'] = 'high';
        }
      }
    }
    if (item.key === 'target_unit' || item.key === 'sl_unit') {
      retData.controls[itemIndex].values = checkDeltaStrikeEnabled(
        enabledFeatures.strikeDeltaEnabled,
      );
    }

    if (item.key === 'contract_selection_type')
      retData.controls[itemIndex].values = checkFeatureFlagForContractType(
        enabledFeatures.deltaEnabled,
      );
    if (
      [
        'contract_selection_value',
        'contract_selection_price_range_start',
        'contract_selection_price_range_end',
        'contract_selection_delta_range_start',
        'contract_selection_delta_range_end',
      ].includes(item.key)
    ) {
      if (
        ['price', 'price_greater_then', 'price_less_then'].includes(
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] as string,
        )
      ) {
        retData['hide'] = retData.type !== 'input';
        if (
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] === 'price_greater_then'
        ) {
          retData.name = 'Price Greater Than';
        } else if (
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] === 'price_less_then'
        ) {
          retData.name = 'Price Less Than';
        } else {
          retData.name = 'Closest Premium';
        }
      } else if (
        ['delta', 'delta_greater_then', 'delta_less_then'].includes(
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] as string,
        )
      ) {
        retData['hide'] = retData.type !== 'input';
        if (
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] === 'delta_greater_then'
        ) {
          retData.name = 'Delta Greater Than';
        } else if (
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] === 'delta_less_then'
        ) {
          retData.name = 'Delta Less Then';
        } else {
          retData.name = 'Closest Delta';
        }
      } else if (
        optionLeg.parent_contract_selection_type['contract_selection_type'] ===
        'price_range'
      ) {
        retData['hide'] =
          retData.parentKey !== 'parent_combine_contract_selection_price_range';
      } else if (
        optionLeg.parent_contract_selection_type['contract_selection_type'] ===
        'delta_range'
      ) {
        retData['hide'] =
          retData.parentKey !== 'parent_combine_contract_selection_delta_range';
      } else {
        retData.controls[itemIndex]['values'] = createContractSelectionOptions(
          optionSet.parent_symbol['symbol'] as string,
          optionLeg.parent_contract_selection_type[
            'contract_selection_type'
          ] as string,
          optionLeg.parent_right['right'] as string,
        );
        retData['hide'] = retData.type !== 'select';
      }
    } else if (item.key === 'target_value' || item.key === 'target_unit') {
      if (
        optionLeg.parent_Target_Profit &&
        optionLeg.parent_Target_Profit['toggleStatus']
      ) {
        optionLeg.parent_Target_Profit['target_unit'] = 'none';
      } else {
        const widthCondition = new Set([
          'delta_points_up',
          'delta_points_down',
          'delta_percentage_up',
          'delta_percentage_down',
          'strike_points_up',
          'strike_points_down',
          'strike_percentage_up',
          'strike_percentage_down',
          'entered_strike',
        ]);
        if (
          widthCondition.has(
            optionLeg['parent_Target_Profit']['target_unit'] as string,
          )
        ) {
          retData.width = 350;
        }
        if (
          optionLeg['parent_Target_Profit']['target_unit'] === 'entered_strike'
        ) {
          retData.controls[1].enabled = false;
          retData.controls[1].isRequired = false;
        }
        if (optionLeg.parent_Target_Profit['target_unit'] === 'none') {
          optionLeg.parent_Target_Profit['target_unit'] = 'points';
        }
      }
    } else if (item.key === 'sl_value' || item.key === 'sl_unit') {
      if (
        optionLeg.parent_stop_loss &&
        optionLeg.parent_stop_loss['toggleStatus']
      ) {
        optionLeg.parent_stop_loss['sl_unit'] = 'none';
      } else {
        const widthCondition = new Set([
          'delta_points_up',
          'delta_points_down',
          'delta_percentage_up',
          'delta_percentage_down',
          'strike_points_up',
          'strike_points_down',
          'strike_percentage_up',
          'strike_percentage_down',
          'entered_strike',
        ]);
        if (
          widthCondition.has(optionLeg['parent_stop_loss']['sl_unit'] as string)
        ) {
          retData.width = 350;
        }
        if (optionLeg['parent_stop_loss']['sl_unit'] === 'entered_strike') {
          retData.controls[1].enabled = false;
          retData.controls[1].isRequired = false;
        }
        if (optionLeg.parent_stop_loss['sl_unit'] === 'none') {
          optionLeg.parent_stop_loss['sl_unit'] = 'points';
        }
      }
    } else if (item.key === 'mtm_sl_unit' || item.key === 'mtm_sl_value') {
      if (
        optionSet.parent_strategy_stop_loss &&
        optionSet.parent_strategy_stop_loss['toggleStatus']
      ) {
        optionSet.parent_strategy_stop_loss['mtm_sl_unit'] = 'none';
      } else {
        if (optionSet.parent_strategy_stop_loss['mtm_sl_unit'] === 'none') {
          optionSet.parent_strategy_stop_loss['mtm_sl_unit'] = 'points';
        }
      }
    } else if (
      item.key === 'mtm_target_unit' ||
      item.key === 'mtm_target_value'
    ) {
      if (
        optionSet.parent_strategy_target_profit &&
        optionSet.parent_strategy_target_profit['toggleStatus']
      ) {
        optionSet.parent_strategy_target_profit['mtm_target_unit'] = 'none';
      } else {
        if (
          optionSet.parent_strategy_target_profit['mtm_target_unit'] === 'none'
        ) {
          optionSet.parent_strategy_target_profit['mtm_target_unit'] = 'points';
        }
      }
    } else if (
      item.key === 'combined_momentum_type' ||
      item.key === 'combined_momentum_value'
    ) {
      if (
        optionSet.parent_combined_momentum_type &&
        optionSet.parent_combined_momentum_type['toggleStatus']
      ) {
        optionSet.parent_combined_momentum_type['combined_momentum_type'] =
          'none';
        optionSet.parent_combined_momentum_type['combined_momentum_value'] = 0;
        // optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      } else {
        if (
          optionSet.parent_combined_momentum_type['combined_momentum_type'] ===
          'none'
        ) {
          optionSet.parent_combined_momentum_type['combined_momentum_type'] =
            'points_up';
          // optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
        }
      }
    } else if (
      item.key === 'mtm_lock_target_profit_unit' ||
      item.key === 'mtm_target_profit_reaches' ||
      item.key === 'mtm_lock_target'
    ) {
      if (
        optionSet.parent_mtm_lock_target_profit_unit &&
        optionSet.parent_mtm_lock_target_profit_unit['toggleStatus']
      ) {
        optionSet.parent_mtm_lock_target_profit_unit[
          'mtm_lock_target_profit_unit'
        ] = 'none';
        optionSet.parent_mtm_lock_target_profit_unit[
          'mtm_target_profit_reaches'
        ] = 0;
        optionSet.parent_mtm_lock_target_profit_unit['mtm_lock_target'] = 0;
        // optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      } else {
        if (
          optionSet.parent_mtm_lock_target_profit_unit[
            'mtm_lock_target_profit_unit'
          ] === 'none'
        ) {
          optionSet.parent_mtm_lock_target_profit_unit[
            'mtm_lock_target_profit_unit'
          ] = 'absolute';
          // optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
        }
      }
    } else if (
      item.key === 'mtm_trail_lock_profit_unit' ||
      item.key === 'mtm_target_profit_trail_every' ||
      item.key === 'mtm_trail_lock_profit_by'
    ) {
      if (!optionSet.parent_mtm_lock_target_profit_unit['toggleStatus']) {
        retData['hide'] = false;
      } else optionSet.parent_mtm_trail_lock_profit_unit['toggleStatus'] = true;
      // if (!optionSet.parent_mtm_trail_lock_profit_unit['toggleStatus'])

      // else

      if (
        optionSet.parent_mtm_trail_lock_profit_unit &&
        optionSet.parent_mtm_trail_lock_profit_unit['toggleStatus']
      ) {
        optionSet.parent_mtm_trail_lock_profit_unit[
          'mtm_trail_lock_profit_unit'
        ] = 'none';
        optionSet.parent_mtm_trail_lock_profit_unit[
          'mtm_target_profit_trail_every'
        ] = 0;
        optionSet.parent_mtm_trail_lock_profit_unit[
          'mtm_trail_lock_profit_by'
        ] = 0;
        optionSet.parent_mtm_lock_target_profit_unit[
          'is_trail_mtm_lock_profit'
        ] = false;
        // optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      } else {
        if (
          optionSet.parent_mtm_trail_lock_profit_unit[
            'mtm_trail_lock_profit_unit'
          ] === 'none'
        ) {
          optionSet.parent_mtm_trail_lock_profit_unit[
            'mtm_trail_lock_profit_unit'
          ] = 'absolute';
          optionSet.parent_mtm_lock_target_profit_unit[
            'is_trail_mtm_lock_profit'
          ] = true;
          // optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
        }
      }
    } else if (
      (item.key === 'unit' || item.key === 'value') &&
      Object.keys(optionDayWise).length
    ) {
      if (retData.parentKey === 'parent_strategy_stop_loss') {
        if (
          optionDayWise.parent_strategy_stop_loss &&
          optionDayWise.parent_strategy_stop_loss['toggleStatus']
        ) {
          optionDayWise.parent_strategy_stop_loss['unit'] = 'none';
          optionDayWise.parent_strategy_stop_loss['value'] = '0';
        } else {
          if (optionDayWise.parent_strategy_stop_loss['unit'] === 'none') {
            optionDayWise.parent_strategy_stop_loss['unit'] = 'absolute';
          }
        }
      } else if (retData.parentKey === 'parent_strategy_target_profit') {
        if (
          optionDayWise.parent_strategy_target_profit &&
          optionDayWise.parent_strategy_target_profit['toggleStatus']
        ) {
          optionDayWise.parent_strategy_target_profit['unit'] = 'none';
        } else {
          if (optionDayWise.parent_strategy_target_profit['unit'] === 'none') {
            optionDayWise.parent_strategy_target_profit['unit'] = 'absolute';
          }
        }
      }
    } else if (
      (item.key === 'activation_unit' || item.key === 'activation_value') &&
      Object.keys(optionDayWise).length
    ) {
      if (
        optionDayWise.parent_strategy_trailing_stop_loss &&
        optionDayWise.parent_strategy_trailing_stop_loss['toggleStatus']
      ) {
        optionDayWise.parent_strategy_trailing_stop_loss['activation_unit'] =
          'none';
      } else {
        if (
          optionDayWise.parent_strategy_trailing_stop_loss[
            'activation_unit'
          ] === 'none'
        ) {
          optionDayWise.parent_strategy_trailing_stop_loss['activation_unit'] =
            'absolute';
        }
      }
    } else if (
      (item.key === 'movement_type' ||
        item.key === 'movement_value' ||
        item.key === 'trail_value') &&
      Object.keys(optionDayWise).length
    ) {
      if (
        Object.prototype.hasOwnProperty.call(
          optionDayWise.parent_trail_stop_loss,
          'trail_sl',
        ) &&
        !optionDayWise.parent_trail_stop_loss['trail_sl']
      ) {
        optionDayWise.parent_trail_stop_loss['movement_type'] = 'none';
      } else {
        if (optionDayWise.parent_trail_stop_loss['movement_type'] === 'none') {
          optionDayWise.parent_trail_stop_loss['movement_type'] = 'absolute';
        }
      }
    } else if (
      item.key === 'positional_expire_on' &&
      optionSet.parent_position_type
    ) {
      retData['hide'] =
        optionSet.parent_position_type['position_type'] !== 'positional';
    } else if (
      item.key === 'positional_expire_on' ||
      item.key === 'max_allowed_entry_time' ||
      item.key === 'entry_time' ||
      item.key === 'exit_time' ||
      (item.key === 'days_to_run' && !Object.keys(optionDayWise).length)
    ) {
      retData['hide'] =
        optionSet.parent_position_type['position_type'] === 'continuous';
    } else if (
      item.key === 'rollover_days_before_expiry' ||
      item.key === 'maximum_days_to_expiry' ||
      item.key === 'minimum_days_to_expiry'
    ) {
      if (retData.parentKey === 'parent_rollover_days_before_expiry') {
        if (
          optionLeg.parent_rollover_days_before_expiry &&
          optionLeg.parent_rollover_days_before_expiry['toggleStatus']
        ) {
          optionLeg.parent_rollover_days_before_expiry[
            'rollover_days_before_expiry'
          ] = '0';
          optionLeg.parent_rollover_days_before_expiry['rollover_time'] =
            '00:00';
        }
      }
      if (
        retData.parentKey === 'parent_maximum_days_to_expiry' ||
        retData.parentKey === 'parent_minimum_days_to_expiry'
      ) {
        if (
          optionLeg.parent_maximum_days_to_expiry &&
          optionLeg.parent_maximum_days_to_expiry['toggleStatus']
        ) {
          optionLeg.parent_maximum_days_to_expiry['maximum_days_to_expiry'] =
            '-1';
        }
        if (
          optionLeg.parent_minimum_days_to_expiry &&
          optionLeg.parent_minimum_days_to_expiry['toggleStatus']
        ) {
          optionLeg.parent_minimum_days_to_expiry['minimum_days_to_expiry'] =
            '-1';
        }
      }
      retData['hide'] =
        optionSet.parent_position_type['position_type'] !== 'continuous';
    } else if (
      ['days_to_expire_entry', 'days_to_expire_exit'].includes(item.key)
    ) {
      if (optionSet.parent_position_type)
        retData.controls[itemIndex]['enabled'] =
          optionSet.parent_position_type['position_type'] === 'positional';
      if (optionSet.parent_positional_expire_on)
        retData.controls[itemIndex]['values'] =
          createDaysToExpireSelectionOptions(
            optionSet.parent_positional_expire_on[
              'positional_expire_on'
            ] as string,
            item.key === 'days_to_expire_exit',
            optionSet.parent_entry_time['days_to_expire_entry'] as string,
          );
      // eslint-disable-next-line no-dupe-else-if
    } else if (item.key === 'days_to_run' && optionSet.parent_position_type) {
      retData['hide'] =
        optionSet.parent_position_type['position_type'] === 'positional';
    } else if (item.key === 'reentry_type') {
      retData.controls[itemIndex].values = createRRTypeSelectionOptions(
        !!enabledFeatures.reEntryEnabled,
      );
    } else if (
      ['rr_type', 'reentry_reexecution_dependency'].includes(item.key) &&
      !enabledFeatures.reEntryEnabled
    ) {
      retData.controls[itemIndex].enabled = false;
    } else if (item.key === 'combine_expiry_type_value') {
      if (optionLeg['legEnabled'] === 'equityleg') {
        retData.hide = true;
      }
      if (enabledFeatures.botBuilderType?.includes('crypto')) {
        retData.hide = true;
      }
      retData.controls[itemIndex].values = createContractExpityTypeSelection(
        '',
        enabledFeatures.DTEenabled,
        optionLeg['legEnabled'] as string | undefined,
      );
      if (optionSet.parent_position_type['position_type'] === 'positional') {
        retData.controls[itemIndex].values = createContractExpityTypeSelection(
          optionSet.parent_positional_expire_on[
            'positional_expire_on'
          ] as string,
          undefined,
          optionLeg['legEnabled'] as string | undefined,
        );
      }
    } else if (
      item.key === 'breakout_on_underlying' &&
      optionLeg.parent_range_breakout &&
      Object.prototype.hasOwnProperty.call(
        optionLeg.parent_range_breakout,
        'toggleStatus',
      )
    ) {
      retData['hide'] = !(optionLeg.parent_range_breakout[
        'range_breakout'
      ] as boolean);
    } else if (item.key === 'position_type') {
      retData.controls[itemIndex].values = createButtonGroupOptions(
        enabledFeatures.continuousSet,
        enabledFeatures.botBuilderType,
      );
    } else if (item.key === 'symbol') {
      retData.controls[itemIndex].values = createTradingSymbolSelectionOptions({
        crudeOil: enabledFeatures.crudeOilSymbol,
        stockSymbols: enabledFeatures.stockSymbols,
        bankex: enabledFeatures.bankex,
        QQQ: enabledFeatures.QQQ,
        SPY: enabledFeatures.SPY,
        SPX: enabledFeatures.SPX,
        XSP: enabledFeatures.XSP,
        usaStockSymbols: enabledFeatures.usaStockSymbols,
        symbolFromBackend: enabledFeatures.symbolFromBackend,
        botBuilderType: enabledFeatures.botBuilderType || 'option',
      });
    }
  });
  return retData;
}

export function generateValuesBeforeSend(
  field: Omit<FieldParam, 'renderer' | 'type'>,
  selected: Record<string, FieldParamValue>,
  optionLeg?: OptionLegParam,
) {
  // in this function we are also modify the optionleg but not sending in return
  const retData = cloneDeep(field);
  retData.controls.forEach(item => {
    if (item.key === 'contract_selection_type' && optionLeg) {
      if (
        ['price', 'price_less_then', 'price_greater_then'].includes(
          selected[item.key] as string,
        )
      ) {
        optionLeg['parent_contract_selection_value'][
          'contract_selection_value'
        ] = '100';
      } else if (
        ['delta', 'delta_less_then', 'delta_greater_then'].includes(
          selected[item.key] as string,
        )
      ) {
        optionLeg['parent_contract_selection_value'][
          'contract_selection_value'
        ] = '0.50';
      } else if (selected[item.key] === 'price_range') {
        optionLeg['parent_combine_contract_selection_price_range'][
          'contract_selection_price_range_start'
        ] = '100';
        optionLeg['parent_combine_contract_selection_price_range'][
          'contract_selection_price_range_end'
        ] = '150';
      } else if (selected[item.key] === 'delta_range') {
        optionLeg['parent_combine_contract_selection_delta_range'][
          'contract_selection_delta_range_start'
        ] = '0.45';
        optionLeg['parent_combine_contract_selection_delta_range'][
          'contract_selection_delta_range_end'
        ] = '0.55';
      } else {
        optionLeg['parent_contract_selection_value'][
          'contract_selection_value'
        ] = '0';
      }
    } else if (item.key === 'momentum_type' || item.key === 'momentum_value') {
      if (selected['toggleStatus']) {
        selected['momentum_type'] = 'none';
        selected['momentum_value'] = 0;
      } else {
        if (selected['momentum_type'] === 'none') {
          selected['momentum_type'] = 'points';
          selected['momentum_value'] = 0;
        }
      }
    }
    //  else if (
    //   item.key === 'rollover_days_before_expiry' ||
    //   item.key === 'rollover_time'
    // ) {
    //   if (selected['toggleStatus']) {
    //     selected['rollover_days_before_expiry'] = '-1';
    //     selected['rollover_time'] = '00:00';
    //   } else {
    //     if (selected['rollover_days_before_expiry'] === 'none') {
    //       selected['rollover_days_before_expiry'] = '-1';
    //       selected['rollover_time'] = '00:00';
    //     }
    //   }
    // }
    else if (
      item.key === 'combined_momentum_type' ||
      item.key === 'combined_momentum_value'
    ) {
      if (selected['toggleStatus']) {
        selected['combined_momentum_type'] = 'none';
        selected['combined_momentum_value'] = 0;
      } else {
        if (selected['combined_momentum_type'] === 'none') {
          selected['combined_momentum_type'] = 'points_up';
          selected['combined_momentum_value'] = 0;
        }
      }
    } else if (
      item.key === 'trailing_sl_type' ||
      item.key === 'trailing_sl_trigger_value' ||
      item.key === 'trailing_sl_value'
    ) {
      if (selected['toggleStatus']) {
        selected['trailing_sl_type'] = 'none';
        selected['trailing_sl_trigger_value'] = 0;
        selected['trailing_sl_value'] = 0;
      } else {
        if (selected['trailing_sl_type'] === 'none') {
          selected['trailing_sl_type'] = 'points';
          selected['trailing_sl_trigger_value'] = 0;
          selected['trailing_sl_value'] = 0;
        }
      }
    } else if (item.key === 'target_value' || item.key === 'target_unit') {
      if (selected['toggleStatus']) {
        selected['target_unit'] = 'none';
        selected['target_value'] = 0;
      } else {
        if (selected['target_unit'] === 'none') {
          selected['target_unit'] = 'points';
          selected['target_value'] = 0;
        }
      }
    } else if (item.key === 'sl_value' || item.key === 'sl_unit') {
      if (selected['toggleStatus']) {
        selected['sl_unit'] = 'none';
        selected['sl_value'] = 0;
      } else {
        if (selected['sl_unit'] === 'none') {
          selected['sl_unit'] = 'points';
          selected['sl_value'] = 0;
        }
      }
    } else if (item.key === 'reentry_count' || item.key === 'reentry_type') {
      if (selected['toggleStatus']) {
        selected['reentry_type'] = 'none';
        selected['reentry_count'] = 0;
      } else {
        if (selected['reentry_type'] === 'none') {
          selected['reentry_type'] = 'ctc';
          selected['reentry_count'] = 0;
        }
      }
    } else if (item.key === 'mtm_sl_unit' || item.key === 'mtm_sl_value') {
      if (selected['toggleStatus']) {
        selected['mtm_sl_unit'] = 'none';
        selected['mtm_sl_value'] = 0;
      } else {
        if (selected['mtm_sl_unit'] === 'none') {
          selected['mtm_sl_unit'] = 'points';
          selected['mtm_sl_value'] = 0;
        }
      }
    } else if (
      item.key === 'mtm_target_unit' ||
      item.key === 'mtm_target_value'
    ) {
      if (selected['toggleStatus']) {
        selected['mtm_target_unit'] = 'none';
        // selected['mtm_target_value'] = 0;
      } else {
        if (selected['mtm_target_unit'] === 'none') {
          selected['mtm_target_unit'] = 'points';
          // selected['mtm_target_value'] = 0;
        }
      }
    } else if (item.key === 'unit' || item.key === 'value') {
      if (selected['toggleStatus']) {
        selected['unit'] = 'none';
        selected['value'] = 0;
      } else {
        if (selected['unit'] === 'none') {
          selected['unit'] = 'absolute';
          selected['value'] = 0;
        }
      }
    } else if (
      item.key === 'activation_unit' ||
      item.key === 'activation_value'
    ) {
      if (selected['toggleStatus']) {
        selected['activation_unit'] = 'none';
        selected['activation_value'] = 0;
      } else {
        if (selected['activation_unit'] === 'none') {
          selected['activation_unit'] = 'absolute';
          selected['activation_value'] = 0;
        }
      }
    } else if (
      item.key === 'movement_type' ||
      item.key === 'movement_value' ||
      item.key === 'trail_value'
    ) {
      if (selected['toggleStatus']) {
        selected['movement_type'] = 'none';
        selected['movement_value'] = 0;
        selected['trail_value'] = 0;
      } else {
        if (selected['movement_type'] === 'none') {
          selected['movement_type'] = 'absolute';
          selected['movement_value'] = 0;
          selected['trail_value'] = 0;
        }
      }
    } else if (item.key === 'reexecution_type') {
      if (selected['toggleStatus']) {
        selected['reexecution_type'] = 'none';
        selected['reexecution_dependency'] = 'none';
      } else {
        if (selected['reexecution_type'] === 'none') {
          selected['reexecution_type'] = 'immediate';
          selected['reexecution_dependency'] = 'sl';
        }
      }
    } else if (item.key === 'reexecution_delay_seconds') {
      if (selected['toggleStatus']) {
        selected['reexecution_delay_seconds'] = 0;
      } else {
        if (selected['reexecution_delay_seconds'] === 'none') {
          selected['reexecution_delay_seconds'] = true;
        }
      }
    } else if (item.key === 'breakout_bar_field') {
      if (selected['toggleStatus']) {
        selected['breakout_bar_field'] = 'none';
      } else {
        if (selected['breakout_bar_field'] === 'none') {
          selected['breakout_bar_field'] = 'high';
        }
      }
    }
  });
  return selected;
}

// export function checkIfSetFieldNeedToHide(
//   field: FieldParam,
//   optionSet: OptionSetParam,
// ) {
//   const retData = cloneDeep(field);
//   if (field.controls.key === 'mtm_sl_value') {
//     if (optionSet.mtm_sl_unit === 'none') {
//       retData.hide = true;
//       optionSet.mtm_sl_value = 0;
//     }
//   } else if (field.key === 'mtm_target_value') {
//     if (optionSet.mtm_target_unit === 'none') {
//       retData.hide = true;
//       optionSet.mtm_target_value = 0;
//     }
//   }
//   return retData;
// }

export const appendExtraSetFields = (sets: OptionSetParam[]) => {
  if (sets.length) {
    sets = sets.map(x => {
      // if (x['isFutureLegEnabled'] === undefined) {
      //   x['isFutureLegEnabled'] = 'false';
      // }
      if (x['square_off_all_legs'] === undefined)
        x['square_off_all_legs'] = 'false';
      if (x['reexecution_type'] === undefined) {
        x['reexecution_type'] = 'none';
        x['reexecution_count'] = '0';
        x['reexecution_dependency'] = 'none';
      }
      if (x['combined_momentum_type'] === undefined) {
        x['combined_momentum_type'] = 'none';
        x['combined_momentum_value'] = '0';
      }
      if (x['position_type'] === undefined) {
        x['position_type'] = 'continuous';
        // x['holding_type'] === 'mis' ? 'intraday' : 'stbt_btst';
        x['positional_expire_on'] = 'weekly';
        x['days_to_expire_entry'] = '0';
        x['days_to_expire_exit'] = '0';
      }
      if (x['indicator_extras'] === undefined) {
        x['indicator_extras'] = {
          entry_conditions: [],
          exit_conditions: [],
        };
      }
      if (x['mtm_lock_target_profit_unit'] === undefined) {
        x['mtm_lock_target_profit_unit'] = 'none';
        x['mtm_target_profit_reaches'] = '0';
        x['mtm_lock_target'] = '0';
      }
      if (x['mtm_trail_lock_profit_unit'] === undefined) {
        x['mtm_trail_lock_profit_unit'] = 'none';
        x['mtm_target_profit_trail_every'] = '0';
        x['mtm_trail_lock_profit_by'] = '0';
        x['is_trail_mtm_lock_profit'] = 'false';
      }
      if (x['max_allowed_entry_time'] === undefined) {
        x['max_allowed_entry_time'] = '00:00';
      }
      if (x['entry_if_missed'] === undefined) x['entry_if_missed'] = 'false';
      if (x['external_signal'] === undefined)
        x['external_signal'] = { enable: false };
      return x;
    });
  }
  return sets;
};

export const appendExtraLegFields = (
  legs: OptionLegParam[],
  // legEnabled,
) => {
  if (legs.length) {
    legs = legs.map(leg => {
      if (leg['legEnabled'] == 'optionleg' || leg['legEnabled'] == undefined) {
        if (leg['rr_type'] === undefined) leg['rr_type'] = 'reentry';
        if (leg['reentry_reexecution_dependency'] === undefined)
          leg['reentry_reexecution_dependency'] = 'sl';
        if (
          typeof leg['contract_selection_value'] === 'string' &&
          leg['contract_selection_value'].split('-').length == 2
        ) {
          const priceRange = leg['contract_selection_value'].split('-');
          leg['contract_selection_price_range_start'] = priceRange[0];
          leg['contract_selection_price_range_end'] = priceRange[1];
          leg['contract_selection_delta_range_start'] = priceRange[0];
          leg['contract_selection_delta_range_end'] = priceRange[1];
        } else {
          leg['contract_selection_price_range_start'] = '100';
          leg['contract_selection_price_range_end'] = '150';
          leg['contract_selection_delta_range_start'] = '0.45';
          leg['contract_selection_delta_range_end'] = '0.55';
        }
        if (leg['range_breakout'] === undefined) {
          leg['range_breakout'] = false;
          leg['breakout_bar_starttime'] = '09:15';
          leg['breakout_bar_endtime'] = '10:00';
          leg['breakout_bar_field'] = 'none';
          leg['breakout_on_underlying'] = false;
        }
        if (leg['indicator_extras'] === undefined) {
          leg['indicator_extras'] = {
            entry_on_underlying: false,
            exit_on_underlying: false,
            entry_conditions: [],
            exit_conditions: [],
          };
        }
        if (leg['momentum_type'] === undefined) {
          leg['momentum_type'] = 'none';
          leg['momentum_value'] = '0';
        }
        if (leg['trail_sl'] === undefined) {
          leg['trail_sl'] = false;
          leg['trailing_sl_value'] = '0';
          leg['trailing_sl_trigger_value'] = '0';
          leg['trailing_sl_type'] = 'none';
        }
        if (leg['entry_execution_settings'] === undefined) {
          leg['entry_execution_settings'] = {
            order_type: 'market',
            buffer_type: 'points',
            limit_buffer: 0,
            trigger_buffer: 0,
            convert_to_market_after: 0,
          };
        }
        if (leg['exit_execution_settings'] === undefined) {
          leg['exit_execution_settings'] = {
            order_type: 'market',
            buffer_type: 'points',
            limit_buffer: 0,
            trigger_buffer: 0,
            convert_to_market_after: 0,
          };
        }
        if (
          leg['maximum_days_to_expiry'] === undefined ||
          leg['minimum_days_to_expiry'] === undefined
        ) {
          leg['maximum_days_to_expiry'] = '-1';
          leg['minimum_days_to_expiry'] = '-1';
        }
        if (
          leg['rollover_days_before_expiry'] === undefined ||
          leg['rollover_time'] === undefined
        ) {
          leg['rollover_days_before_expiry'] = '0';
          leg['rollover_time'] = '00:00';
        }
        // if (
        //   leg['order_product_type'] === undefined ||
        //   leg['order_product_type'] === 'mis'
        // ) {
        //   leg['order_product_type'] = 'undefined';
        // }
        return leg;
      } else {
        if (leg['right'] === 'call ') {
          delete leg['right'];
        }
        if (leg['indicator_extras'] === undefined) {
          leg['indicator_extras'] = {
            entry_on_underlying: false,
            exit_on_underlying: false,
            entry_conditions: [],
            exit_conditions: [],
          };
        }
        if (leg['entry_execution_settings'] === undefined) {
          leg['entry_execution_settings'] = {
            order_type: 'market',
            buffer_type: 'points',
            limit_buffer: 0,
            trigger_buffer: 0,
            convert_to_market_after: 0,
          };
        }
        if (leg['exit_execution_settings'] === undefined) {
          leg['exit_execution_settings'] = {
            order_type: 'market',
            buffer_type: 'points',
            limit_buffer: 0,
            trigger_buffer: 0,
            convert_to_market_after: 0,
          };
        }

        return leg;
      }
    });
  }
  return legs;
};
export const generateParentKey = (
  optionData: OptionSetParam[] | OptionLegParam[] | OptionDayWiseParam[],
) => {
  const optionSetWithParentKey: Record<
    string,
    Record<string, string>
  > = calculateOptionSetParentKey();
  const optionLegWithParentKey: Record<
    string,
    Record<string, string>
  > = calculateOptionLegParentKey();
  if (optionData.length) {
    return optionData.map((optionSet: OptionSetParam | OptionLegParam) => {
      let returnData: OptionSetParam = {};
      for (const [key, value] of Object.entries(optionSet)) {
        const optionKey = optionSetWithParentKey[key];
        const legKey = optionLegWithParentKey[key];
        if (key === 'legs') {
          returnData = {
            ...returnData,
            [key]: generateParentKey(
              appendExtraLegFields(
                value as OptionLegParam[],
                // optionSet.isFutureLegEnabled,
              ),
            ),
          };
        } else if (optionKey && optionKey.parentKey) {
          if (optionKey.type === 'selectwithinput') {
            returnData = {
              ...returnData,
              [optionKey.parentKey]: {
                ...(returnData[optionKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                toggleStatus: (value === 'none'
                  ? true
                  : returnData[optionKey.parentKey] &&
                    Object.prototype.hasOwnProperty.call(
                      returnData[optionKey.parentKey],
                      'toggleStatus',
                    )
                  ? returnData[optionKey.parentKey]['toggleStatus']
                  : false) as boolean,
                [key]: value,
              } as Record<string, Record<string, FieldParamValue>>,
            } as OptionSetParam;
          } else {
            returnData = {
              ...returnData,
              [optionKey.parentKey]: {
                ...(returnData[optionKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                [key]: value,
              } as Record<string, FieldParamValue>,
            };
          }
        } else if (legKey && legKey.parentKey) {
          if (legKey.type === 'selectwithinput') {
            returnData = {
              ...returnData,
              [legKey.parentKey]: {
                ...(returnData[legKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                toggleStatus: (value === 'none'
                  ? true
                  : returnData[legKey.parentKey] &&
                    Object.prototype.hasOwnProperty.call(
                      returnData[legKey.parentKey],
                      'toggleStatus',
                    )
                  ? returnData[legKey.parentKey]['toggleStatus']
                  : false) as boolean,
                [key]: value,
              },
            } as OptionSetParam;
          } else if (key === 'expiry_value' || key === 'expiry_type') {
            returnData = {
              ...returnData,
              ['parent_combine_expiry_type_value']: {
                ['combine_expiry_type_value']: returnData
                  ?.parent_combine_expiry_type_value?.[
                  'combine_expiry_type_value'
                ]
                  ? `${returnData['parent_combine_expiry_type_value']['combine_expiry_type_value']}_${value}`
                  : value,
              } as Record<string, FieldParamValue>,
              [legKey.parentKey]: {
                ...(returnData[legKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                [key]: value,
              } as Record<string, FieldParamValue>,
            };
          } else {
            returnData = {
              ...returnData,
              [legKey.parentKey]: {
                ...(returnData[legKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                [key]: value,
              } as Record<string, FieldParamValue>,
            };
          }
        } else {
          returnData = { ...returnData, [key]: value } as OptionSetParam;
        }
      }
      return returnData;
    });
  } else {
    return [];
  }
};

export function createEmptyOptionDayWiseParam(
  OptionDayWiseFieldsGroupWise: FieldParam[],
): OptionDayWiseParam {
  const OptionDayWiseFieldsGroupWiseData = cloneDeep(
    OptionDayWiseFieldsGroupWise,
  );

  let emptyOptionDayWise: OptionDayWiseParam = {
    parent_days_to_run: { days_to_run: [] },
  };
  OptionDayWiseFieldsGroupWiseData.forEach(field => {
    if (field.type === 'selectwithinput') {
      emptyOptionDayWise = {
        ...emptyOptionDayWise,
        [field.parentKey]: { toggleStatus: true },
      };
    }
    field.controls.forEach(control => {
      emptyOptionDayWise = {
        ...emptyOptionDayWise,
        [field.parentKey]: {
          ...(emptyOptionDayWise[field.parentKey] as Record<
            string,
            FieldParamValue
          >),
          [control.key]: control.defaultValue,
        },
      };
    });
  });

  return emptyOptionDayWise;
}

export const generateOptionDayWiseParentKey = (
  optionData: OptionSetParam[],
  key: string,
) => {
  const optionSetWithParentKey: Record<string, Record<string, string>> = key ===
  DAY_WISE_TARGETSTOPLOSS
    ? calculateOptionTrailingStopLossFieldsParentKey()
    : key === DAY_WISE_STOPLOSS
    ? calculateOptionStopLossFieldsParentKey()
    : calculateOptionTargetProfitFieldsParentKey();
  if (optionData.length) {
    return optionData.map((optionSet: OptionSetParam) => {
      let returnData: OptionDayWiseParam = {};
      for (const [key, value] of Object.entries(optionSet)) {
        const optionKey = optionSetWithParentKey[key];
        if (optionKey && optionKey.parentKey) {
          if (optionKey.type === 'selectwithinput') {
            returnData = {
              ...returnData,
              [optionKey.parentKey]: {
                ...(returnData[optionKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                toggleStatus: (value === 'none'
                  ? true
                  : returnData[optionKey.parentKey] &&
                    Object.prototype.hasOwnProperty.call(
                      returnData[optionKey.parentKey],
                      'toggleStatus',
                    )
                  ? returnData[optionKey.parentKey]['toggleStatus']
                  : false) as boolean,
                [key]: value,
              } as Record<string, Record<string, FieldParamValue>>,
            } as OptionDayWiseParam;
          } else {
            returnData = {
              ...returnData,
              [optionKey.parentKey]: {
                ...(returnData[optionKey.parentKey] as Record<
                  string,
                  FieldParamValue
                >),
                [key]: value,
              } as Record<string, FieldParamValue>,
            };
          }
        } else {
          returnData = { ...returnData, [key]: value } as OptionDayWiseParam;
        }
      }
      return returnData;
    });
  } else {
    return [];
  }
};

export const CheckValidate = (strategy: SbStrategy) => {
  const sets =
    strategy.sb_opt_strategy && strategy.sb_opt_strategy.strategy_sets
      ? (strategy.sb_opt_strategy.strategy_sets as OptionSetParam[])
      : [];

  let isValid = true;
  sets.forEach(set => {
    const legs = set.legs as OptionLegParam[];
    if (legs.length === 0) {
      isValid = false;
      return;
    }

    legs.forEach(leg => {
      if (
        leg.trail_sl === true &&
        (!leg.trailing_sl_value ||
          !leg.trailing_sl_trigger_value ||
          leg.trailing_sl_value === '0' ||
          leg.trailing_sl_trigger_value == '0')
      ) {
        isValid = false;
        return;
      }

      // if (
      //   leg.momentum_type !== 'none' &&
      //   (!leg.momentum_value || leg.momentum_value === '0')
      // ) {
      //   isValid = false;
      //   return;
      // }
    });
  });

  return isValid;
};

export const daysToRunDisabledUsedDays = (
  param: OptionDayWiseParam[] | OptionSetParam[],
  index: number,
) => {
  let disabledKeys: string[] = [];
  param.forEach((optionDayWiseData, i) => {
    let dayToRun = (
      optionDayWiseData.parent_days_to_run as Record<string, FieldParamValue>
    ).days_to_run as string[];

    if (i < index) {
      disabledKeys = [...disabledKeys, ...dayToRun];
      return;
    }

    dayToRun = dayToRun.filter(el => !disabledKeys.includes(el));
    if (dayToRun && dayToRun.length)
      disabledKeys = [...disabledKeys, ...dayToRun];

    (
      optionDayWiseData.parent_days_to_run as Record<string, FieldParamValue>
    ).days_to_run = dayToRun;
  });
  return param;
};

export const createStraddleSets = (
  templateData: OptionSetStraddleValueType,
  totalLegs: number,
) => {
  const legs: OptionLegParam[] = [];
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 1));

  const leg1 = legs[0];
  const leg2 = legs[1];

  leg1.parent_right['right'] = 'call';
  leg2.parent_right['right'] = 'put';

  leg1.parent_direction['direction'] = leg2.parent_direction['direction'] =
    templateData.direction;
  leg1.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg2.parent_combine_expiry_type_value['combine_expiry_type_value'] =
      templateData.expiry;

  leg1.parent_lots['lots'] = leg2.parent_lots['lots'] = templateData.lots;

  leg1.parent_contract_selection_value['contract_selection_value'] =
    templateData.value.toString();
  leg2.parent_contract_selection_value['contract_selection_value'] = (
    templateData.value * -1
  ).toString();

  return legs;
};

export const createStrangleSets = (
  templateData: OptionSetStrangleValueType,
  totalLegs: number,
) => {
  const legs: OptionLegParam[] = [];
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 1));

  const leg1 = legs[0];
  const leg2 = legs[1];

  leg1.parent_right['right'] = 'put';
  leg2.parent_right['right'] = 'call';

  leg1.parent_direction['direction'] = leg2.parent_direction['direction'] =
    templateData.direction;
  leg1.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg2.parent_combine_expiry_type_value['combine_expiry_type_value'] =
      templateData.expiry;

  leg1.parent_lots['lots'] = leg2.parent_lots['lots'] = templateData.lots;

  leg1.parent_contract_selection_value['contract_selection_value'] = (
    templateData.value1 * -1
  ).toString();
  leg2.parent_contract_selection_value['contract_selection_value'] =
    templateData.value2.toString();

  return legs;
};

export const createSpreadSets = (
  templateData: OptionSetSpreadValueType,
  totalLegs: number,
) => {
  const legs: OptionLegParam[] = [];
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 1));

  const leg1 = legs[0];
  const leg2 = legs[1];

  leg1.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg2.parent_combine_expiry_type_value['combine_expiry_type_value'] =
      templateData.expiry;
  leg1.parent_lots['lots'] = leg2.parent_lots['lots'] = templateData.lots;
  leg1.parent_right['right'] = leg2.parent_right['right'] = templateData.type;

  leg1.parent_direction['direction'] = templateData.direction;
  leg2.parent_direction['direction'] =
    templateData.direction === 'long' ? 'short' : 'long';

  leg1.parent_contract_selection_value['contract_selection_value'] = (
    templateData.value1 * (templateData.type === 'put' ? -1 : 1)
  ).toString();
  leg2.parent_contract_selection_value['contract_selection_value'] = (
    templateData.value2 * (templateData.type === 'put' ? -1 : 1)
  ).toString();

  return legs;
};

export const createIronFlySets = (
  templateData: OptionSetIronFlyValueType,
  totalLegs: number,
) => {
  const legs: OptionLegParam[] = [];
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 1));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 2));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 3));

  const leg1 = legs[0];
  const leg2 = legs[1];
  const leg3 = legs[2];
  const leg4 = legs[3];

  leg1.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg2.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg3.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg4.parent_combine_expiry_type_value['combine_expiry_type_value'] =
      templateData.expiry;

  leg1.parent_lots['lots'] =
    leg2.parent_lots['lots'] =
    leg3.parent_lots['lots'] =
    leg4.parent_lots['lots'] =
      templateData.lots;

  leg1.parent_right['right'] = leg2.parent_right['right'] = 'call';
  leg3.parent_right['right'] = leg4.parent_right['right'] = 'put';

  leg1.parent_direction['direction'] = leg3.parent_direction['direction'] =
    'long';
  leg2.parent_direction['direction'] = leg4.parent_direction['direction'] =
    'short';

  if (templateData.type === 'short') {
    leg1.parent_contract_selection_value['contract_selection_value'] =
      templateData.value3.toString();
    leg2.parent_contract_selection_value['contract_selection_value'] =
      templateData.value2.toString();
    leg4.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value2 * -1
    ).toString();
    leg3.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value1 * -1
    ).toString();
  } else {
    leg2.parent_contract_selection_value['contract_selection_value'] =
      templateData.value3;
    leg1.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value2 * -1
    ).toString();
    leg3.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value2 * -1
    ).toString();
    leg4.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value1 * -1
    ).toString();
  }

  return legs;
};

export const createIronCondorSets = (
  templateData: OptionSetIronCondorValueType,
  totalLegs: number,
) => {
  const legs: OptionLegParam[] = [];
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 1));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 2));
  legs.push(createEmptyOptionLeg(cloneDeep(OptionLegFields), totalLegs + 3));

  const leg1 = legs[0];
  const leg2 = legs[1];
  const leg3 = legs[2];
  const leg4 = legs[3];

  leg1.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg2.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg3.parent_combine_expiry_type_value['combine_expiry_type_value'] =
    leg4.parent_combine_expiry_type_value['combine_expiry_type_value'] =
      templateData.expiry;

  leg1.parent_lots['lots'] =
    leg2.parent_lots['lots'] =
    leg3.parent_lots['lots'] =
    leg4.parent_lots['lots'] =
      templateData.lots;

  leg1.parent_right['right'] = leg2.parent_right['right'] = 'call';
  leg3.parent_right['right'] = leg4.parent_right['right'] = 'put';

  leg1.parent_direction['direction'] = leg3.parent_direction['direction'] =
    'long';
  leg2.parent_direction['direction'] = leg4.parent_direction['direction'] =
    'short';

  if (templateData.type === 'short') {
    leg1.parent_contract_selection_value['contract_selection_value'] =
      templateData.value4.toString();
    leg2.parent_contract_selection_value['contract_selection_value'] =
      templateData.value3.toString();
    leg3.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value1 * -1
    ).toString();
    leg4.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value2 * -1
    ).toString();
  } else {
    leg1.parent_contract_selection_value['contract_selection_value'] =
      templateData.value3.toString();
    leg2.parent_contract_selection_value['contract_selection_value'] =
      templateData.value4.toString();
    leg3.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value2 * -1
    ).toString();
    leg4.parent_contract_selection_value['contract_selection_value'] = (
      templateData.value1 * -1
    ).toString();
  }

  return legs;
};

export const transformLegsOnChange = (
  symbol: string,
  legs: OptionLegParam[],
) => {
  legs.forEach((x, i) => {
    const expiry = (
      legs[i].parent_combine_expiry_type_value as Record<
        string,
        FieldParamValue
      >
    )['combine_expiry_type_value'] as string;
    const selectionType = (
      legs[i].parent_contract_selection_type as Record<string, FieldParamValue>
    )['contract_selection_type'] as string;
    const selectionValue = (
      legs[i].parent_contract_selection_value as Record<string, FieldParamValue>
    )['contract_selection_value'] as string;

    if (!expiry.startsWith('weekly')) {
      legs[i].parent_combine_expiry_type_value['combine_expiry_type_value'] =
        'weekly_0';
    }
    const range = SYMBOL_RANGES[symbol];
    if (
      !selectionType.startsWith('price') &&
      (!selectionValue ||
        parseInt(selectionValue) < range[0] ||
        parseInt(selectionValue) > range[1])
    ) {
      legs[i].parent_contract_selection_value['contract_selection_value'] = '0';
    }
  });

  return legs;
};
