import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import DynamicForm from './DynamicForm';

// Service
import useGetBrokerForm from 'services/Menu/useGetBrokerForm';
import useGetBrokerById from 'services/Menu/useGetBrokerById';
import useEditBroker from 'services/Menu/useEditBroker';
import useDeleteBroker from 'services/Menu/useDeleteBroker';
import { CreateBrokerParams } from 'types/ApiServicesTypes';
import useGetBrokerAuthUrl from 'services/Menu/useGetBrokerAuthUrl';
import useBrokerLogout from 'services/Menu/useGetBrokerLogout';
import { usePostTOTPVarification } from 'services/Menu/usePostTOTPVarification';
import { useState } from 'react';
import TOTPVarificationFormModal from './TOTPVarificationFormModal';

const EditBrokerForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [openTOTPModal, setOpenTOTPModal] = useState(false);

  const { data, isLoading } = useGetBrokerForm();
  const { data: brokerData, isLoading: brokerIsLoading } = useGetBrokerById(
    parseInt(id),
  );

  const mutation = useEditBroker(parseInt(id));
  const mutationDelete = useDeleteBroker(parseInt(id));
  const mutationGetBrokerAuthUrl = useGetBrokerAuthUrl();
  const mutationBrokerLogout = useBrokerLogout();
  const mutationTOTPVarification = usePostTOTPVarification(parseInt(id));

  const handleFormSubmit = values => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const valuesData: CreateBrokerParams = { ...values };
    mutation.mutate(valuesData);
  };

  const handleFormDelete = () => {
    mutationDelete.mutate();
  };
  const handleFormVerify = () => {
    if (brokerData?.totp_verification) {
      setOpenTOTPModal(true);
    } else {
      mutationGetBrokerAuthUrl.mutate({ id: parseInt(id) });
    }
  };
  const handleBrokerLogout = () => {
    mutationBrokerLogout.mutate({ id: parseInt(id) });
  };

  const handleCloseTOTPVarificationModal = () => {
    setOpenTOTPModal(false);
  };

  const handleSubmitTOTPVarificationModal = ({ totp }: { totp: string }) => {
    mutationTOTPVarification.mutate({
      totp: totp,
    });
  };

  return (
    <>
      <CircularLoader open={isLoading || brokerIsLoading} />
      {data && data.brokers && brokerData && (
        <DynamicForm
          formData={data}
          handleFormSubmit={handleFormSubmit}
          submitButtoneText={t('buttons.update')}
          cancelButtonLink="/broker"
          brokerData={brokerData}
          handleFormDelete={handleFormDelete}
          handleFormVerify={handleFormVerify}
          handleBrokerLogout={handleBrokerLogout}
          isLoading={
            mutation.isLoading ||
            mutationDelete.isLoading ||
            mutationGetBrokerAuthUrl.isLoading
          }
        />
      )}
      {openTOTPModal && (
        <TOTPVarificationFormModal
          open={openTOTPModal}
          handleClose={handleCloseTOTPVarificationModal}
          handleSubmitData={handleSubmitTOTPVarificationModal}
        />
      )}
    </>
  );
};

export default EditBrokerForm;
