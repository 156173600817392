import { Box, Divider, useTheme } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useGetWebHookSignalDetails from 'services/WebHookSignals/useGetWebHookSignalDetail';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import SignalDetailTable from 'app/components/WebHookSignalComponent/SignalDetailTable';
import WebhookSignalDetailModal from 'app/components/WebHookSignalComponent/WebhookSignalDetailModal';
import { TWebHookSignal } from 'types/ApiServicesTypes';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import WebhookSignalDetailItem from 'app/components/WebHookSignalComponent/WebhookSignalDetailItem';
import MuiButton from 'app/design/uiComponents/MuiButton';
import WebHookBotModal from 'app/components/WebHookSignalComponent/WebHookBotModal';

const WebHookSignalDetails = () => {
  const { bid } = useParams<{ bid: string }>();
  const parsedBid = parseInt(bid);
  const theme = useTheme();
  const { data: signalData, isLoading } = useGetWebHookSignalDetails(parsedBid);
  const [open, setOpen] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState<TWebHookSignal>();
  const history = useHistory();
  const [openBotModal, setOpenBotModal] = useState<boolean>(false);
  const handleBotModalClose = () => {
    setOpenBotModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (value: TWebHookSignal) => {
    setOpen(true);
    setSelectedSignal(value);
  };
  const handleBotModalClick = () => {
    if (signalData?.bot_data && signalData.bot_data.broker_name)
      setOpenBotModal(true);
    else history.push('/signal');
  };
  const isMdUp = useGetMediaQueryUp('md');
  // const location = useLocation<TWebHookBot[]>();

  return (
    <>
      <Box>
        <CircularLoader open={isLoading} zIndex={1302} />
        <Helmet>
          <title>Signal Details</title>
          <meta name="description" content="Signal Detail PAge" />
        </Helmet>

        <PageHeader
          variant="inactive"
          buttonText={<FormattedMessage id="Signal Details" />}
          title={<FormattedMessage id="Signal Details" />}
          buttonHandleClick="/signal"
          breadcrumb={<Breadcrumbs page={PAGES.SIGNALS} data={{}} />}
          rightElement={
            <MuiButton
              variant="contained"
              color="secondary"
              onClick={() => handleBotModalClick()}
            >
              View Info
            </MuiButton>
          }
        />
        <Box
          className="sticky-top"
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
        </Box>
        {isMdUp
          ? signalData && (
              <SignalDetailTable
                data={signalData?.signals}
                handleClick={handleClick}
              />
            )
          : signalData &&
            signalData.signals &&
            signalData.signals.map((signal, index) => (
              <Box key={index}>
                <WebhookSignalDetailItem
                  bot={signal}
                  handleViewSignalClick={handleClick}
                />
                {signalData.signals.length - 1 !== index && (
                  <Divider className="m-main-spacing" />
                )}
              </Box>
            ))}
      </Box>
      <WebhookSignalDetailModal
        open={open}
        handleClose={handleClose}
        data={selectedSignal}
      />
      {signalData?.bot_data && signalData.bot_data.broker_name && (
        <WebHookBotModal
          open={openBotModal}
          handleClose={handleBotModalClose}
          data={signalData.bot_data}
        />
      )}
    </>
  );
};

export default WebHookSignalDetails;
