import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { PostPortfolioManualBot } from 'services/apiServices';
import {
  Errors,
  PostPortfolioManualBotResults,
  PostPortfolioManualBotParams,
} from 'types/ApiServicesTypes';

export default function usePostManualBot() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostPortfolioManualBotResults,
    Errors,
    Pick<
      PostPortfolioManualBotParams,
      'bot_name' | 'broker' | 'capital' | 'duration' | 'multiplier' | 'type'
    >
  >(['PostPortfolioManualBot'], values =>
    PostPortfolioManualBot(
      values.broker,
      values.capital,
      values.duration,
      values.multiplier,
      values.bot_name,
      values.type,
    )
      .then(res => {
        if (res.error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          showAlert!(res.error);
        }

        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
