import CommonTable from 'app/components/Common/CommonTable';
import { createOptionTableRows } from './utils';
import { SymbolOptionChainDataType } from 'types/ApiServicesTypes';
import { PositionBaseDataType, PositionData } from '../types';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';

type Props = {
  optionChainData: Record<string, SymbolOptionChainDataType>;
  handleOptionClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void;
  isGreeks: boolean;
  currentStrike: number;
  positions: PositionData;
  isCE: boolean;
  date: string;
};

const OptionCustomTable = ({
  optionChainData,
  handleOptionClick,
  isGreeks,
  currentStrike,
  positions,
  isCE,
  date,
}: Props) => {
  const theme = useTheme();
  const { rows, columns } = createOptionTableRows(
    optionChainData,
    handleOptionClick,
    isGreeks,
    currentStrike,
    positions,
    isCE,
    date,
    theme,
  );

  return (
    <CommonTable
      containerStyle={{
        height: '72vh',
        scrollbarWidth: 'none',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
      }}
      headerSticky={true}
      rows={rows}
      columns={columns}
    />
  );
};

export default memo(OptionCustomTable);
