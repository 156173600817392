import { Box, Typography } from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { TWebHookBot } from 'types/ApiServicesTypes';
import MuiButton from 'app/design/uiComponents/MuiButton';
import React from 'react';
interface IwebHookItem {
  bot: TWebHookBot;
  handleViewBotClick: (bot: TWebHookBot) => void;
  handleActionClick: (status: string, sub_id: number) => void;
  handleContainerClick: (sub_id: number) => void;
}
const WebHookBotItem = ({
  bot,
  handleViewBotClick,
  handleActionClick,
  handleContainerClick,
}: IwebHookItem) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2, cursor: 'pointer' }}
      onClick={() => {
        handleContainerClick(bot.sub_id);
      }}
    >
      {/* {order.status && order.status !== '3' && orderStatus[order.status] && (
        <Box sx={{ mb: 1 }}>
          <MuiChip
            icon={<>{orderStatus[order.status].icon}</>}
            label={<>{orderStatus[order.status].value}</>}
            size="xsmallicon"
            color={orderStatus[order.status].chipColor as ChipColor}
          />
        </Box>
      )} */}
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="text.disabled">
            {/* {bot.duration} days */}
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <span>{bot.name}</span>
              {/* <MuiChip
              label={order.direction === '0' ? 'B' : 'S'}
              color={order.direction === '0' ? 'infolight' : 'purplelight'}
              size="singleLetter"
              variant="filled"
              sx={{ ml: 1 }}
            /> */}
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right', lineHeight: 0 }}>
          <MuiChip
            label={bot.status}
            size="xsmall"
            color={bot.status === 'Active' ? 'successlight' : 'errorlight'}
            sx={{ maxWidth: '100px' }}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {/* <span>{bot.name}</span> */}
            {/* <MuiChip
              label={order.direction === '0' ? 'B' : 'S'}
              color={order.direction === '0' ? 'infolight' : 'purplelight'}
              size="singleLetter"
              variant="filled"
              sx={{ ml: 1 }}
            /> */}
          </Typography>
        </Box>
        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right' }}>
          <Typography variant="body2" color="info.main">
            {bot.broker_name}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <MuiChip label={bot.sub_id} size="xsmall" color={'infolight'} />
        </Box>
        <Box
          sx={{
            flex: 'initial',
            pl: 2,
            textAlign: 'right',
            columnGap: 1,
            display: 'flex',
          }}
        >
          <MuiButton
            variant="contained"
            color="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleViewBotClick(bot);
            }}
          >
            Info
          </MuiButton>
          <MuiButton
            variant="outlined"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleActionClick(
                bot.status === 'Active' ? 'Paused' : 'Active',
                bot.sub_id,
              );
            }}
            color={bot.status === 'Active' ? 'error' : 'success'}
          >
            {bot.status === 'Active' ? 'Stop' : 'Start'}
          </MuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default WebHookBotItem;
