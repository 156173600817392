import { MutationKey, QueryCache, QueryClient, QueryKey } from 'react-query';
import { get, set, del } from 'idb-keyval';
import {
  PersistedClient,
  Persistor,
  persistQueryClient,
} from 'react-query/persistQueryClient-experimental';
// import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import throttle from 'lodash/throttle';

const DEFAULT_QUERY_STALE_TIME = 1 * 1000; // Set 1 second for temporary reason
const DEFAULT_QUERY_CACHE_TIME = 24 * 60 * 60 * 1000;

export const queryNoCachList = [];

export const queryInvalidationMap = {
  PostBotAction: [
    { key: 'GetPortfolio' },
    { key: 'GetAllBots' },
    {
      key: (mutationKey: MutationKey) => [
        'GetBotDetails',
        mutationKey[1],
        mutationKey[2],
      ],
    },
  ],
  DeleteBot: [{ key: 'GetPortfolio' }, { key: 'GetAllBots' }],
  PostUsersBotAction: [{ key: 'GetAllBots' }],
  PostBotSignal: [
    {
      key: (mutationKey: MutationKey) => [
        'GetBotDetails',
        mutationKey[1],
        mutationKey[2],
      ],
    },
    {
      key: (mutationKey: MutationKey) => ['GetBotSignals', mutationKey[1]],
    },
    { key: 'GetBotDetails' },
  ],

  EditProfile: [{ key: 'GetProfile' }],
  CreateBroker: [{ key: 'GetBrokerForm' }],
  EditBroker: [{ key: 'GetBrokerForm' }],
  BrokerLogout: [{ key: 'GetBrokerForm' }],
  DeleteBroker: [{ key: 'GetBrokerForm' }],
  SubscribePlan: [{ key: 'GetProfile' }],
  SubscribePlanStatus: [{ key: 'GetProfile' }, { key: 'GetInvoices' }],
  CancelPlan: [{ key: 'GetProfile' }],
  SaveUserBot: [{ key: 'GetUserBot' }, { key: 'GetAllBots' }],
  EditUserBot: [{ key: 'GetUserBot' }, { key: 'GetAllBots' }],
  ReadNotifications: [{ key: 'GetNotifications' }],
  ReadAllNotifications: [{ key: 'GetNotifications' }],
  PutCancelBacktestQueue: [{ key: 'GetBacktestQueue' }],
  EditNotificationSettings: [{ key: 'GetNotificationSettings' }],
  ActivateFreeTrial: [{ key: 'GetProfile' }],
  PostPortfolioManualBot: [{ key: 'GetPortfolioBotV2' }],
  PostBotExitAllAndStop: [{ key: 'GetBotTodaysPositions' }],
  PutWebHookAction: [{ key: 'webhooksignalbot' }],
  PostClearError: [
    {
      key: (mutationKey: MutationKey) => [
        'GetPortfolioBotDetail',
        mutationKey[1],
      ],
    },
    { key: 'GetPortfolio' },
  ],
  PostBotExitStrategyPositions: [
    {
      key: (mutationKey: MutationKey) => [
        'GetBotTodaysPositions',
        mutationKey[1],
      ],
    },
  ],
  SetReferral: [
    {
      key: 'GetProfile',
    },
  ],
  WalletAction: [{ key: 'GetProfile' }],
  WalletPaymentStatus: [{ key: 'GetProfile' }],
  PostCreditTransfer: [{ key: 'GetProfile' }],
  PostTicketData: [{ key: 'GetTicketsData' }],
  PostTicketMessageDatas: [{ key: 'GetTicketMessageData' }],
  PostPortfolioShare: [{ key: 'GetPortfolioShare' }],
};

export const queryCacheClear = [
  // 'Authenticate',
  // 'AuthenticateWithGoogle',
  'AuthenticateDefault',
];

export const invalidateQueryCache = (mutationKey: MutationKey) => {
  const key =
    typeof mutationKey === 'string' ? mutationKey : (mutationKey[0] as string);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const queriesKeyForInvalidation = queryInvalidationMap[key];
  if (queriesKeyForInvalidation) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    queriesKeyForInvalidation.map(queryAction => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { key } = queryAction.key;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const queryKey: QueryKey =
        typeof queryAction.key === 'function'
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            queryAction.key(mutationKey)
          : key;

      void queryClient.invalidateQueries(queryKey, {
        refetchActive: true,
        refetchInactive: false,
      });
    });
  }
};

export const checkForClearCache = (key: string) => {
  if (queryCacheClear.indexOf(key) > -1) {
    clearQueryCache();
  }
};

export const clearQueryCache = () => {
  const queryCache = queryClient.getQueryCache();
  queryCache.clear();
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // retry: false,
      retry: 2, //1 time failed and try again 2 times more=total try 3 times
      staleTime: DEFAULT_QUERY_STALE_TIME,
      cacheTime: DEFAULT_QUERY_CACHE_TIME,
      // suspense: true,
    },
    mutations: {
      onSuccess: function () {
        const mutationKey = this.mutationKey;
        if (mutationKey) {
          const key =
            typeof mutationKey === 'string'
              ? mutationKey
              : (mutationKey[0] as string);

          invalidateQueryCache(mutationKey);
          checkForClearCache(key);
        }
      },
    },
  },
});

// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
// });

const PERSIST_THROTTLE_TIME = 1000;

export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: throttle(async (client: PersistedClient) => {
      await set(idbValidKey, client);
    }, PERSIST_THROTTLE_TIME),
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persistor;
}

persistQueryClient({
  queryClient,
  persistor: createIDBPersister(),
}).catch(error => {
  //TODO: Send persist storage did not work error.
  // eslint-disable-next-line no-console
  console.error(error);
});

export const queryCache = new QueryCache({
  onError: error => {
    return error;
  },
  onSuccess: data => data,
});
