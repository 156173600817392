import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { trimChars } from 'utils/GenericFunctions';
import { getDefaultBackPathForPage } from './BackPaths';
import { BreadcrumbData, BreadcrumbInfo, GeneratorPages, PAGES } from './types';

export class BreadcrumbManager {
  private pageBreadcrumbs: BreadcrumbInfo[] = [];
  private breadcrumbPage: PAGES = PAGES.PORTFOLIO;

  public setBackPathForPage = (page: PAGES, link: string, label: string) => {
    if (
      page === PAGES.BOTS ||
      page === PAGES.MARKETPLACE ||
      page === PAGES.PORTFOLIO ||
      page === PAGES.MENU ||
      page === PAGES.TEMPLATES ||
      page === PAGES.TRADERS
    ) {
      this.pageBreadcrumbs = [];
    }

    // Check if exist then remove breadcrumb record
    const index = this.pageBreadcrumbs.findIndex(x => x.link === link);
    if (index >= 0) {
      this.pageBreadcrumbs.splice(index, 1);
    }

    this.breadcrumbPage = page;
    this.pageBreadcrumbs.push({
      page: page,
      link: link,
      label: label,
    });
  };

  public push = (page: PAGES, link: string, label: string) => {
    this.setBackPathForPage(page, link, label);
  };

  public getBreadcrumpPath = (page: GeneratorPages, data: BreadcrumbData) => {
    // return this.pageBreadcrumbs;

    // return page === this.breadcrumbPage
    //   ? this.pageBreadcrumbs
    //   : getDefaultBackPathForPage(page, data);

    // return this.breadcrumbPage && this.breadcrumbPage.length
    //   ? this.pageBreadcrumbs
    //   : getDefaultBackPathForPage(page, data);

    if (!this.pageBreadcrumbs || !this.pageBreadcrumbs.length) {
      this.pageBreadcrumbs = getDefaultBackPathForPage(page, data);
      this.breadcrumbPage = page;
    }
    return this.pageBreadcrumbs;
  };

  public clickPageBreadcrumbs = (removeFromIndex: number) => {
    if (removeFromIndex !== undefined && this.pageBreadcrumbs.length > 0) {
      this.breadcrumbPage = this.pageBreadcrumbs[removeFromIndex].page;
      this.pageBreadcrumbs.splice(removeFromIndex);
    }
  };
  public resetBreadcrumpPaths = () => {
    this.pageBreadcrumbs = [];
    this.breadcrumbPage = PAGES.PORTFOLIO;
  };

  public goBackPageBreadcrumb = (link: string) => {
    link = trimChars(link.toLowerCase(), '/');
    const removeFromIndex = this.pageBreadcrumbs.findIndex(
      x => trimChars(x.link.toLowerCase(), '/') === link,
    );
    if (removeFromIndex >= 0) {
      this.breadcrumbPage = this.pageBreadcrumbs[removeFromIndex].page;
      this.pageBreadcrumbs.splice(removeFromIndex);

      return true;
    }
    return false;
  };
  public isPageUrlExist = (link: string) => {
    const index = this.pageBreadcrumbs.findIndex(
      x => x.link.toLowerCase() === link,
    );
    return index >= 0;
  };
  public isPageExist = (page: PAGES) => {
    const index = this.pageBreadcrumbs.findIndex(x => x.page === page);
    return index >= 0;
  };
}

const breadcrumbManager = new BreadcrumbManager();

export function useBreadcrumbManager() {
  const history = useHistory();

  const breadcrumbPush = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    <T extends unknown>(
      link: string,
      page: PAGES,
      backLink: string,
      label: string,
      data?: T,
    ) => {
      const isBackLinkExecute = breadcrumbManager.goBackPageBreadcrumb(link);
      if (!isBackLinkExecute) breadcrumbManager.push(page, backLink, label);

      // Ensure `history.push` is typed correctly for `data`
      history.push(link, data);
    },
    [history],
  );

  const clickPageBreadcrumb = useCallback(
    (removeFromIndex: number, link: string) => {
      breadcrumbManager.clickPageBreadcrumbs(removeFromIndex);
      history.push(link);
    },
    [history],
  );

  const goBackPathPage = useCallback(
    (link: string) => {
      breadcrumbManager.goBackPageBreadcrumb(link);
      history.push(link);
    },
    [history],
  );

  return {
    push: breadcrumbPush,
    clickBreadcrumb: clickPageBreadcrumb,
    getBreadcrumpPath: breadcrumbManager.getBreadcrumpPath,
    resetBreadcrumpPaths: breadcrumbManager.resetBreadcrumpPaths,
    goBackPathPage: goBackPathPage,
    isPageUrlExist: breadcrumbManager.isPageUrlExist,
    isPageExist: breadcrumbManager.isPageExist,
  };
}
