import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PostTOTPVarification } from 'services/apiServices';
import {
  Errors,
  TOTPVarificationParams,
  TOTPVarificationResult,
} from 'types/ApiServicesTypes';

export const usePostTOTPVarification = (id: number) => {
  const { showAlert } = useContext(ToastContext);
  const breadcrumb = useBreadcrumbManager();
  const mutation = useMutation<
    TOTPVarificationResult,
    Errors,
    Pick<TOTPVarificationParams, 'totp'>
  >('PostTOTPVarification', value =>
    PostTOTPVarification(value, id)
      .then(res => {
        if (res.verified) {
          breadcrumb.goBackPathPage('/broker');
        } else if (!res.verified || res.message)
          showAlert!('Your TOTP is invalid', 'error');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
