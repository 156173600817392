import { Fragment, useState, useEffect, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MuiTable from 'app/design/uiComponents/MuiTable';
import {
  CommonTableProps,
  DetailsRowRendererType,
  TableRow as TableRowType,
} from './type';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
const TableDataRow = memo(function TableDataRow<T>({
  row,
  handleClick,
  isSelected,
}: {
  row: TableRowType<T>;
  handleClick?: () => void | undefined;
  isSelected?: boolean;
  index: number;
}) {
  return (
    <TableRow
      sx={{
        cursor:
          row.handleClick !== undefined || row.link || handleClick !== undefined
            ? 'pointer'
            : 'default',
      }}
      {...(row.handleClick && { onClick: row.handleClick })}
      {...(handleClick && { onClick: handleClick })}
      {...(row.link && { component: RouterLink, to: row.link })}
      selected={isSelected}
      className={row.visibleOnHover ? 'hoverable-row' : ''}
    >
      {row.rowCellsData.map((item, j) => (
        <TableCell
          key={j}
          align={item.align || 'left'}
          sx={{
            position: 'relative',
            ...(row.noBorder && { border: 0 }),
            pt: row.pt || row.pt === 0 ? row.pt : 2,
            pb: row.pb || row.pb === 0 ? row.pb : 2,
            display: item.hide ? 'none' : undefined,
            backgroundColor: (item.backgroudColor as string) || '',
          }}
          {...(item.colSpan && { colSpan: item.colSpan })}
          className={row.visibleOnHover ? 'hoverable-cell' : ''}
        >
          <Typography
            variant={item.textVariant || 'body2'}
            color={item.color || 'text.primary'}
            fontWeight={item.fontWeight || 400}
          >
            {item.content}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
});

function TableRowWithDetailRowRenderer<T>({
  row,
  DetailsRowRenderer,
  colSpan,
  handleChartRowClick,
  rowIndex,
  clickIndex,
  setClickIndex,
  index,
  handleOnClickInsideRow,
}: {
  row: TableRowType<T>;
  DetailsRowRenderer: DetailsRowRendererType<T>;
  colSpan: number;
  handleChartRowClick?: (positionData: T) => void;
  rowIndex: number;
  clickIndex: number;
  setClickIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  handleOnClickInsideRow?: (positionData: T) => void;
}) {
  const status = clickIndex === rowIndex;
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);

  useEffect(() => {
    setStatusToggle(status);
  }, [status]);

  const handleTableRowClick = () => {
    if (handleChartRowClick && row && row.rowData) {
      handleChartRowClick(row.rowData);
      setClickIndex(rowIndex);
    } else {
      toggleOpen();
    }
  };

  return (
    <>
      <TableDataRow
        row={row}
        handleClick={handleTableRowClick}
        isSelected={statusToggle}
        index={index}
      />
      <TableRow>
        <TableCell sx={{ p: '0 !important' }} colSpan={colSpan}>
          <Collapse in={open}>
            <DetailsRowRenderer
              rowData={row.rowData}
              handleClose={handleClose}
              handleClick={handleOnClickInsideRow}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CommonTable<T>({
  containerStyle,
  headerSticky,
  rows,
  columns,
  whichTable,
  DetailsRowRenderer,
  handleOnClickInsideRow,
  handleChartRowClick,
  page,
  sx,
}: CommonTableProps<T>) {
  const sortColumnMap = {
    portfolioTable: 'name',
    alltodaysposition: 'trading_symbol',
    positions: 'entry_time',
  };

  const defaultSortColumn =
    whichTable && sortColumnMap[whichTable]
      ? (sortColumnMap[whichTable] as string)
      : 'order_date';
  const defaultSortDirection: 'asc' | 'desc' =
    whichTable !== 'portfolioTable' ? 'desc' : 'asc';
  const theme = useTheme();
  const [clickIndex, setClickIndex] = useState(-1);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
    defaultSortDirection,
  );

  useEffect(() => {
    setClickIndex(-1);
  }, [page]);

  const handleSort = (column = 'trading_symbol') => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };
  // eslint-disable-next-line no-console
  const sortedRows = rows.slice().sort((a, b) => {
    if (!sortColumn) return 0;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let aValue = a.rowData[sortColumn];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let bValue = b.rowData[sortColumn];

    if (sortColumn.includes('.')) {
      const sortKey = sortColumn.split('.');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      aValue = a.rowData[sortKey[0]][sortKey[1]];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      bValue = b.rowData[sortKey[0]][sortKey[1]];
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
  });

  return (
    <TableContainer
      className="scrollThis"
      {...(containerStyle && { sx: containerStyle })}
    >
      <MuiTable stickyHeader={headerSticky || false} {...(sx && { sx: sx })}>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                key={i}
                align={column.align || 'left'}
                sx={{
                  ...(column.noWrap && { whiteSpace: 'nowrap' }),
                  display: column.hide ? 'none' : undefined,
                  cursor: 'pointer',
                }}
                onClick={() => handleSort(String(column.key))}
              >
                <Typography variant="body3">{column.text}</Typography>
                {column.key &&
                  column.key === sortColumn &&
                  (sortDirection === 'asc' ? (
                    <ArrowDownward sx={{ color: theme.palette.grey[400] }} />
                  ) : (
                    <ArrowUpward sx={{ color: theme.palette.grey[400] }} />
                  ))}
                {/* {column.key && (
                  <Tooltip title="Click to Sort" placement="top">
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <Sort fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )} */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, i) => (
            <Fragment key={i}>
              {DetailsRowRenderer ? (
                <TableRowWithDetailRowRenderer
                  row={row}
                  DetailsRowRenderer={DetailsRowRenderer}
                  colSpan={columns.length}
                  handleChartRowClick={handleChartRowClick}
                  rowIndex={i}
                  setClickIndex={setClickIndex}
                  clickIndex={clickIndex}
                  index={i}
                  handleOnClickInsideRow={handleOnClickInsideRow}
                />
              ) : (
                <TableDataRow row={row} index={i} />
              )}
            </Fragment>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default memo(CommonTable) as typeof CommonTable;
