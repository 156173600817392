import { memo, useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Alert,
  Typography,
  Link,
  IconButton,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SbStrategy, UserBot } from './types';
import { useTranslation } from 'react-i18next';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { isUserLoggedIn } from 'utils/auth';
// Services
import usePostUserBotsIsExist from 'services/Bots/usePostUserBotsIsExist';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import OptionsBuilder from 'app/components/Builder/OptionsStrategy/OptionsBuilder';
import { createEmptyOptionBuilder } from 'app/components/Builder/OptionsStrategy/Utils/utils';
import { OptionStrategyFields } from 'app/components/Builder/OptionsStrategy/FieldsData';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { CheckUniqueItemsByPropGeneric } from 'utils/GenericFunctions';
import {
  OptionLegParam,
  OptionSetParam,
} from 'app/components/Builder/OptionsStrategy/types';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import MuiSwitchUnstyled from 'app/design/uiComponents/MuiUnstyledSwitch';
import SignInModal from 'app/components/Auth/SignInModal';

import ShowMoreText from 'react-show-more-text';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import OptionBotDescriptionModal from './OptionBotDescriptionModal';
import { Edit } from '@mui/icons-material';
import { StrategyBuilderTypes, StrategyBuiltWithTypes } from './util';
import HintAlert from 'app/design/speedBot/ComponentItem/Bots/HintAlert';
import { ToastContext } from '../../Context/ToastContext';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OptionStrategyBuilderForm = ({
  userData,
  setUserData,
  isTempalte,
  botBuilderType,
}: {
  isTempalte?: boolean;
  userData: UserBot | null;
  setUserData: (values: UserBot) => void;
  botBuilderType: string | undefined;
}) => {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const isLgUp = useGetMediaQueryUp('lg');
  const history = useHistory();
  const { data: siteInfo } = useGetSiteInfo();
  const [isLoginModal, setIsLoginModal] = useState<boolean>(false);
  const [sbStrategy, setSbStrategy] = useState<SbStrategy>(
    userData && userData.sb_strategy
      ? userData.sb_strategy
      : createEmptyOptionBuilder(OptionStrategyFields),
  );
  const [isValidStrategy, setIsValidStrategy] = useState(false);
  const [isValidForm, setIsValidForm] = useState({
    validate: false,
    show: false,
  });
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [openDescriptionModal, setOpenDescriptoinModal] = useState(false);
  const [description, setDescription] = useState('<p></p>');
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >('');
  const { showAlert } = useContext(ToastContext);
  const handleHideAlert = () => {
    setIsShowAlert(false);
  };

  const handleClose = (val: boolean) => {
    setOpenDescriptoinModal(val);
  };

  const mutationIsExist = usePostUserBotsIsExist();
  const isLoggedIn = isUserLoggedIn();
  const setSbStrategyData = useCallback(
    (data: SbStrategy, isValidSbStrategy: boolean, isValidate: boolean) => {
      setIsValidStrategy(isValidSbStrategy);
      setSbStrategy(data);
      setIsValidForm({ validate: isValidate, show: false });
    },
    [],
  );
  const [errorCount, setErrorCount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const botDetails = sbStrategy;
  const isBuiltWithApp = botDetails
    ? (botDetails.built_with === null ||
        botDetails.built_with === undefined ||
        botDetails.built_with in StrategyBuiltWithTypes) &&
      botDetails.type in StrategyBuilderTypes
    : false;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: userData ? userData.name : '',
          underlyingFrom:
            userData &&
            userData.sb_strategy &&
            userData.sb_strategy.sb_opt_strategy &&
            userData.sb_strategy.sb_opt_strategy['underlying_from'] ===
              'future',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.bot_name'),
            }),
          ),
        })}
        onSubmit={values => {
          if (botBuilderType != 'option') {
            values['exchange'] = botBuilderType?.toUpperCase();
          }
          setIsLoginModal(true);
          setIsValidForm({ ...isValidForm, show: true });
          if (!isBuiltWithApp) {
            showAlert!(
              "Access denied. You can't edit or make changes.",
              'error',
            );
            return;
          }
          if (
            !isValidForm.validate ||
            !sbStrategy.sb_opt_strategy.strategy_sets ||
            !sbStrategy.sb_opt_strategy.strategy_sets.length
          )
            return;

          // Check unique name
          const sets = sbStrategy.sb_opt_strategy
            .strategy_sets as OptionSetParam[];
          const isUniqueSetName = CheckUniqueItemsByPropGeneric(sets, 'name');
          let isUniqueLegName = true;

          sets.forEach(set => {
            const legs = set.legs as OptionLegParam[];
            if (!CheckUniqueItemsByPropGeneric(legs, 'name')) {
              isUniqueLegName = false;
              return;
            }
          });
          if (!isUniqueSetName || !isUniqueLegName) return;

          const isNameChanged =
            !userData || (userData && userData.name !== values.name);

          if (sbStrategy) {
            sbStrategy.type = 'option';
            sbStrategy.built_with = StrategyBuiltWithTypes.option_builder;
          }
          if (sbStrategy && sbStrategy['si-algo-params'])
            delete sbStrategy['si-algo-params'];
          if (sbStrategy && sbStrategy['si_algo_params'])
            delete sbStrategy['si_algo_params'];

          if (!isValidStrategy && !isShowAlert) {
            setIsShowAlert(true);
            return;
          }

          sbStrategy.sb_opt_strategy['underlying_from'] = values.underlyingFrom
            ? 'future'
            : 'spot';

          if (isNameChanged) {
            mutationIsExist.mutate(
              { name: values.name },
              {
                onSuccess: resSuccess => {
                  if (resSuccess.success && isBuiltWithApp)
                    setUserData({
                      ...values,
                      sb_strategy: sbStrategy,
                      description: description,
                    });
                },
              },
            );
          } else {
            setUserData({
              ...values,
              sb_strategy: sbStrategy,
              description: description,
            });
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          handleBlur,
          handleChange,
          touched,
          isValid,
        }) => {
          if (errors.name && errorCount < 3) {
            if (inputRef.current) {
              inputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              const observer = new IntersectionObserver(
                ([entry]) => {
                  if (entry.isIntersecting && inputRef.current) {
                    inputRef.current.focus();
                    observer.disconnect(); // Stop observing once the element is in view
                  }
                },
                {
                  root: null,
                  threshold: 1.0,
                },
              );

              observer.observe(inputRef.current);
            }
            setErrorCount(val => val + 1);
          }
          const nameExistError =
            mutationIsExist.data && mutationIsExist.data.error
              ? mutationIsExist.data.error
              : '';

          return (
            <Box
              className="m-main-spacing"
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <CircularLoader open={mutationIsExist.isLoading} />
              <Box sx={{ mb: 2, flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: { sm: 'column', md: 'column', lg: 'row' },
                  }}
                >
                  <Box flex={1}>
                    <FormControl
                      fullWidth
                      error={
                        Boolean(touched.name && errors.name) ||
                        Boolean(touched.name && nameExistError)
                      }
                      variant="outlined"
                    >
                      <InputLabel htmlFor="label-name">
                        <FormattedMessage id="user_bot_startegy.form.bot_name" />
                      </InputLabel>
                      <OutlinedInput
                        inputRef={inputRef}
                        id="label-name"
                        name="name"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={e => {
                          if (nameExistError) mutationIsExist.reset();
                          handleChange(e);
                        }}
                        label={t('user_bot_startegy.form.bot_name')}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error>{errors.name}</FormHelperText>
                      )}
                      {touched.name && nameExistError && (
                        <FormHelperText error>{nameExistError}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box
                    flex={2}
                    display={'flex'}
                    flexDirection={{ sm: 'column', md: 'row' }}
                    mt={{ sm: 1, md: 1, lg: 0 }}
                  >
                    <Box
                      flex={1}
                      alignSelf={{ lg: 'center' }}
                      mx={{ lg: 2 }}
                      maxWidth={{ lg: '220px' }}
                    >
                      <FeatureFlag
                        features={[FEATURES.IS_BOT_DISCRIPTION]}
                        fallback={<></>}
                      >
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          fullWidth
                          startIcon={<Edit />}
                          onClick={() => setOpenDescriptoinModal(true)}
                          sx={{
                            alignItems: 'center',
                            justifySelf: 'center',
                          }}
                        >
                          {t('excel_file_labels.description')}
                        </MuiButton>
                      </FeatureFlag>
                    </Box>
                    <FeatureFlag
                      features={[FEATURES.IS_SPOT_FUTURES_ATM]}
                      fallback={<></>}
                    >
                      {botBuilderType != 'mcx' && (
                        <Box flex={3} alignSelf={'center'}>
                          <Box
                            display="flex"
                            alignItems={'center'}
                            justifyContent={{
                              sm: 'center',
                              md: 'left',
                              lg: 'left',
                            }}
                            ml={{ sm: 2, lg: 0 }}
                          >
                            <Typography>
                              {isMdUp ? t('user_bot_startegy.form.use') : ''}
                              {t('user_bot_startegy.form.spot_as_atm')}
                            </Typography>
                            <MuiSwitchUnstyled
                              {...{
                                componentsProps: {
                                  input: {
                                    'aria-label': 'Notification switch',
                                  },
                                },
                              }}
                              checked={Boolean(values.underlyingFrom)}
                              onChange={e => {
                                setFieldValue(
                                  'underlyingFrom',
                                  e.target.checked,
                                );
                              }}
                              sx={{ flex: 'inherit', mx: 1 }}
                            />
                            <Typography variant={isLgUp ? 'body1' : 'body2'}>
                              {isMdUp ? t('user_bot_startegy.form.use') : ''}
                              {t('user_bot_startegy.form.futures_as_atm')}
                            </Typography>
                            <CustomTooltip tooltipKey="futures_spot_atm">
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </Box>
                        </Box>
                      )}
                    </FeatureFlag>
                  </Box>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Alert severity="info" icon={false}>
                    <Typography
                      variant="body2"
                      component={'span'}
                      // color="text.primary"
                      fontWeight={400}
                    >
                      <strong style={{ color: 'black' }}>
                        <FormattedMessage id="user_backtesting.note" />
                      </strong>
                      <Box>
                        <ShowMoreText
                          lines={2}
                          more={'Read More'}
                          less="Less"
                          className="show-more"
                          anchorClass="anchor-show-more"
                          expanded={false}
                          truncatedEndingComponent={'...'}
                        >
                          {siteInfo && siteInfo.notes_option_builder ? (
                            <Typography
                              component={'div'}
                              variant={'body2'}
                              sx={{ fontWeight: 400, color: 'black' }}
                              dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __html: siteInfo.notes_option_builder,
                              }}
                            ></Typography>
                          ) : (
                            <ul
                              style={{
                                padding: '0 0.5rem !important',
                                margin: 0,
                                paddingLeft: '1rem',
                                paddingTop: '0.25rem',
                              }}
                            >
                              <li>
                                <FormattedMessage id="user_bot_startegy.option_builder_note1" />
                              </li>
                              <li>
                                <FormattedMessage id="user_bot_startegy.option_builder_note2" />
                              </li>
                              <li>
                                {t('user_bot_startegy.option_builder_note3')}
                                <Link
                                  target="_blank"
                                  href={
                                    siteInfo &&
                                    siteInfo.option_builder_toolkit_link
                                      ? siteInfo.option_builder_toolkit_link
                                      : 'https://help.speedbot.tech/Options-Strategy-Builder-Toolkit'
                                  }
                                >
                                  {t(
                                    'user_bot_startegy.option_builder_note3_click_here',
                                  )}
                                </Link>
                              </li>
                              <li>
                                {t('user_bot_startegy.option_builder_note4')}
                                <Link
                                  target="_blank"
                                  href={
                                    siteInfo && siteInfo.help_link
                                      ? siteInfo.help_link
                                      : 'https://speedbot.tech/helpdesk'
                                  }
                                >
                                  {t(
                                    'user_bot_startegy.option_builder_note4_click_here',
                                  )}
                                </Link>
                              </li>
                              <li>
                                <FormattedMessage id="user_bot_startegy.option_builder_note5" />
                              </li>
                            </ul>
                          )}
                        </ShowMoreText>
                      </Box>
                    </Typography>
                  </Alert>
                </Box>
                {isTempalte && (
                  <Box mt={2}>
                    <FeatureFlag
                      features={[FEATURES.IS_NOTE_FOR_TEMPLATE_DETAIL]}
                      fallback={<></>}
                    >
                      <HintAlert />
                    </FeatureFlag>
                  </Box>
                )}
                <Box>
                  <OptionsBuilder
                    setValidationMessage={setValidationMessage}
                    optionBuilderData={sbStrategy}
                    onChangeBuilderData={setSbStrategyData}
                    isReadOnly={false}
                    isShowOptionSetValidation={isValidForm.show}
                    botBuilderType={botBuilderType}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 0,
                  textAlign: 'center',
                  display: { xs: 'block', md: 'flex' },
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                }}
              >
                {
                  <MuiButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={!isMdUp}
                    sx={{ mb: { xs: 2, md: 0 } }}
                    disabled={!isValid || !isValidForm}
                  >
                    {isLoggedIn ? (
                      <FormattedMessage id="buttons.save_and_continue" />
                    ) : (
                      <FormattedMessage id="buttons.login_and_continue" />
                    )}
                  </MuiButton>
                }
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth={!isMdUp}
                  onClick={() => history.goBack()}
                  sx={{
                    mr: { xs: 0, md: 2 },
                  }}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Box>

              <SnakbarMessageModal
                open={isShowAlert}
                handleClose={() => handleHideAlert()}
                body={
                  validationMessage == ''
                    ? t('user_bot_startegy.entered_invalid_parameters')
                    : t('user_bot_startegy.entered_invalid_parameters')
                }
                footer={
                  <>
                    <Box display="flex" justifyContent="end">
                      <Box>
                        <MuiButton
                          varint="contained"
                          color="secondary"
                          sx={{ mr: 1 }}
                          onClick={handleHideAlert}
                        >
                          <FormattedMessage id="buttons.cancel" />
                        </MuiButton>
                      </Box>
                      <Box>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          <FormattedMessage id="buttons.continue_anyway" />
                        </MuiButton>
                      </Box>
                    </Box>
                  </>
                }
              />
            </Box>
          );
        }}
      </Formik>
      {!isLoggedIn && (
        <SignInModal
          handleClose={() => setIsLoginModal(false)}
          open={isLoginModal}
        />
      )}
      <OptionBotDescriptionModal
        open={openDescriptionModal}
        handleClose={handleClose}
        description={description}
        setDescription={setDescription}
      />
    </>
  );
};
export default memo(OptionStrategyBuilderForm);
