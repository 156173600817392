import { Box, Divider, useTheme } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import WebHookCustomTables from 'app/components/WebHookSignalComponent/WebHookCustomTables';
import useGetWebHookSignalBot from 'services/WebHookSignals/useGetWebhookSignalBot';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import ManualBotModal from 'app/components/Portfolio/ManualBotModal';
import { TWebHookBot } from 'types/ApiServicesTypes';
import WebHookBotModal from 'app/components/WebHookSignalComponent/WebHookBotModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import WebHookBotItem from 'app/components/WebHookSignalComponent/WebHookBotItem';
import usePutWebhookSignalAction from 'services/WebHookSignals/usePutWebhookSignalAction';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

const WebHookSignals = () => {
  const theme = useTheme();
  const { data: signalBotData, isLoading } = useGetWebHookSignalBot();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBotModal, setOpenBotModal] = useState<boolean>(false);
  const [botItem, setBotItem] = useState<TWebHookBot>();
  const handleBotModalClose = () => {
    setOpenBotModal(false);
  };
  const isMdUp = useGetMediaQueryUp('md');
  const handleViewBotClick = (bot: TWebHookBot) => {
    setOpenBotModal(true);
    setBotItem(bot);
  };
  const mutation = usePutWebhookSignalAction();
  const handleActionClick = (status: string, sub_id: number) => {
    mutation.mutate({ status, sub_id });
  };
  const breadcrumb = useBreadcrumbManager();
  const handleContainerClick = (sub_id: number) => {
    const botItem =
      signalBotData &&
      signalBotData.bots.filter(item => item.sub_id === sub_id);
    breadcrumb.push(
      `/signal/details/${sub_id}`,
      PAGES.SIGNALS,
      `/signal`,
      'Signals',
      botItem,
    );
  };
  return (
    <Box>
      <CircularLoader open={isLoading} zIndex={1302} />
      <Helmet>
        <title>Webhook Signals</title>
        <meta name="description" content="TradeIdeas Page" />
      </Helmet>
      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader variant="market" title={'WebHook Signals'} />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      {signalBotData && signalBotData.bots && (
        <Box>
          <Box
            sx={{ my: 1, ml: 'auto', textAlign: 'right' }}
            className="p-main-spacing"
          >
            <MuiButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
            >
              Create Bot
            </MuiButton>
          </Box>
          {isMdUp ? (
            <WebHookCustomTables
              data={signalBotData.bots}
              handleActionClick={handleActionClick}
              handleViewBotClick={handleViewBotClick}
            />
          ) : (
            signalBotData &&
            signalBotData.bots.map((bot, i) => (
              <Box key={i}>
                <WebHookBotItem
                  bot={bot}
                  handleContainerClick={handleContainerClick}
                  handleViewBotClick={handleViewBotClick}
                  handleActionClick={handleActionClick}
                />
                {signalBotData.bots.length - 1 !== i && (
                  <Divider className="m-main-spacing" />
                )}
              </Box>
            ))
          )}
        </Box>
      )}
      <ManualBotModal
        source="signal"
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <WebHookBotModal
        open={openBotModal}
        handleClose={handleBotModalClose}
        data={botItem ? botItem : ({} as TWebHookBot)}
      />
    </Box>
  );
};

export default WebHookSignals;
