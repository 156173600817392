import CommonTable from 'app/components/Common/CommonTable';
import createTradeTableColumnsRows from './utils';
import { TradesType } from '../types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Box } from '@mui/material';

type TradeTableProps = {
  tradeData: Array<TradesType> | undefined;
  handleTradeRowClick?: (trade: TradesType) => void;
  loadmore: () => void;
  isLoading: boolean;
};

const TradeTable = ({
  tradeData,
  handleTradeRowClick,
  loadmore,
  isLoading,
}: TradeTableProps) => {
  const { rows, columns } = createTradeTableColumnsRows(
    tradeData,
    handleTradeRowClick,
  );
  return (
    <Box position={'relative'}>
      <CommonTable
        containerStyle={{
          height: '80vh',
          scrollbarWidth: 'none',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
        }}
        rows={rows}
        headerSticky={true}
        columns={columns}
        handleChartRowClick={handleTradeRowClick}
      />
      <MuiButton
        variant="contained"
        color="secondary"
        sx={{
          marginInline: 'auto',
          display: 'flex',
          // position: 'absolute',
          // bottom: 0,
          // left: 0,
          // transform:"translate(50%,-50%)"
        }}
        onClick={loadmore}
        disabled={isLoading}
      >
        Load More
      </MuiButton>
    </Box>
  );
};

export default TradeTable;
