import {
  Box,
  // Card,
  // CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import { formatNumber } from 'utils/GenericFunctions';
import { GetProfileResult } from 'types/ApiServicesTypes';
import { PAGES } from '../Common/Breadcrumb/types';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import MuiButton from 'app/design/uiComponents/MuiButton';
import ShareIcons from '../Common/ShareIcons';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useTranslation } from 'react-i18next';
// import useGetBrokerForm from 'services/Menu/useGetBrokerForm';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useState } from 'react';
import { ContentCopy, LibraryAddCheckOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

function UserDetailCard({
  profileData,
}: //   setCopied,
{
  profileData: GetProfileResult;
}) {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isMaxUp = useGetMediaQueryUp('max');
  // const { data: brokerImage } = useGetBrokerForm();
  const theme = useTheme();
  const [, setCopied] = useState<boolean>(false);
  const [ticked, setTicked] = useState<boolean>(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTicked(true);
        setTimeout(() => setTicked(false), 600);
      })
      .catch(err => {
        alert(`Error copying content to clipboard: ${err}`);
      });
  };

  return (
    <Grid container spacing={2}>
      {/* LG: 4 cards in a row */}
      <Grid item xl={isMaxUp ? 3 : 6} lg={6} md={6} sm={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            height: '100%',
            borderRadius: '1em',
            backgroundColor: theme.palette.grey[100],
            justifyContent: 'space-between',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: theme.palette.custom.boxShadow,
            },
          }}
        >
          <Box>
            <Typography variant="h6">Integrate Your Broker Account</Typography>
            <Typography
              variant="caption"
              sx={{
                textAlign: 'justify',
                color: theme.palette.primary.light,
              }}
            >
              Explore our supported brokers and seamlessly link your brokerage
              account to SpeedBot, unlocking a world of automated trading
              opportunities. Start trading smarter today! 🚀
            </Typography>
          </Box>
          <Box>
            <MuiButton
              sx={{
                marginTop: '20px',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[600],
                '&:hover': {
                  backgroundColor: theme.palette.common.black,
                },
              }}
              variant="contained"
              fullWidth
              onClick={() =>
                breadcrumb.push('/brokers/new', PAGES.BROKER, `/home`, 'Home')
              }
            >
              Integrate Broker
            </MuiButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={isMaxUp ? 3 : 6} lg={6} md={6} sm={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            justifyContent: 'space-between',
            height: '100%',
            borderRadius: '1em',
            backgroundColor: theme.palette.grey[100],
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box height="100%">
            <Typography variant="h6" color="primary">
              Credit Balance
            </Typography>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <AccountBalanceWalletTwoToneIcon sx={{ fontSize: '50px' }} />
              <Typography variant="subtitle1" color="textSecondary">
                {formatNumber(Number(profileData?.wallet_balance))}
              </Typography>
            </Box>
          </Box>
          <Box>
            <MuiButton
              sx={{
                marginTop: '20px',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[600],
                '&:hover': {
                  backgroundColor: theme.palette.common.black,
                },
              }}
              variant="contained"
              fullWidth
              onClick={() =>
                breadcrumb.push('/menu/wallet', PAGES.WALLET, `/home`, 'Home')
              }
            >
              Add More Credits
            </MuiButton>
          </Box>
        </Box>
      </Grid>

      <Grid item xl={isMaxUp ? 3 : 6} lg={6} md={6} sm={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            height: '100%',
            borderRadius: '1em',
            backgroundColor: theme.palette.grey[100],
            justifyContent: 'space-between',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Typography variant="h6" color={theme.palette.common.black}>
            Current Plan
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignSelf={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography color={'text.'} variant="h5" fontWeight="bold">
              {profileData?.user_plan?.plan.plan_name}
            </Typography>
            <MuiChip sx={{ mt: 1 }} label={'Active'} color={'purplelight'} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <MuiButton
              onClick={() =>
                breadcrumb.push(
                  '/menu/plan-details',
                  PAGES.PLAN_DETAIL,
                  `/home`,
                  'Home',
                )
              }
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: theme.palette.grey[600],
                color: theme.palette.common.white,
                '&:hover': {
                  backgroundColor: theme.palette.common.black,
                },
              }}
            >
              See Details
            </MuiButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={isMaxUp ? 3 : 6} lg={6} md={6} sm={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            justifyContent: 'space-between',
            height: '100%',
            borderRadius: '1em',
            backgroundColor: theme.palette.grey[100],
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box>
            <Typography variant="h6">Refer and Earn</Typography>
            <Typography variant="subtitle1">
              For every 100 credits they buy, you get 25 credits each time!
            </Typography>
          </Box>
          {/* <Box>
  <MuiButton variant="contained">Refer Now</MuiButton>
</Box> */}
          {
            <Box
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderRadius: '1em',
                display: 'flex',
                justifyContent: ['block'],
                flexDirection: ['column'],

                padding: 2,
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  borderRadius: '0.5rem',

                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <Box
                    sx={{
                      // backgroundColor: '#e0e0e0',
                      padding: 2,
                      paddingBottom: 0,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={[24]}
                      sx={{
                        color: theme.palette.text.primary,
                        fontWeight: '400',
                        fontSize: [18],
                      }}
                    >
                      {profileData?.referral_code}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    sx={{
                      borderRadius: '0.5rem',
                      border: `1px solid ${theme.palette.action.disabledBackground}`,
                      backgroundColor: theme.palette.common.white,
                      height: '2.5rem',
                      width: '2.5rem',
                    }}
                    onClick={() => {
                      copyToClipboard(
                        `${window.location.origin}/signup/start?referral=${profileData?.referral_code}`,
                      );
                      setCopied(true);
                    }}
                  >
                    {!ticked ? (
                      <ContentCopy sx={{ fontSize: [18] }} />
                    ) : (
                      <LibraryAddCheckOutlined
                        sx={{ fontSize: [18] }}
                        color="success"
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  flexDirection: 'column',
                  marginTop: 2,
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    display: ['none'],
                    textAlign: 'end',
                  }}
                >
                  <FormattedMessage id="referral_page.share_via" /> :
                </Typography>
                <ShareIcons
                  style={{ backgroundColor: theme.palette.common.white }}
                  title={t('referral_page.share_note')}
                  variant="medium"
                  referralURL={`${window.location.origin}/signup/start?referral=${profileData?.referral_code}`}
                  whichIconYouWant={[
                    'twitter',
                    'facebook',
                    'whatsapp',
                    'email',
                    'telegram',
                  ]}
                />
              </Box>
            </Box>
          }
        </Box>
      </Grid>
    </Grid>
  );
}

export default UserDetailCard;
