import { useQuery } from 'react-query';
import { GetWebhookSignalDetails } from 'services/apiServices';
import { Errors, TWebHookSignalResult } from 'types/ApiServicesTypes';

export default function useGetWebHookSignalDetails(botId: number) {
  const objQuery = useQuery<TWebHookSignalResult, Errors>(
    ['webhooksignaldetail', botId],
    () => GetWebhookSignalDetails(botId),
  );
  return objQuery;
}
