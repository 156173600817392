import { Box, Divider, Typography, useTheme } from '@mui/material';
import TradeFilter from 'app/components/TradeIdeas/TradeFilter';
import TradeTable from 'app/components/TradeIdeas/TradeTable';
import PageHeader from 'app/design/speedBot/PageHeader';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import usePostTradeIdeasData from 'services/TradeIdeas/useGetTradeIdeasData';
import { formatDateString, getQueryParams } from 'utils/GenericFunctions';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { TradesType } from 'app/components/TradeIdeas/types';
import TradeRowDetailsModal from './TradeRowDetailsModal';

const TradeIdeas = () => {
  const { paginatedTrades, loadMore, isLoading, mutate } =
    usePostTradeIdeasData();
  const { symbol, view } = getQueryParams(['symbol', 'view']);
  const [tradeData, setTradeData] = useState<TradesType | null>(null);
  const [open, setOpen] = useState(false);

  const handleFilter = (filter: Record<string, string>) => {
    mutate({
      symbol: symbol || 'NIFTY 50',
      view: view || 'bullish',
      ...filter,
    });
  };
  useEffect(() => {
    mutate({ symbol: symbol || 'NIFTY 50', view: view || 'bullish' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTradeRowClick = (trade: TradesType) => {
    setTradeData(trade);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  return (
    <Box>
      <CircularLoader open={false} zIndex={1302} />
      <Helmet>
        <title>Trade Ideas</title>
        <meta name="description" content="TradeIdeas Page" />
      </Helmet>
      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader
          variant="inactive"
          buttonText={'Trade Ideas'}
          title={'Trade Table'}
          buttonHandleClick={`/trade-options`}
          breadcrumb={<Breadcrumbs page={PAGES.TRADE_OPTIONS} data={{}} />}
          rightElement={
            paginatedTrades.length ? (
              <Typography variant="body2">
                This trade ideas was generated on{' '}
                {formatDateString(
                  paginatedTrades[0].candle_time,
                  'DD MMM YYYY HH:mm',
                )}
              </Typography>
            ) : (
              <></>
            )
          }
        />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      <TradeFilter
        handleFilter={handleFilter}
        defaultValue={{ symbol: symbol || 'NIFTY 50', view: view || 'bullish' }}
      />
      <TradeTable
        tradeData={paginatedTrades}
        handleTradeRowClick={handleClickTradeRowClick}
        loadmore={loadMore}
        isLoading={isLoading}
      />

      {open && (
        <TradeRowDetailsModal
          open={open}
          handleClose={handleClose}
          tradeData={tradeData}
        />
      )}
    </Box>
  );
};

export default TradeIdeas;
