import { NoBreadcrumbData, PAGES } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const webhookSignals = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.SIGNAL_DETAILS,
      link: `/signal`,
      label: 'Signals',
    },
  ];
};
