import { TradesType } from 'app/components/TradeIdeas/types';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { PostTradeIdeasData } from 'services/apiServices';
import { GetTradeIdeasResult } from 'types/ApiServicesTypes';

export type TradeIdeasFilterParam = Record<string, string>;
export default function usePostTradeIdeasData() {
  const [allTrades, setAllTrades] = useState<TradesType[]>([]); // Stores all trades fetched from the API
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const itemsPerPage = 35; // Number of items to show per page
  const scroll = document.getElementsByClassName('scrollThis');
  const objQuery = useMutation<
    GetTradeIdeasResult,
    Error,
    TradeIdeasFilterParam
  >('PostTradeIdeas', values =>
    PostTradeIdeasData(values).then(res => {
      setCurrentPage(1);
      scroll[0].scroll({ top: 0, behavior: 'smooth' });

      setAllTrades(res.trades); // Cache all trades on the first call
      return res;
    }),
  );

  // Derived state for paginated trades
  const paginatedTrades = allTrades.slice(0, currentPage * itemsPerPage);

  const loadMore = () => {
    if (currentPage * itemsPerPage < allTrades.length) {
      setCurrentPage(prev => prev + 1);
    }
    // scroll[0].scroll(0,100);
  };

  const resetPagination = () => {
    setCurrentPage(1);
  };

  return {
    ...objQuery,
    paginatedTrades,
    loadMore,
    resetPagination,
    currentPage,
  };
}
