import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type TOTPVarificationFormModalPropsType = {
  open: boolean;
  handleClose: () => void;
  handleSubmitData: ({ totp: string }) => void;
};

const TOTPVarificationFormModal = ({
  open,
  handleClose,
  handleSubmitData,
}: TOTPVarificationFormModalPropsType) => {
  const { t } = useTranslation();
  const [showOtp, setShowOtp] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Delete',
    ];
    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('Text');
    if (!/^\d+$/.test(pastedData)) {
      e.preventDefault();
    }
  };
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={() => handleClose()}
      body={
        <Formik
          enableReinitialize
          initialValues={{
            totp: '',
          }}
          validationSchema={Yup.object().shape({
            totp: Yup.string()
              .length(6, t('menu.broker.totp_length', { length: '6' }))
              .required(
                t('menu.required', {
                  type: t('menu.broker.totp'),
                }),
              ),
          })}
          onSubmit={(values: { totp: string }) => {
            handleSubmitData(values);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            touched,
          }) => {
            return (
              <Box
                className="m-main-spacing"
                component="form"
                id="totp-form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100% - 2.25rem)',
                  pt: 1,
                  position: 'relative',
                }}
              >
                <Box sx={{ mb: 2, flex: 1 }}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} order={1}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.totp && errors.totp)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                      >
                        <InputLabel htmlFor="label-totp">
                          <FormattedMessage id="menu.broker.totp" />
                        </InputLabel>
                        <OutlinedInput
                          id="label-totp"
                          name="totp"
                          type={showOtp ? 'text' : 'password'}
                          value={values.totp}
                          onBlur={handleBlur}
                          onChange={e => setFieldValue('totp', e.target.value)}
                          label={t('menu.broker.totp')}
                          inputProps={{
                            maxLength: 6,
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            style: { letterSpacing: '1.0rem' },
                          }}
                          onKeyDown={handleKeyDown}
                          onPaste={handlePaste}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowOtp(!showOtp)}
                                edge="end"
                                aria-label={showOtp ? 'Hide OTP' : 'Show OTP'}
                              >
                                {showOtp ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {touched.totp && errors.totp && (
                          <FormHelperText error sx={{ mx: 0 }}>
                            {errors.totp}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            );
          }}
        </Formik>
      }
      footer={
        <>
          <MuiButton
            variant="contained"
            color="primary"
            fullWidth
            form="totp-form"
            type="submit"
          >
            <FormattedMessage id="buttons.verify" />
          </MuiButton>
        </>
      }
      handleBackDropClick={() => {
        handleClose();
      }}
    />
  );
};

export default TOTPVarificationFormModal;
