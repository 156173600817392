import { useQuery } from 'react-query';
import { GetWebHookSignalBot } from 'services/apiServices';
import { Errors, TWebHookBotResult } from 'types/ApiServicesTypes';

export default function useGetWebHookSignalBot() {
  const objQuery = useQuery<TWebHookBotResult, Errors>(
    ['webhooksignalbot'],
    () => GetWebHookSignalBot(),
  );
  return objQuery;
}
